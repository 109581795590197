import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { useState } from 'react';
// Three js
import * as THREE from 'three';
import * as OBJLoader from 'three-obj-loader';
OBJLoader(THREE);

class ThreeCard2 extends Component{
    constructor(){
        super();
        this.state = {
            hover: false,
            onLoop: false
        };
    };
    componentDidMount(){
        this.THREE = THREE;
        const objLoader = new this.THREE.OBJLoader();
        const material =new THREE.MeshLambertMaterial( { color: 0xffffff } );

        this.angle=0;
        const width = this.mount.clientWidth;
        const height = this.mount.clientHeight;
        //ADD SCENE
        this.scene = new THREE.Scene();
        var myscene = this.scene;
        //ADD CAMERA
        this.camera = new THREE.PerspectiveCamera(45, width/height, 0.1, 20);
        this.camera.position.z = 7.5;
        if(this.props.data.id==1){
            //FOR TEXTURE
            const tLoader = new THREE.TextureLoader();
            const mapOverlay = tLoader.load(this.props.data.imgUrl);
            // IF DW, render this:
            //CREATE PLANE
            const material0 = new THREE.MeshBasicMaterial({map: mapOverlay});
            this.planeGeometry = new THREE.PlaneGeometry(3, 1.8, 0, 0);
            this.plane = new THREE.Mesh(this.planeGeometry, material0);
            this.plane.position.set(-1,-.7,-1);
            //CREATE CONE
            const coneGeometry = new THREE.ConeGeometry( 0.5, 1.5, 16 );
            const coneMaterial = new THREE.MeshLambertMaterial({color: 0x4070ff});
            this.cone = new THREE.Mesh( coneGeometry, coneMaterial );
            this.cone.rotation.z=0.2;
            this.cone.rotation.x=-0.4;
            //CREATE CUBE
            const cubeGeometry = new THREE.BoxGeometry( .8, .8, .8 );
            const cubeMaterial = new THREE.MeshLambertMaterial({color: 0x10f070});
            this.cube = new THREE.Mesh( cubeGeometry, cubeMaterial );
            this.cube.position.x=1;
            this.cube.position.y=.5;
            this.cube.rotation.z=1;
            this.cube.rotation.x=-0.5;
            //CREATE SPHERE
            var sphereGeometry = new THREE.SphereGeometry( 0.6, 8, 8);
            const sphereMaterial = new THREE.MeshLambertMaterial({color: 0xff0010});
            this.sphere = new THREE.Mesh( sphereGeometry, sphereMaterial );
            this.sphere.rotation.z=-1;
            this.sphere.position.x=1.5;
            this.sphere.position.y=-1;
            this.sphere.position.z=-.5;
            this.scene.add(this.plane);
            this.scene.add(this.cone);
            this.scene.add(this.cube);
            this.scene.add(this.sphere);
        }
        else if(this.props.data.id==2){
            const material0 = new THREE.MeshLambertMaterial({color: 0x558BFF});
            const material = new THREE.MeshLambertMaterial({color: 0xffffff});
            this.planeGeometry = new THREE.BoxGeometry( 4, 2.8, 0.1 );
            this.plane0 = new THREE.Mesh(this.planeGeometry, material0);
            this.plane0.position.set(0,-0.5,-1);
            this.plane = new THREE.Mesh(this.planeGeometry, material0);
            this.plane.rotation.x=1.47;
            this.plane.position.set(0,-1.8,0);
            myscene.add(this.plane0);
            myscene.add(this.plane);
            objLoader.load(
                // resource URL
                '/assets/obj/bunny.obj',
                // called when resource is loaded
                function ( object ) {
                    object.traverse( function ( child ) {
                        if ( child instanceof THREE.Mesh ) {
                            child.material = material;
                        }
                    } );
                    object.scale.set(11,11,11);
                    object.position.set(0,-2.1,0);
                    myscene.add(object);
                },
                // called when loading is in progresses
                function ( xhr ) {
                    console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
                },
                // called when loading has errors
                function ( error ) {
                    console.log( 'An error happened (OBJ Loader)' );
                }
            );

        }

        // IF ATOMU, render this:
        this.THREE = THREE;
        var myscene = this.scene;
        // lineMaterial.linewidth=0.1
        if(this.props.data.id==3){
            const objLoader = new this.THREE.OBJLoader();
            for(var i in this.props.data.obj_urls){
                const col=new THREE.Color(this.props.data.obj_colors[i]);
                const col1=new THREE.Color(this.props.data.line_colors[i]);
                const material =new THREE.MeshBasicMaterial({ color: col});
                const lineMaterial = new THREE.LineBasicMaterial( { color: col1} );
                material.color.set(col);
                objLoader.load(
                    // resource URL
                    this.props.data.obj_urls[i],
                    // called when resource is loaded
                    function ( object ) {
                        object.material = material;
                        object.traverse( function ( child ) {
                            if ( child instanceof THREE.Mesh ) {
                                child.material = material;
                                var meshGeometry = new THREE.EdgesGeometry( child.geometry );
                                var wireframe = new THREE.LineSegments( meshGeometry, lineMaterial );
                                myscene.add( wireframe);
                            }
                        } );
                        myscene.add( object);
                    },
                    function ( xhr ) {
                        console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
                    },
                    function ( error ) {
                        console.log( 'An error happened (OBJ Loader)' );
                    }
                );
            }
        }
        //ADD RENDERER
        this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true  })
        // this.renderer.setClearColor('#000000')
        this.renderer.setSize(width, height)
        this.renderer.maxAnisotropy=1024;
        this.mount.appendChild(this.renderer.domElement)
        // ADD LIGHT
        this.light = new THREE.PointLight( 0xFFFFFF, 2, 90 );
        this.light.position.set( 0, 15, 28 );
        this.scene.add( this.light );
        // this.ablight = new THREE.PointLight( 0xFFFFFF,1.5, 50 );
        // this.ablight.position.set( 0, 20, 0 );
        // this.ab1light = new THREE.AmbientLight( 0xFFFFFF, .1, 10 );
        // this.ab1light.position.set( 0, 5, 0 );
        // this.ab2light = new THREE.PointLight( 0xFFFFFF,1, 50 );
        // this.ab2light.position.set( 0, 0, -5 );
        // this.scene.add( this.light );
        // this.scene.add( this.ablight );
        // this.scene.add( this.ab1light );
        // this.scene.add( this.ab2light );
        this.mount.addEventListener("pointerenter", (event) => {this.setState({hover:true})});
        this.mount.addEventListener("pointerleave", (event) => {this.setState({hover:false})});
        this.start();
    }
    componentWillUnmount(){
        this.stop()
        this.mount.removeChild(this.renderer.domElement)
    }
    start = () => {
        if (!this.frameId) {
            this.frameId = requestAnimationFrame(this.animate)
        }
    }
    stop = () => {
        cancelAnimationFrame(this.frameId)
    }
    animate = () => {
        if(this.props.data.id==1){
            this.cone.rotation.y +=0.01;
            this.cube.rotation.y +=0.01;
            this.sphere.rotation.y +=0.01;
        }
        if (this.state.hover){
            if(this.camera.position.x>0.00001||this.camera.position.x<=0.00001){
                var xdiff=this.camera.position.x/6;
                var ydiff=this.camera.position.y/6;
                this.camera.position.x-=xdiff;
                this.camera.position.y-=ydiff;
            }
        }
        else{
            this.angle += 0.006;
            this.camera.position.x = Math.cos(this.angle);
            this.camera.position.y =Math.sin(this.angle);
        }
        this.camera.lookAt( this.scene.position );
        this.renderScene()
        this.frameId = window.requestAnimationFrame(this.animate)
    }
    renderScene = () => {
        this.renderer.render(this.scene, this.camera)
    }
    render(){
        if (window.innerWidth < 600){
            return(
                <div className="pofo-card" style={{backgroundColor:this.props.data.id%2==0?"#f8f8f8":"#fff"}}>
                    <div className = "pofo-card-3d-container mobile" >
                        <Link to={this.props.data.link} >
                            <div className="pofo-card-3d-view mobile"
                                 ref={(mount) => { this.mount = mount }}/>
                        </Link>
                    </div>

                    <div className="pofo-card-texts mobile"  >
                        <h3><Link to={this.props.data.link} style={{textDecoration:"none",color:"#000"}}>
                            {this.props.data.title}
                        </Link>
                        </h3>
                        <p style={{display:this.props.data.description? "block":"none"}}>{this.props.data.description}</p>
                        <h4>{this.props.data.hashtag}</h4>
                    </div>

                </div>)
        }
        if(this.props.data.id%2==0){
            return(
                 <div className="pofo-card" style={{ backgroundColor:this.props.macos? "#f8f8f8":"transparent"}}>
                    <Link to={this.props.data.link} style={{paddingLeft:"10vw"}}><div
                        style={{ width: '30vw', height: '30vw' }}
                        ref={(mount) => { this.mount = mount }}
                        />
                    </Link>
                    <div className="pofo-card-texts" style={{paddingLeft:"6vw"}}>
                        <h3><Link to={this.props.data.link} style={{textDecoration:"none",color:"#000"}}>
                            {this.props.data.title}</Link>
                        </h3>
                        <p style={{display:this.props.data.description? "block":"none"}}>{this.props.data.description}</p>
                        <h4>{this.props.data.hashtag}</h4>
                    </div>
                </div>
            )
        }
        else{
            return(
                <div className="pofo-card" style={{backgroundColor:"#fff"}}>
                    <div className="pofo-card-texts"  style={{paddingLeft:"15vw"}}>
                        <h3><Link to={this.props.data.link} style={{textDecoration:"none",color:"#000"}}>
                            {this.props.data.title}
                        </Link>
                        </h3>
                        <p style={{display:this.props.data.description? "block":"none"}}>{this.props.data.description}</p>
                        <h4>{this.props.data.hashtag}</h4>
                    </div>
                    <div style={{ width: '30vw', height: '30vw'}}>
                        <Link to={this.props.data.link}><div
                            style={{ width: '30vw', height: '30vw'}}
                            ref={(mount) => { this.mount = mount }}
                        />
                        </Link>
                    </div>
                </div>
            )
        }

    }
}
export default ThreeCard2