import React from 'react'
import PD from "./components/PD"
import PD3 from "./components/PD3"
import CT from "./components/CT"
import About from "./components/About"
import Relpro from "./components/Relpro"
import DealTroubleshoot from "./components/DealTroubleshoot"
import ViewTools from "./components/ViewTools"
import CameraControls from "./components/CameraControls"
import Transparency from "./components/Transparency"
import Dv from "./components/Dv"
import Ross from "./components/Ross"
import Makecents from "./components/Makecents"
import Dw from "./components/Dw"
import VR from "./components/VR"
import Atomu from "./components/Atomu"
import BasRelief from "./components/BasRelief"
import Windows from "./components/Windows"
// test backgrounds
import FragShaders from "./components/FragShaders.js"
import ThreeTests from "./components/ThreeTests.js"
import D3Tests from "./components/D3Tests.js"

import { Link, Switch } from "react-router-dom";
import {BrowserRouter, Route} from 'react-router-dom';
import 'bootstrap/dist/js/bootstrap.js';
import $ from 'jquery';
import FS1 from "./components/shaderComponents/FS1";


class Header extends React.Component {
    constructor(){
        super();
        // state is an object
        this.state={
            pd:true,
            ux3d:false,
            ct:false,
            about:false,
        }
    };
    toggleState =(menu_id)=>{
        if(menu_id==0){
            this.setState(state => ({ pd:true, ux3d:false, ct:false, about:false}));
        }
        else if(menu_id==1){
            this.setState(state => ({ pd:false, ux3d:true, ct:false, about:false}));
        }
        else if(menu_id==2){
            this.setState(state => ({ pd:false, ux3d:false, ct:true, about:false}));
        }
        else{
            this.setState(state => ({ pd:false, ux3d:false, ct:false, about:true}));
        }
    }
    render() {

        $('#root').css('background-color', '#f8f8f8');
        $('#navbar').css('background', 'transparent');
        //
        // document.body.style = 'background: #f8f8f8;';
        const about="ABOUT";
        const pd="2D UX";
        const pd3="3D UX";
        const ct="CREATIVE TECH";
        window.scrollTo(0, 0);

        function changeLogoSize(){
            document.getElementById("logo").style.width = '64px';
        }

        return(

        <BrowserRouter >
            <div>
                <header className='navbar' id='navbar'>
                    <div className="logo" style={{width: '120px' }} onClick={()=>{this.toggleState(0);changeLogoSize()}} >
                        {/*<Link to="/" style={{textDecoration: "none"}}><h1 onClick={()=>{this.toggleState(0)}}>{myLogo}</h1></Link>*/}
                        <Link to="/" ><img id="logo" src={require('./header-mink.png')} style={{width: '64px' }}/></Link>
                    </div>
                    <div className ="Menu">
                        <Link to="/about" style={{textDecoration: "none",float:"right"}}><h2 onClick={()=>{this.toggleState(3)}} style={this.state.about? { color: '#111' }:{}}>{about}</h2>
                        </Link>
                        <Link to="/ct" style={{textDecoration: "none",float:"right"}}><h2 onClick={()=>{this.toggleState(2)}} style={this.state.ct? { color: '#111' }:{}}>{ct}</h2>
                        </Link>
                        <Link to="/ux3d" style={{textDecoration: "none",float:"right"}}><h2 onClick={()=>{this.toggleState(1)}} style={this.state.ux3d? { color: '#111' }:{}}>{pd3}</h2>
                        </Link>
                        <Link to="/" style={{textDecoration: "none",float:"right"}}><h2 onClick={()=>{this.toggleState(0)}} style={this.state.pd? { color: '#111' }:{}}>{pd}</h2>
                        </Link>
                    </div>
                </header>
            <Switch>
                <Route exact={true} path='/' component={PD}/>
                <Route path='/ux3d' component={PD3}/>
                <Route path='/ct' component={CT}/>
                <Route path='/about' component={About}/>
                <Route path='/dealtroubleshoot' render={()=>(
                    <DealTroubleshoot />
                )}/>
                <Route path='/viewtools' render={()=>(
                    <ViewTools />
                )}/>
                <Route path='/cameracontrols' render={()=>(
                    <CameraControls />
                )}/>
                <Route path='/transparency' render={()=>(
                    <Transparency />
                )}/>
                <Route path='/dv' render={()=>(
                    <Dv />
                )}/>
                <Route path='/relpro' render={()=>(
                    <Relpro />
                )}/>
                <Route path='/ross' render={()=>(
                    <Ross />
                )}/>
                <Route path='/makecents' render={()=>(
                    <Makecents />
                )}/>
                <Route path='/dw' render={()=>(
                    <Dw />
                )}/>
                <Route path='/atomu' render={()=>(
                    <Atomu />
                )}/>
                <Route path='/br' render={()=>(
                    <BasRelief />
                )}/>
                <Route path='/vr' render={()=>(
                    <VR />
                )}/>
                <Route path='/windows' render={()=>(
                    <Windows />
                )}/>
                <Route path='/fragshaders' component={FragShaders}/>
                <Route path='/threetests' component={ThreeTests}/>
                <Route path='/D3Tests' component={D3Tests}/>
            </Switch>
            </div>
        </BrowserRouter>
        );
    }
}

export default Header