import React from 'react';

class PBI3 extends React.Component{
    constructor(){
        super();
        this.state = {

        };
    }
    render(){
        return(
            <div>
                {/*<h4>{this.props.data.title}</h4>*/}
                <div className="pofo-text-row" >
                    {/*<div className="pofo-text-col-sticky" style={{width:"40vw", flex:"none", position:"sticky",top:"0"}}>*/}
                    <div className="pofo-text-col-sticky" style={{width:"28vw"}}>
                        <h4>{this.props.data.title}</h4>
                        <div style={this.props.data.description==="" ? { display: 'none' }:{paddingBottom:"2vh",paddingTop:"1vh",width:"28vw"}}>
                            <b>{this.props.data.description}</b>
                        </div>
                        <div className="pofo-text-col-col" style={{width:"28vw",paddingBottom:"1vh"}}>
                            <b>{this.props.data.title0}</b>{this.props.data.bullet0}
                        </div>
                        <div className="pofo-text-col-col" style={{width:"28vw",paddingBottom:"1vh"}}>
                            <b>{this.props.data.title1}</b>{this.props.data.bullet1}
                        </div>
                        <div className="pofo-text-col-col" style={{width:"28vw",paddingBottom:"1vh"}}>
                            <b>{this.props.data.title2}</b>{this.props.data.bullet2}
                        </div>

                        <div style={{display:window.innerWidth < 600?"none":""}}>
                            <div style={{paddingTop:"2vh"}}><b>Example: Module Page for Grade 8 "Performing Arts"</b></div>
                            <div className="toggle-label" >
                                <img src="/assets/dts/brs-thumb-1.jpg" id="brs-tab-1" href="#o2" style={{margin:"0"}}/><p></p>
                            </div>
                            <div className="toggle-label" >
                                <img src="/assets/dts/brs-thumb-2.jpg" id="brs-tab-2" href="#o2" style={{opacity:"1",margin:"0"}}/><p></p>
                            </div>
                            <div className="toggle-label" >
                                <img src="/assets/dts/brs-thumb-3.jpg" id="brs-tab-3" href="#o2" style={{margin:"0"}}/><p></p>
                            </div>
                        </div>
                    </div>

                    <div className="pofo-img-col" style={{width:"48vw", overflow:"auto", marginTop:"5vw",flex:"none",position:"relative",boxShadow:"0px 0px 4px #afafaf"}}>
                        <img id="display-brs" src={this.props.data.imgUrl} style={{width:"48vw",float:"right",marginLeft:"0",marginBottom:"0",position:"relative"}}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default PBI3