import React from 'react';
import Overview from './pofoComponents/Overview'
import PI0 from './pofoComponents/PI0'
import PI2 from './pofoComponents/PI2'
import PBIn from './pofoComponents/PBIn'
import PBI11 from './pofoComponents/PBI11'
import PBI12 from './pofoComponents/PBI12'
import PBI13 from './pofoComponents/PBI13'
import CZ13 from './pofoComponents/Clickzoom13'
import CZ21 from './pofoComponents/Clickzoom21'
import CZ22 from './pofoComponents/Clickzoom22'
import CZ23 from './pofoComponents/Clickzoom23'
import CZ44 from './pofoComponents/Clickzoom44'
import CZ3 from './pofoComponents/Clickzoom3'
import CZ4 from './pofoComponents/Clickzoom4'
import PIV0 from './pofoComponents/PIV0'
import SV0 from './pofoComponents/SV0'
import GalleryColLeft from './pofoComponents/GalleryColLeft'
import DesignOutcome from './pofoComponents/DesignOutcome'
import dtsData from "../data/dtsData.js"
import "../css/pofo.css"
import "../css/image.css"
import "../css/sideNav.css"
import $ from 'jquery';

class DealTroubleshoot extends React.Component {

    constructor(){
        super();
        //pass data of this project
        this.state = {
            hasResearch: false,
            hasPrototype:false
        };
        // Outcome
        this.o0_PBI11 = dtsData.o0_PBI11.map(idea=>{
            return <PBI11 data={idea}/>
        });
        this.o1_PBI12 = dtsData.o1_PBI12.map(idea=>{
            return <PBI12 data={idea}/>
        });
        this.o2_PBI13 = dtsData.o2_PBI13.map(idea=>{
            return <PBI13 data={idea}/>
        });
        this.o3_CZ3 = dtsData.o3_CZ3.map(idea=>{
            return <CZ3 data={idea}/>
        });
        this.ov_SV0 = dtsData.ov_SV0.map(ur=>{
            return <SV0 data={ur}/>
        });
        // Research
        // this.r0_PI2 = dtsData.r0_PI2.map(idea=>{
        //     return <PI2 data={idea}/>
        // });
        this.r0_CZ21 = dtsData.r0_CZ21.map(idea=>{
            return <CZ21 data={idea}/>
        });
        // this.r1_PI2 = dtsData.r1_PI2.map(idea=>{
        //     return <PI2 data={idea}/>
        // });
        this.r1_CZ13 = dtsData.r1_CZ13.map(idea=>{
                return <CZ13 data={idea}/>
            });
        this.r2_PI2 = dtsData.r2_PI2.map(idea=>{
            return <PI2 data={idea}/>
        });
        // this.p0_PI2 = dtsData.p0_PI2.map(idea=>{
        //     return <PI2 data={idea}/>
        // });
        this.p0_CZ44 = dtsData.p0_CZ44.map(idea=>{
            return <CZ44 data={idea}/>
        });
        this.p1_CZ4 = dtsData.p1_CZ4.map(idea=>{
            return <CZ4 data={idea}/>
        });
        // this.p2_PBIn= dtsData.p2_PBIn.map(idea=>{
        //     return <PBIn data={idea}/>
        // });
        this.p2_CZ23= dtsData.p2_CZ23.map(idea=>{
            return <CZ23 data={idea}/>
        });
        // this.p3_PI2= dtsData.p3_PI2.map(idea=>{
        //     return <PI2 data={idea}/>
        // });
        this.p3_CZ22= dtsData.p3_CZ22.map(idea=>{
            return <CZ22 data={idea}/>
        });
    };

    componentDidMount(){
            let zoomImgH = function(){
                let clone = this.cloneNode();
                if(clone.classlist){
                    clone.classlist.remove("zoomDH");
                }

                //put clone to lb
                let lb = document.getElementById("lb-img-h");
                lb.innerHTML = "";
                lb.appendChild(clone);
                //show lb
                lb = document.getElementById("lb-back-h");
                lb.classList.add("show");
            };

            let zoomImgW = function(){
                let clone = this.cloneNode();
                if(clone.classlist)
                    clone.classlist.remove("zoomDW");
                //put clone to lb
                let lb = document.getElementById("lb-img-w");
                lb.innerHTML = "";
                lb.appendChild(clone);
                //show lb
                lb = document.getElementById("lb-back-w");
                lb.classList.add("show");
            };

            // window.addEventListener("load",function(){
            console.log("Mounted");
                let imagesH = document.getElementsByClassName("zoomDH");
                if (imagesH.length>0){
                    console.log("Mounted! (H)");
                    for (let imgh of imagesH){
                        imgh.addEventListener("click", zoomImgH);
                    }
                }
                document.getElementById("lb-back-h").addEventListener("click", function(){
                    this.classList.remove("show");
                })

                let imagesW = document.getElementsByClassName("zoomDW");
                if (imagesW.length>0){
                    console.log("Mounted! (W)");
                    for (let imgw of imagesW)
                        imgw.addEventListener("click", zoomImgW);
                }
                document.getElementById("lb-back-w").addEventListener("click", function(){
                    this.classList.remove("show");
                })

            // });

        // nav -> content
        $(document).ready(function(){
            //Switch to white scheme
            $('#root').css('background-color', 'white');
            $('#navbar').css('background-color', 'white');

            $('body').scrollspy({target: ".pofo-page", offset: 50});

            // Add smooth scrolling on all links inside the navbar
            $("#side-nav a").on('click', function(event) {
                // Make sure this.hash has a value before overriding default behavior
                if (this.hash !== "") {
                    // Prevent default anchor click behavior
                    event.preventDefault();
                    // Store hash
                    let targetHref = $(this).attr('href');
                    // Using jQuery's animate() method to add smooth page scroll
                    // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
                    $('html, body').animate({
                        scrollTop: $(targetHref).offset().top
                    }, 900);
                }  // End if
            });

            $(window).scroll(function() {
                let scrollDistance = $(window).scrollTop();
                // Assign active class to nav links while scolling
                $('.page-section').each(function(m) {
                    if ($(this).position().top-200<= scrollDistance) {
                        $('.side-nav div a.active').removeClass('active');
                        $('.side-nav div a').eq(m).addClass('active');
                    }
                    else{
                        $('.subgroup').each(function(n) {
                            $('nav ul li a.active').removeClass('active');
                        });
                    }
                });
                $('.subgroup').each(function(n) {
                    if ($(this).position().top-200 <= scrollDistance) {
                        $('nav ul li a.active').removeClass('active');
                        $('nav ul li a').eq(n).addClass('active');
                    }
                });
            }).scroll();

            $(document).ready(function(){
                $("#if-tab-1").on({
                    'click': function(){
                        let targetHref = $(this).attr('href');
                        $('html, body').animate({scrollTop: $(targetHref).offset().top}, 0);
                        // style changes:
                        $(this).css('opacity','1');
                        $('#display-if').attr('src','/assets/dts/bid-request-rate.jpg');
                        $('#if-tab-2').css('opacity','0.5');
                        $('#if-tab-3').css('opacity','0.5');
                        $('#if-tab-4').css('opacity','0.5');
                    }
                });
                $("#if-tab-2").on({
                    'click': function(){
                        let targetHref = $(this).attr('href');
                        $('html, body').animate({scrollTop: $(targetHref).offset().top}, 0);
                        // style changes:
                        $(this).css('opacity','1');
                        $('#display-if').attr('src','/assets/dts/bid-rate.jpg');
                        $('#if-tab-1').css('opacity','0.5');
                        $('#if-tab-3').css('opacity','0.5');
                        $('#if-tab-4').css('opacity','0.5');
                    }
                });
                $("#if-tab-3").on({
                    'click': function(){
                        let targetHref = $(this).attr('href');
                        $('html, body').animate({scrollTop: $(targetHref).offset().top}, 0);
                        // style changes:
                        $(this).css('opacity','1');
                        $('#display-if').attr('src','/assets/dts/win-rate.jpg');
                        $('#if-tab-1').css('opacity','0.5');
                        $('#if-tab-2').css('opacity','0.5');
                        $('#if-tab-4').css('opacity','0.5');
                    }
                });
                $("#if-tab-4").on({
                    'click': function(){
                        let targetHref = $(this).attr('href');
                        $('html, body').animate({scrollTop: $(targetHref).offset().top}, 0);
                        // style changes:
                        $(this).css('opacity','1');
                        $('#display-if').attr('src','/assets/dts/render-rate.jpg');
                        $('#if-tab-1').css('opacity','0.5');
                        $('#if-tab-2').css('opacity','0.5');
                        $('#if-tab-3').css('opacity','0.5');
                    }
                });
                $("#er-tab-1").on({
                    'click': function(){
                        let targetHref = $(this).attr('href');
                        $('html, body').animate({scrollTop: $(targetHref).offset().top}, 0);
                        // style changes:
                        $(this).css('opacity','1');
                        $('#display-er').attr('src','/assets/dts/er-1.jpg');
                        $('#er-tab-2').css('opacity','0.5');
                        $('#er-tab-3').css('opacity','0.5');
                    }
                });
                $("#er-tab-2").on({
                    'click': function(){
                        let targetHref = $(this).attr('href');
                        $('html, body').animate({scrollTop: $(targetHref).offset().top}, 0);
                        // style changes:
                        $(this).css('opacity','1');
                        $('#display-er').attr('src','/assets/dts/er-2.jpg');
                        $('#er-tab-1').css('opacity','0.5');
                        $('#er-tab-3').css('opacity','0.5');

                    }
                });
                $("#er-tab-3").on({
                    'click': function(){
                        let targetHref = $(this).attr('href');
                        $('html, body').animate({scrollTop: $(targetHref).offset().top},0);
                        // style changes:
                        $(this).css('opacity','1');
                        $('#display-er').attr('src','/assets/dts/er-3.jpg');
                        $('#er-tab-1').css('opacity','0.5');
                        $('#er-tab-2').css('opacity','0.5');
                    }
                });
                $("#brs-tab-1").on({
                    'click': function(){
                        let targetHref = $(this).attr('href');
                        $('html, body').animate({scrollTop: $(targetHref).offset().top}, 0);
                        // style changes:
                        $(this).css('opacity','1');
                        $('#display-brs').attr('src','/assets/dts/brs-1.jpg');
                        $('#brs-tab-2').css('opacity','0.5');
                        $('#brs-tab-3').css('opacity','0.5');
                    }
                });
                $("#brs-tab-2").on({
                    'click': function(){
                        let targetHref = $(this).attr('href');
                        $('html, body').animate({scrollTop: $(targetHref).offset().top}, 0);
                        // style changes:
                        $(this).css('opacity','1');
                        $('#display-brs').attr('src','/assets/dts/brs-2.jpg');
                        $('#brs-tab-1').css('opacity','0.5');
                        $('#brs-tab-3').css('opacity','0.5');
                    }
                });
                $("#brs-tab-3").on({
                    'click': function(){
                        let targetHref = $(this).attr('href');
                        $('html, body').animate({scrollTop: $(targetHref).offset().top}, 0);
                        // style changes:
                        $(this).css('opacity','1');
                        $('#display-brs').attr('src','/assets/dts/brs-3.jpg');
                        $('#brs-tab-1').css('opacity','0.5');
                        $('#brs-tab-2').css('opacity','0.5');
                    }
                });
            });

        });}


    render() {
        window.scrollTo(0, 0);
        if(window.innerWidth < 600){
            return(
                <div className="pofo-page mobile" >
                    <div style={{width:"90vw", marginLeft:"5vw", float:"left", display:"inline"}}>
                        {/*position: "relative", float:"left",*/}
                        <Overview data={dtsData}/>
                        <div>
                            <h2 className="page-section" id="rp">PROJECT BACKGROUND</h2>
                            <div>
                                <h4 id="r0" className="subgroup">ADTECH BACKGROUND</h4>
                                {this.r0_CZ21}
                            </div>
                            <div>
                                <h4 id="r1" className="subgroup">XANDR'S CURRENT PRODUCT OFFERING</h4>
                                {this.r1_CZ13}
                            </div>
                            <div>
                                <h4 id="r2" className="subgroup">INITIAL PERSONA & FIELD RESEARCH</h4>
                                {this.r2_PI2}
                            </div>
                        </div>
                        <div>
                            <h2 className="page-section" id="do">DESIGN OUTCOMES</h2>
                            <div id="ov" className="subgroup" style={{height: "82vh"}}>
                                {this.ov_SV0}
                            </div>
                            <div id="o0" className="subgroup">
                                {this.o0_PBI11}
                            </div>
                            <div id="o1" className="subgroup">
                                {this.o1_PBI12}
                            </div>
                            <div id="o2" className="subgroup">
                                {this.o2_PBI13}
                            </div>
                            <div id="o3" className="subgroup">
                                {this.o3_CZ3}
                            </div>
                        </div>

                        <div>
                            <h2 className="page-section" id="dp">DESIGN PROCESS</h2>
                            <div>
                                <h4 id="p0" className="subgroup">REVIEW PRODUCT REQUIREMENTS</h4>
                                {this.p0_CZ44}
                            </div>
                            <div>
                                <h4 id="p1" className="subgroup">INITIAL EXPLORATION</h4>
                                {this.p1_CZ4}
                            </div>
                            <div>
                                <h4 id="p2" className="subgroup">DESIGN ITERATIONS</h4>
                                {this.p2_CZ23}
                            </div>
                            <div>
                                <h4 id="p3" className="subgroup">DELIVERY</h4>
                                {this.p3_CZ22}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return(
            <div className="pofo-page" >
                <div style={{width:"18vw", marginLeft: "0vw", float:"left"}}>
                    <nav className="side-nav" id="side-nav" style={{position: "fixed", display:"inline-block"}}>
                        <div><a href="#overview">OVERVIEW</a></div>
                        <div><a href="#rp">PROJECT BACKGROUND</a></div>
                        <ul>
                            <li><a href="#r0">Adtech Background</a></li>
                            <li><a href="#r1">Existing Product</a></li>
                            <li><a href="#r2">Field Research</a></li>
                        </ul>
                        <div><a href="#do">DESIGN OUTCOMES</a></div>
                        <ul>
                            <li><a href="#ov">Live Demo</a></li>
                            <li><a href="#o0">Impression Funnel</a></li>
                            <li><a href="#o1">Deal Error Report</a></li>
                            <li><a href="#o2">Bid Request Sampler</a></li>
                            <li><a href="#o3">User Feedback</a></li>
                        </ul>

                        <div><a href="#dp">DESIGN PROCESS</a></div>
                        <ul>
                            <li><a href="#p0">Review Requirements</a></li>
                            <li><a href="#p1">Initial Exploration</a></li>
                            <li><a href="#p2">Design Iterations</a></li>
                            <li><a href="#p3">Delivery</a></li>
                        </ul>
                        {/*<div><a href="#ux">UX REFINEMENTS</a>*/}
                        {/*</div>*/}
                    </nav>
                </div>
                <div style={{width:"80vw", marginLeft:"18vw", float:"left", display:"inline"}}>
                    {/*position: "relative", float:"left",*/}
                    <Overview data={dtsData}/>
                    <div>
                        <h2 className="page-section" id="rp" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>PROJECT BACKGROUND</h2>
                        <div id="r0" className="subgroup" >
                            <h4 style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"1vw"}}>AdTech Background</h4>
                            {this.r0_CZ21}
                        </div>
                        <div id="r1" className="subgroup" >
                            <h4 style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"1vw"}}>Xandr's Current Product Offerings</h4>
                            {this.r1_CZ13}
                        </div>
                        <div>
                            <h4 id="r2" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>Initial Persona & Field Research</h4>
                            {this.r2_PI2}
                        </div>
                    </div>
                    <div>
                        <h2 className="page-section" id="do" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>DESIGN OUTCOMES</h2>
                        <div id="ov" className="subgroup" style={{height: "82vh"}}>
                            {this.ov_SV0}
                            </div>
                        <div id="o0" className="subgroup">
                            {this.o0_PBI11}
                        </div>
                        <div id="o1" className="subgroup">
                            {this.o1_PBI12}
                        </div>
                        <div id="o2" className="subgroup">
                            {this.o2_PBI13}
                        </div>
                        <div id="o3" className="subgroup">
                            {this.o3_CZ3}
                        </div>
                    </div>

                    <div>
                        <h2 className="page-section" id="dp" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>DESIGN PROCESS</h2>
                        <div id="p0" className="subgroup" >
                            <h4 style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"1vw"}}>Review Product Requirements</h4>
                            {this.p0_CZ44}
                        </div>
                        <div id="p1" className="subgroup" >
                            <h4 style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"6vw"}}>Initial Explorations</h4>
                            {this.p1_CZ4}
                        </div>
                        <div id="p2" className="subgroup" >
                            <h4 style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"6vw"}}>Design Iterations</h4>
                            {this.p2_CZ23}
                        </div>
                        <div id="p3" className="subgroup" >
                            <h4 style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"4vw"}}>Delivery</h4>
                            {this.p3_CZ22}
                        </div>
                    </div>
                    {/*<div>*/}
                        {/*<h2 className="page-section" id="ux">UX REFINEMENTS</h2>*/}
                        {/*<div id="sub5-page-section" className="subgroup">*/}
                            {/*<h4>*To be updated soon*</h4>*/}
                            {/*/!*{this.s0_PIV0}*!/*/}
                        {/*</div>*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*<h2 className="page-section" id="do">DESIGN OUTCOMES</h2>*/}
                    {/*<div id="sub5-page-section" className="subgroup">{this.d0_SI0}</div>*/}
                    {/*</div>*/}
                </div>
            </div>
        )
    }
}

export default DealTroubleshoot