import React from 'react';
import Intro1 from './pofoComponents/Intro1'
import MyExperience from './pofoComponents/MyExperience'
import MySkills from './pofoComponents/MySkills'
import MyUpdates from './pofoComponents/MyUpdates'
import MyContact from './pofoComponents/MyContact'


// import aboutData from "../data/aboutData.js"

class About extends React.Component {
    render(){
        window.scrollTo(0, 0);

        // if (window.innerWidth < 600)
        //     return (<div><h4>* Please view on desktop screen :)!</h4></div>);

        return(
            <div>
                <Intro1 />
                <MyExperience />
                <MySkills />
                <MyUpdates/>
                <MyContact/>
            </div>
        )
    }
}

export default About