import React from 'react';

class PBI21 extends React.Component{
    constructor(){
        super();
        this.state = {

        };
    }
    render(){
        return(
            <div>
                {/*<h4>{this.props.data.title}</h4>*/}
                <div className="pofo-text-row" >
                    {/*<div className="pofo-text-col-sticky" style={{width:"40vw", flex:"none", position:"sticky",top:"0"}}>*/}
                    <div className="pofo-text-col-sticky" style={{width:"28vw"}}>
                        <h4 style={{paddingBottom:"1.5vw", paddingTop:"1vw"}}>AppCues User Survey Report</h4>
                        {/*<div style={this.props.data.description==="" ? { display: 'none' }:{paddingBottom:"2vh",paddingTop:"1vh",width:"28vw"}}>*/}
                        {/*    <b>{this.props.data.description}</b>*/}
                        {/*</div>*/}
                        <div className="pofo-text-col-col" style={{width:"28vw",paddingBottom:"1vh"}}>
                            <b>{this.props.data.title0}</b>{this.props.data.bullet0}
                        </div>
                        <div className="pofo-text-col-col" style={{width:"28vw",paddingBottom:"1vh"}}>
                            <b>{this.props.data.title1}</b>{this.props.data.bullet1}
                        </div>
                        <div className="pofo-text-col-col" style={{width:"28vw",paddingBottom:"1vh"}}>
                            <b>{this.props.data.title2}</b>{this.props.data.bullet2}
                        </div>
                        <div style={{display:window.innerWidth < 600?"none":""}}>
                            <div className="toggle-label" >
                                <img src="/assets/dv/thumb-1.jpg" id="dv-tab-1" href="#o0" style={{margin:"0"}}/>
                            </div>
                            <div className="toggle-label" >
                                <img src="/assets/dv/thumb-2.jpg" id="dv-tab-2" href="#o0" style={{margin:"0", opacity:"1"}}/>
                            </div>

                        </div>
                    </div>

                    <div className="pofo-img-col" style={{width:"48vw", overflow:"auto",marginTop:"5vw",flex:"none",position:"relative",boxShadow:"0px 0px 4px #afafaf"}}>
                        <img id="display-dv" src={this.props.data.imgUrl} style={{width:"48vw",float:"right",margin:"0",position:"relative"}}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default PBI21