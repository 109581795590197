const vtData = {
    id:0,
    overview:{
        coverImgUrl:"/assets/vt/cover-image-1.png",
        descriptionBold:"I served as the Product Designer, UX Researcher, and 3D Prototyper for nTopology's new version of 3D inspection tools.",
        descriptionRegular:"The inspection tools include Section Cut and Field Viewer, both of which are key to visualizing geometric properties and informing 3D designs, and are used in many 3D design and optimization workflows.",
        timelineImgUrl:"/assets/vt/project-timeline.jpg",

        role:"Lead Product Designer, Lead UX Researcher, Lead 3D UX Prototyper",
        theTeam:"Engineering Team, Myself (Design and co-PM)",
        tools:"Figma, Miro, ThreeJS/WebGL, ReactJS, HTML, CSS, pen & paper, Jira",
        duration:"Q3 2022 - Q4 2022"
    },
    b0_PI0:[
        {
            descriptionL:"nTopology is a new generation of 3D modeling software that’s highly performant at generating and rendering intricate details.",
            descriptionR:"Many industrial manufacturing companies use nTopology (instead of traditional CAD) to iterate on designs that involve intricate geometries and suitable for advanced manufacturing (such as 3D printing).",
            imgUrl:"/assets/vt/use_ntop_for.jpg"
        }
    ],
    b1_PI0:[
        {
            descriptionL:"",
            imgUrl:"/assets/vt/current_pain_point.jpg",
        }
    ],
    b1_CZ41:[
        {
            title:"",
            descriptionL:"",
            descriptionR:"",
            imgH1:"Ever-shrinking UI work space",
            imgH2:"Information hierarchy is very flat",
            imgH3:"Error-prone gimbal UI",
            imgH4:"Only one inspection tool at a time",
            imgTxt1:"Large 2D UI panels block the 3D view port",
            imgTxt2:"A lot of technical settings, but there isn’t any persona-driven layout",
            imgTxt3:"Accidentally perform rotation instead of translation, due to tiny clickable area",
            imgTxt4:"Users can’t use both Section Cut and Field Viewer simultaneously on a model",
            imgUrl1:"/assets/vt/pp1.jpg",
            imgUrl2:"/assets/vt/pp2.jpg",
            imgUrl3:"/assets/vt/pp3.jpg",
            imgUrl4:"/assets/vt/pp4.jpg",
        },
    ],
    o0_PI0:[
        {
            descriptionL:"The final design consists of contextual 2D Toolbars that allow user to quickly make modifications to their inspection setup on the fly, and more accessible 3D and keyboard inputs.",
            imgUrl:"/assets/vt/design_outcome_1.jpg",
        }
    ],
    o1_PI2:[
        {   title:"Field Viewer Toolbar",
            descriptionL:"",
            descriptionR:"",
            // descriptionL:"Unified workflow that enables two tools to be displayed at once.",
            imgUrl:"/assets/vt/design_outcome_fv.jpg"
        },
        {   title:"Section Cut Toolbar",
            descriptionL:"",
            descriptionR:"",
            imgUrl:"/assets/vt/design_outcome_sc.jpg"
        }
    ],
    o2_PI0:[
        {
            descriptionL:"Intuitive and continuous experience for switching states",
            imgUrl:"/assets/vt/key_input_diagram.jpg",
        }
    ],
    o3_PIV1:[
        {
            title1:"Improved Mouse Interactions for Transforming View Planes",
            imgUrl:"assets/vt/gimbal_thumbs.jpg",
            imgUrl1:"",
            title2:"Sharable 3D Prototype made in WebGL",
            descriptionL:"I built a 3D prototype and specified the rendering setup for the improved Gimbal, and hosted the 3D specs on the following website to share easily with PMs and Engineers.",
            descriptionR:"You can play with the interactive prototype at: ",
            linkUrl:"http://prototypes3d.s3-website.us-east-2.amazonaws.com/",
            vUrl:"https://player.vimeo.com/video/822615322?title=0&byline=0&portrait=0&transparent=0&autoplay=1&loop=1",
            vHeight:"30.5vw",
        }
        // {
        //     vUrl:"https://player.vimeo.com/video/810037416?title=0&byline=0&portrait=0&transparent=0&autoplay=1&loop=1",
        //     imgUrl:"assets/vt/gimbal_thumb_1.jpg",
        //     description:""
        // },
        // {
        //     vUrl:"https://player.vimeo.com/video/810041283?title=0&byline=0&portrait=0&transparent=0&autoplay=1&loop=1",
        //     imgUrl:"assets/vt/gimbal_thumb_2.jpg",
        //     description:""
        // },
    ],
    o4_PIV1:[
        {   title1:"Design and Build a Website for Documenting 2D & 3D Prototypes",
            imgUrl:"/assets/vt/project_web.jpg",
            imgUrl1:"/assets/vt/documentation_structure.jpg",
            title2:"One-Stop-Shop for All Design-Related Artifacts",
            descriptionL:"At the time the company wasn't using a Confluence-like project management tool, and it's been historically hard for cross-functional teams to quickly find the lastest design spec they need, or to trace back how design decisions were made.",
            descriptionR:"Therefore, I built a website to help track the design process (such as Miro boards and Google docs used for ideation), and host the 2D and 3D design specs and prototypes - so that PMs and engineers can still quickly find the designs even after the Jira tickets are closed or stashed. ",
            linkUrl:"https://main.dp9d0xutmqlxj.amplifyapp.com/",
            vUrl:"https://player.vimeo.com/video/822740840?title=0&byline=0&portrait=0&transparent=0&autoplay=1&loop=1",
            vHeight:"28vw",
        },
    ],
    r0_PI0:[
        {
            title:"",
            descriptionL:"To validate initial assumptions, I designed a survey for nTopology's internal users, with an emphasis on people who are in customer-facing roles.",
            descriptionR:"The survey responses helped provide an overview of usage of inspection tools by persona, workflows, and level of experience.",
            imgUrl:"/assets/vt/survey.jpg"
        },
        {
            title:"",
            descriptionL:"After initial round of assumption validation and feedback gathering, I then talked to people from each of the 4 main user groups, observed their usage of nTopology in action, and gathered more qualitative feedback.",
            descriptionR:"I sat down with internal users in either individual or group meetings, depending on each person's schedules, and I gathered feedback from nTopology's clients by riding along existing weekly or biweekly product support meetings they have with nTopology.",
            imgUrl:"/assets/vt/interviews.jpg"
        },
    ],
    r1_PI0:[
        {
            title:"Analyze and Synthesize Our Learnings from Users",
            descriptionL:"",
            descriptionR:"",
            imgUrl:"/assets/vt/feedback_summary.jpg"
        },
    ],
    d0_PI2:[
        {   title:"Organize and Facilitate Team-wide Brainstorm Sessions",
            descriptionL:"I engaged the entire graphics engineering team to discuss rendering capabilities, and I designed a Miro board template to facilitate and keep track of the inputs from engineers.",
            imgUrl:"/assets/vt/brainstorm.jpg"
        },
        {   title:"Run Review Sessions for On-going Design",
            descriptionL:"For each major iteration of my design, I made sure to share the design directions with the customer success team, graphics engineering team, and product design team to gather feedback on usability and feasibility.",
            descriptionR:"This way, I was able to narrow down the design direction before creating hi-fidelity spec.",
            imgUrl:"/assets/vt/feedback.jpg"
        },
    ],
    d1_PI2:[
        {   title:"Design Iterations on Wireframe, 2D Toolbar UI, and 3D Interaction",
            descriptionL:"",
            imgUrl:"/assets/vt/ideation.jpg"
        },
    ],
    s0_PBI31: [
        {
            title:"TOOLBAR UI",
            description:"Detailed spec for the Toolbar UI for both Field Viewer and Section Cut.",
            imgUrl:"/assets/vt/2d_ui_spec_fv.jpg",
            title0:"Icons: ",
            bullet0:"There are many new icons introduced in this design, and the spec makes sure that the file names and thumbnails of the assets are clearly to avoid confusion during implementation.",
            title1:"Options and States: ",
            bullet1:"The options with each dropdown menu and expected behavior is also briefly noted in the spec.",
            title2:"",
            bullet2:""
        }
    ],
    s1_PBIn:[
        {
            title:"LAYOUT & COLORS",
            description:"",
            imgUrl:"/assets/vt/toolbar_spacing_colors.jpg",
            title0:"Layout and Spacing Guidelines: ",
            bullet0:"Detailed spacing guidelines for dev implementation in Qt.",
            title1:"Dark Mode vs. Light Mode: ",
            bullet1:"Color specs for both dark mode and light mode application.",
            title2:"",
            bullet2:"",
        }
    ],
}

export default vtData