const dwData = {
    id:1,
    overview:{
        coverImgUrl:"/assets/dw/01-cover.jpg",
        descriptionBold:"For my Senior Thesis project at RISD, I design and build an mixed-media toy for teaching kids 3D geometry, by linking the physical space and digital space.",
        descriptionRegular:"Digital Whirligig is an IoT toy that bridges children's physical and digital learning experience.\n" + "\n" +
        "By spinning the wooden toys, the player correlates the physical and tangible space with virtual 3D models in the computer.",
        timelineImgUrl:"",

        role:"Research, Design, Prototyping",
        theTeam:"Myself",
        tools:"Arduino, Processing (a Java-based graphics library), Rhino, fabrication tools",
        duration:"Feb 2015 - June 2015, Dec 2016"
    },
    o0_PBI0:[
        {
            title:"",
            description:"By spinning the wooden toys, the player correlates the physical and tangible space with virtual 3D models in the computer.",
            imgUrl:"/assets/dw/gif01.gif"
        }
    ],
    o1_SV0:[
        {
            title:"Demo Video:",
            vUrl:"https://player.vimeo.com/video/201006984?title=0&byline=0&portrait=0&transparent=0"
        }
    ],
    o2_PI0:[
        {
            title:"Interactive Screens:",
            descriptionL:"",
            descriptionR:"",
            imgUrl:"/assets/dw/slide-02.jpg",
        }
    ],
    r0_SPI0:[
        {
            title:"WHY DO WE NEED NEW INTERFACES FOR DIGITAL LEARNING?",
            descriptionL:"Although millennials first began learning by playing in the physical space before digital simulations, many of the \"digital natives\" nowadays are learning through the screen displays ever since they are toddlers: \n" +
            "educational products nowadays are increasingly built as Apps and delivered through laptops and tablets, and children spending more time on mastering iPad games.",
            descriptionR:"Adults gradually loose sight of their learning and understanding of physical space. For example, the study of shapes and volume is now relying largely on flat screens, touch pads, and computer mice, which are two-dimensional control devices that lack any meaningful tactile feedback. Therefore, children's understanding of geometry is hindered if they don't connect what's displayed on screen with what's happening physically.",
            imgUrl:"/assets/dw/insert12.jpg"
        },
        {
            title:"WHY IS DIGITAL INTERFACE SO POPULAR?",
            descriptionL:"As smartphones and tablets become more accessible, toy companies are increasingly migrating to digital platforms, creating a \"virtual body\" of their iconic physical products (Barbie, for example). Software company also see the connected digital devices (smartphones, tablets, laptops, etc) as both fast and efficient medium to deliver their digital products, making digital games and apps seem like the ideal mode of \"the future of education\".",
            descriptionR:"",
            imgUrl:"/assets/dw/insert13.jpg"
        },
        {
            title:"CURRENT INITIATIVES FOR ANALOG AND DIGITAL PLAY",
            descriptionL:"In the attempt to shift the singular mode of touch screen interaction, a number of products have emerged in the past few years. For example, LEGO Fusion allows the players to use iPad to record the front view of the house that they built with lego bricks, and turn it into a virtual 3D object. The virtual object can then be placed within the iPad app and facilitate story telling. Doodle3D is an application that works the other way around: it takes the 2D doodles of the kids as input, and digitally transform the drawing into a 3D model that can be printed through a 3D printer.",
            descriptionR:"However, in both examples, the analog play and the digital creation are divided into separate stages: in LEGO fusion, the player has to exit the construction mode in order to engage in the digital story telling; in Doodle3D, the player draw and manipulate the sketch in a 2D interface, and then passively watch or wait for the 3D printer to complete the fabrication. Both example lack are short of meaningful connection between digital and analog interactions.",
            imgUrl:"/assets/dw/insert14.jpg"
        }
    ],
    d0_SPI0:[
        {
            title:"MY PROPOSAL: SYNCHRONIZE PHYSICAL MOVEMENTS WITH DIGITAL REPRESENTATION",
            descriptionL:"I was very inspired by the traditional wood toy blocks chained through curly wire, which the children can spin with finger tips. When the wooden blocks are spinning, they create the illusion of unique 3D volumes.\n" +
            "\n" +
            "What if, when the wood shapes are spinning, we can track their movement? What if we recreate this 3D volume in the digital space? Because the temporary silhouettes of spinning blocks become 3D models in real time, children can better correlate these 3D shapes with their tangible experience.",
            descriptionR:"I brainstormed a few different designs for the technical hardware that could make the physical-to-digital interaction possible. I first thought of setting digital pins inside cylindrical spindles, but for the need of mechanical strength and limitation of manufacturing capability, I migrated to the idea of using (hacking) existing USB 3.0 ports as connectors.",
            imgUrl:"/assets/dw/insert15.jpg"
        },
        {
            title:"MOCK-UP AND RENDERING",
            descriptionL:"The formal attributes of toy blocks - such as color, shape and orientation - will produce different computer-generated 3D models.\n" +
            "\n" +
            "Therefore, I created a series of 3D models in Rhino to visualize how these different geometries interact with space. Based on these 3D renderings and possibilities of user manipulations, I started to design the actually digital interface.",
            descriptionR:" \n",
            imgUrl:"/assets/dw/insert16.jpg"
        }],
    pt0_SPI0:[
        {
            title:"PROTOTYPING: PHYSICAL COMPUTING + COMPUTER GRAPHICS",
            descriptionL:"The hardware prototype includes two key components: the connectors that identify different ports of the wood toys, and the motion sensor that tracks the rotation after a wood toy is connected to the main device.",
            descriptionR:"I altered the USB 3.0 ports by connecting selected pins to ground, and leaving other pins to pull-up resistors, thus customizing an array of binary values. Those binary arrays informs the graphical interface which shapes and orientations to display. A gyroscope is attached to the main device and functions as motion sensor.",
            imgUrl:"/assets/dw/insert17.jpg"
        }
    ],
    pt1_PVV0:[
        {
            descriptionL:"Testing with motion tracking and geometry rendering:",
            descriptionR:"Testing with custom port for object identification:",
            vUrl1:"https://player.vimeo.com/video/189661388?title=0&byline=0&portrait=0&transparent=0",
            vUrl2:"https://player.vimeo.com/video/161582819?title=0&byline=0&portrait=0&transparent=0"
        }
    ],
}

export default dwData