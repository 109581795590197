const windowsData = {
    id:0,
    overview:{
        coverImgUrl:"/assets/windows/oCover.png",
        descriptionBold:"Forge is a tech startup re-imagining how home renovations - one of the world's oldest professions - can be done more efficiently by leveraging software solutions and process innovations.",
        descriptionRegular:"As archaic and interpersonal the trades have been, the challenge lies in finding out where and how technology can come in and contribute to a better process.",
        timelineImgUrl:"",

        role:"Product Designer, also serving the PM function in a team that doesn’t have a Product Manager",
        theTeam:"Myself, Software Engineering team, Leadership team",
        tools:"Figma, Confluence, Jira",
        duration:"Q1-Q2 2024",
    },
    pb0_PI2:[
        {
            title:"From Architecture Digest to iconic tv scenes, we often see houses of historical charm and timeless styles.\n" +
                "Indeed, revitalizing those historical buildings is a rapidly growing market in the US, with over 50% homes in New York and Massachusetts already more than 60 years old. As housing prices go up and new constructions become less affordable, renovation demands have been rising and the revenue of which has surpassed new constructions since 2022.",
            descriptionL:"Among the many things that can be improved in old houses, windows are a popular subject of renovation. Weathering through decades of wear and deformation, the original windows in old homes are not only onerous to open and close, but also less energy efficient compared to new ones.",
            descriptionR:"But unlike placing an Amazon order or walking into a dealership to pick your favorite car model, window replacements are site-specific, made-to-order and manually installed. Working with existing buildings means scoping the job and tailoring the approach for each project.",
            imgUrl:"/assets/windows/oPB-1.png",
        },
        {
            title:"",
            descriptionL:"",
            descriptionR:"",
            imgUrl:"/assets/windows/oPB-2.png",
        },
        {
            title:"",
            descriptionL:"",
            descriptionR:"",
            imgUrl:"/assets/windows/oPB-3.png",
        },
        {
            title:"Challenges with Window Renovations",
            descriptionL:"In Massachusetts, nearly 60% homes are single-family, which are built individually at different times, with different blueprints, and using different materials and techniques.",
            descriptionR:"This non-conformity of various built environments presents many challenges to the trades professionals (“Pros”) as they must draw from decades of hands-on experience to analyze the existing structure (without taking the client’s house apart!) and determine how to order the new replacements.",
            imgUrl:"/assets/windows/oPB-4.png",
        }
    ],
    rp0_PI2:[
        {
            // title:"How can digital technology improve the trades - an industry that’s largely manual and non-digitized?",
            title:"",
            descriptionL:"To answer this question, we studied the day-to-day activities of the Pros, how their time was allocated throughout the life cycle of a window job, and the common causes of job recalls.",
            descriptionR:"There are three major logistical and technical blockers that constraints window renovation jobs:",
            imgUrl:"/assets/windows/oRP-0.png",
        },
        {
            title:"",
            descriptionL:"",
            descriptionR:"Base on the staffing and window measurement challenges, we brainstormed from different angles, and mocked up a few approaches to evaluate their practicality.",
            imgUrl:"",
        }
    ],
    rp1_PBIn:[
        {
            title:"Approach 1: Remote Assistance via Video Chat",
            description:"In an ideal world, if we only send the Senior-level Pros to measure windows, the measurements will be reliable, new windows will be made to the right size, and installations will complete in the first path.",
            imgUrl:"/assets/windows/RP-0.png",
            title0:"",
            bullet0:" ",
            title1:"In practice, ",
            bullet1:" with traveling taking up 60-70% of the Pro’s day, and the fact that Forge only employs 2 senior Pros, it’s impractical for the senior Pro to show up to each job site.",
            title2:"With 10x more junior Pros than senior Pros at Forge, ",
            bullet2:"if we allow senior Pros to remotely assist the onsite junior Pros without traveling themselves, we can leverage their expertise on a much greater scale.",
        },
        {
            title:"Approach 2: Automate Measurements using AI",
            description:"AI has been increasingly used for extracting spatial data. Can we leverage machine learning to reduce human errors? We tested out both depth-based (3D) and image-based (2D) solutions available in the market. However, neither approach was able to provide the level of precision required for windows: ",
            imgUrl:"/assets/windows/RP-1.png",
            title0:"",
            bullet0:"",
            title1:"3D scanning results ",
            bullet1:"vary significantly in different environment, making it hard to achieve stable level of precision",
            title2:"2D image recognition algorithms ",
            bullet2:"often fail to pick up details around window trims - which are essential factors of the measurement",
        },
        {
            title:"Approach 3: Asynchronous Record + Review of Measurements",
            description:"While Approach 1 and 2 didn’t work out, the learnings we gained during the process led us to an approach that will:",
            imgUrl:"/assets/windows/RP-2.png",
            title0:"Mocking up the live-streaming approach",
            bullet0:" proved that videos alone are sufficient for Senior Pros to evaluate measurement correctness",
            title1:"3D and 2D image recognition interface ",
            bullet1:"effectively eliminates manual transcriptions - a frequent source of human error in measurement",
            title2:"Eventually, we arrived at a new workflow ",
            bullet2:"where the Junior Pros capture videos onsite, while the Senior Pros evaluate the videos offsite, asynchronously. Going a step further, we replaced traditional tape measures with Bluetooth-connected digital ones, so that numbers are automatically uploaded without any human transcription.",
        }
    ],
    dp0_PI2:[
        {
            title:"To enable the asynchronous record and review workflow between Junior Pros and Senior Pros, we mapped out the user stories of both junior pros & senior pros, and designed for their experiences respectively.",
            descriptionL:"",
            descriptionR:"For Junior Pros to measure windows, they need to travel to the job sites on their own, where they are expected to: tour the client’s house, video-capture how each window is measured, answer any questions from the clients at the same time:",
            imgUrl:"/assets/windows/oDP-0.png",
        },
        {
            title:"",
            descriptionL:"With navigating between  2~3 jobs sites a day, and 4~10 windows per job site, the amount of information to keep track of can easily overload.",
            descriptionR:"After shadowing how Pros work in the field, we identified places where hardware and software interfaces can effectively help.",
            imgUrl:"",
        },
        {
            title:"① Enable Multitasking",
            descriptionL:"Make video-recording as hands-free as possible, so that Junior Pros can work with their hands uninterrupted.",
            descriptionR:"",
            imgUrl:"/assets/windows/oDP-1.png",
        },
        {
            title:"② Fool-proof data entry",
            descriptionL:"Auto-complete job-related data entries as much as possible",
            descriptionR:"",
            imgUrl:"/assets/windows/oDP-2.png",
        },
        {
            title:"③ Self-Review",
            descriptionL:"After returning from the job site, Jr. Pros conduct self-review before submitting measurements",
            descriptionR:"",
            imgUrl:"/assets/windows/oDP-3.png",
        },
    ],
    dp1_PI2:[
        {
            title:"To enable the asynchronous record and review workflow between Junior Pros and Senior Pros, we mapped out the user stories of both Junior & Senior Pros, and designed for their experiences respectively.",
            descriptionL:"For Senior Pros who work from the office desk, they need to review dozens of measurement recordings each day, and determine whether the submissions are usable or should be re-visited.",
            descriptionR:"",
            imgUrl:"/assets/windows/oDP-4.png",
        },
        {
            title:"① Determine if measuring alignment and add offset amount",
            descriptionL:"If alignment is wrong, reject the Junior Pros submission and flag the window for re-measure.",
            descriptionR:"If alignment looks good, add the adjustment amount - estimated by the Senior Pro themselves - to the final measurement.",
            imgUrl:"/assets/windows/oDP-5.png",
        },
        {
            title:"② Review Window Spec Completion for Each Job",
            descriptionL:"Determine if a window job has all its measurements and specs ready for placing an order with the manufacturer.",
            descriptionR:"",
            imgUrl:"/assets/windows/oDP-6.png",
        },
    ],
    do0_PI2:[
        {
            title:"",
            descriptionL:"",
            descriptionR:"",
            imgUrl:"/assets/windows/oDO-01.png",
        },
        {
            title:"",
            descriptionL:"",
            descriptionR:"",
            imgUrl:"/assets/windows/oDO-2.png",
        },
        {
            title:"",
            descriptionL:"",
            descriptionR:"",
            imgUrl:"/assets/windows/oDO-3.png",
        },
        {
            title:"",
            descriptionL:"",
            descriptionR:"",
            imgUrl:"/assets/windows/oDO-4.png",
        },
    ],
    do1_PI2:[
        {
            title:"",
            descriptionL:"",
            descriptionR:"",
            imgUrl:"/assets/windows/oDO-5.png",
        },
        {
            title:"",
            descriptionL:"",
            descriptionR:"",
            imgUrl:"/assets/windows/oDO-6.png",
        },
        {
            title:"",
            descriptionL:"",
            descriptionR:"",
            imgUrl:"/assets/windows/oDO-7.png",
        },
    ],
    do2_PI2:[

        {
            title:"",
            descriptionL:"",
            descriptionR:"",
            imgUrl:"/assets/windows/oDO-8.png",
        },
        {
            title:"",
            descriptionL:"",
            descriptionR:"",
            imgUrl:"/assets/windows/oDO-9.png",
        },
        {
            title:"",
            descriptionL:"",
            descriptionR:"",
            imgUrl:"/assets/windows/oDO-10.png",
        },
    ]
}

export default windowsData