import React from 'react';
import ReactPlayer from 'react-player'

class PIV0 extends React.Component{
    constructor(){
        super();
        this.state = {

        };
    }

    render(){
        return(
            <div>
                {/*<h4>{this.props.data.title}</h4>*/}
                <div className="pofo-text-row">
                    <div className="pofo-text-col" style={{width:"80vw",paddingLeft:"14vw",paddingRight:"15vw"}}>
                        <div style={this.props.data.descriptionL==="" ? { display: 'none' }:{paddingBottom:"1vw"}}>
                            {this.props.data.description}
                        </div>
                    </div>
                </div>
                <div >
                    <img src={this.props.data.imgUrl} style={{width:"30vw", height:"22.88vw",float:"left",paddingLeft:"5vw",paddingRight:"6vw"}}/>
                    <ReactPlayer url={this.props.data.vUrl} width={"30vw"} height={"22.88vw"} style={{float:"left",padding:"0",position:"relative"}} playing loop/>
                </div>
            </div>
        )
    }
    // render(){
    //     return(
    //         <div>
    //             {/*<h4>{this.props.data.title}</h4>*/}
    //             <div className="pofo-text-row">
    //                 <div className="pofo-text-col">
    //                     <div style={this.props.data.description==="" ? { display: 'none' }:{paddingBottom:"1vw"}}>
    //                         {this.props.data.description}
    //                     </div>
    //                 </div>
    //                 <div className="pofo-text-col">
    //                     <div></div>
    //                 </div>
    //             </div>
    //             <div >
    //                 <img src={this.props.data.imgUrl} style={{width:"30vw", height:"22.88vw",float:"left",paddingLeft:"5vw",paddingRight:"6vw"}}/>
    //                 <ReactPlayer url={this.props.data.vUrl} width={"30vw"} height={"22.88vw"} style={{float:"left",padding:"0",position:"relative"}} playing loop/>
    //                 {/*<iframe src={this.props.data.vUrl} style={{width:"30vw", height:"22.88vw",paddingTop:"0"}} data-allow={"autoplay=1; fullscreen"} autoplay="1">*/}
    //                 {/*</iframe>*/}
    //             </div>
    //         </div>
    //     )
    // }
}

export default PIV0