const ctechData = [
    {
        id:1,
        imgUrl:"/assets/dw/dw_cover.jpg",
        title:"Digital Whirligig",
        description:"Interactive Toy for Learning Geometry",
        link:"/dw",
        hashtag:"#AR #Education #UX #UI #Coding #PhysicalComputing #Fabrication",
        obj_colors:[],
        obj_urls:[],
    },
    {
        id:2,
        imgUrl:"",
        title:"Bas-Relief from 3D models",
        description:"Jasperware models generated by the C++ Implementation of a constrained convex optimization algorithm",
        link:"/br",
        hashtag:"#GeometryProcessing #ComputerGraphics #ConvexOptimization #Coding #C++",
        obj_colors:[],
        obj_urls:[],
    },
    {
        id:3,
        imgUrl:"/assets/temp.png",
        title:"Atomu",
        description: "Depth-Sensitive Display of Human Anatomy",
        link:"/atomu",
        hashtag:"#AR #UX #Installation #Coding #PhysicalComputing #Fabrication",
        obj_colors:[
            "rgb(250,235,215)",
            "rgb(219,128,135)",
            "rgb(255,228,196)",
            "rgb(255,255,255)",
            "rgb(200,15,15)",
            "rgb(15,111,255)",
            "rgb(0,0,0)",
            "rgb(255,182,193)"],
        line_colors:[
            "rgb(244,209,164)",
            "rgb(210,96,105)",
            "rgb(255,209,153)",
            "rgb(234,233,234)",
            "rgb(166,12,12)",
            "rgb(0,92,230)",
            "rgb(0,0,0)",
            "rgb(255,153,168)"
        ],
        obj_urls:[
            "/assets/obj/atomu/brain.obj",
            "/assets/obj/atomu/lower_brain.obj",
            "/assets/obj/atomu/center_back.obj",
            "/assets/obj/atomu/eye_teeth.obj",
            "/assets/obj/atomu/tongue_red.obj",
            "/assets/obj/atomu/eye_blue.obj",
            "/assets/obj/atomu/eye_black.obj",
            "/assets/obj/atomu/gum.obj",
        ]
    },
]
export default ctechData