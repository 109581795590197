import React from 'react';

class Clickzoom13 extends React.Component{
    constructor(){
        super();
        this.state = {
        };
    }

    render(){
        return(
            <div>
                <div className="pofo-text-row">
                    <div className="pofo-text-col" style={{width:"80vw",padding:"2vw 15vw 0vw 14vw"}}>
                        <b style={this.props.data.title==="" ? { display: 'none' }:{fontSize:"18px", paddingBottom:"2vw"}}>{this.props.data.title}</b>
                        <p style={this.props.data.descriptionL==="" ? { display: 'none' }:{paddingBottom:"10px"}}>
                            <div style={{paddingBottom:"14px"}}>{this.props.data.descriptionL}</div>
                            <div>{this.props.data.descriptionR}</div>
                        </p>
                    </div>
                </div>
                <div class="box" style={{backgroundColor:"#f8f8f8",width:"74vw",margin:" 1vw 3vw 6vw 3vw"}}>
                    <div id="lb-back-h">
                        <div id="lb-img-h"></div>
                    </div>
                    <div id="lb-back-w">
                        <div id="lb-img-w"></div>
                    </div>
                    <ul id="row-1" style={{padding:"2.2vw 2vw 0vw 2vw", margin: "0"}}>
                        <li style={{margin:"1vw"}}><img src={this.props.data.imgUrl1} className="zoomDH" style={{width: "62vw",boxShadow:"0px 0px 4px #aaa"}}/></li>
                    </ul>
                    <ul id="row-2" style={{padding: "0vw 7vw 2vw 7vw",margin: "0", display: "inline-flex", marginBlockStart: "0",marginBlockEnd: "0",marginInlineStart: "0",marginInlineEnd: "0"}}>
                        <li style={{margin:"2vw"}}><img src={this.props.data.imgUrl2} className="zoomDW" style={{width: "15vw", margin:"0", maxWidth:"30vw",boxShadow:"0px 0px 4px #aaa"}}/></li>
                        <li style={{margin:"2vw"}}><img src={this.props.data.imgUrl3} className="zoomDW" style={{width: "15vw", margin:"0", maxWidth:"30vw",boxShadow:"0px 0px 4px #aaa"}}/></li>
                        <li style={{margin:"2vw"}}><img src={this.props.data.imgUrl4} className="zoomDW" style={{width: "18vw", margin:"0", maxWidth:"40vw",boxShadow:"0px 0px 4px #aaa"}}/></li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Clickzoom13