import React from 'react';
import aboutData from "../../data/aboutData.js"
import ExperienceEntry from "./ExperienceEntry";

class MyUpdates extends React.Component{
    constructor(){
        super();
        this.updates= aboutData.updates.map(dt=>{
            return <ExperienceEntry data={dt}/>
        });
    }

    render(){
        return(
            <div className="exp-text-row">
                <div className="exp-text-col">
                    <div>
                        <h2>{"What am I learning recently?"}</h2>
                    </div>
                    <div className="alt-b">
                        {this.updates}
                    </div>
                </div>
            </div>
        )
    }
}

export default MyUpdates