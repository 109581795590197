import React from 'react';

class SI0 extends React.Component{
    constructor(){
        super();
        this.state = {};
    }

    render(){
        return(
            <div>
                <h4 style={{paddingLeft:"14vw",paddingRight:"15vw"}}>{this.props.data.title}</h4>
                <img src={this.props.data.imgUrl}/>
            </div>
        )
    }
}

export default SI0