const pofoData = [
    {
        id:1,
        imgUrl:"/assets/windows/oCard.jpg",
        title:"Window Measure App",
        description: "Augmenting Home Renovation Workflows with Remote Collaboration",
        link:"/windows",
        hashtag:"#UX #UI #UserResearch #Prototyping #Wearables #Tablet #WebApplication"
    },
    {
        id:2,
        imgUrl:"/assets/dts/dts-card.jpg",
        title:"Deal Troubleshooting",
        description: "A Brand New Experience for Programmatic Deals",
        link:"/dealtroubleshoot",
        hashtag:"#UX #UI #UserResearch #Prototyping #DataVisualization #AdTech #SaaS #WebDesign"
    },
    {
        id:3,
        imgUrl:"/assets/dv/thumb.jpg",
        title:"Visualizing User Surveys",
        description: "Data Visualization of AppCues Surveys for Invest DSP",
        link:"/dv",
        hashtag:"#UX #UI #Prototyping #DataVisualization #AdTech #SaaS"
    },
    {
        id:4,
        imgUrl:"/assets/ross/ross_card.jpg",
        title:"Ross Learning System",
        description:"Redesign UI & UX for the Digital Learning Platform of K-12 School",
        link:"/ross",
        hashtag:"#UX #UI #UserStudy #Wireframing #Prototyping #VisualDesign #WebDesign"
    },
    {
        id:5,
        imgUrl:"/assets/mkct/mkct_card.jpg",
        title:"MakeCents",
        description: "Branding + UI Design for a BlockChain Start-up",
        link:"/makecents",
        hashtag:"#UI #VI #Branding #CompetitiveAnalysis #Wireframing #WebDesign #MobileDesign"
    },
    {
        id:6,
        imgUrl:"/assets/relpro/relpro_card.jpg",
        title:"RelPro",
        description: "UX Design + Prototyping for Map-Search Features of B2B SaaS Platform",
        link:"/relpro",
        hashtag:"#UX #UI #UserStudy #Prototyping #TechnicalResearch #Coding #SaaS #WebDesign"
    },
]
export default pofoData