import React, { Component } from 'react';
import { Link } from "react-router-dom";
// Three js
import * as THREE from 'three';
import * as OBJLoader from 'three-obj-loader';
OBJLoader(THREE);

var object;
var wing_r,wing_l,body,fan_l,fan_r;
class ThreeBeetle extends Component{
    constructor(){
        super();
        this.state = {
            hover: false,
            onLoop: false
        };
    };
    componentDidMount(){
        this.THREE = THREE;
        const objLoader = new this.THREE.OBJLoader();

        this.angle=0;
        const width = this.mount.clientWidth;
        const height = this.mount.clientHeight;
        //ADD SCENE
        this.scene = new THREE.Scene();
        var myscene = this.scene;
        //ADD CAMERA
        this.camera = new THREE.PerspectiveCamera(45, width/height, 0.1, 200);
        this.camera.position.z =1;
        this.camera.position.y = 4;
        this.camera.position.x =8;
        this.camera.rotation.x = -1.5;
        this.camera.rotation.y = 1;
        this.camera.rotation.z = -2.7;
        // IF ATOMU, render this:
        this.THREE = THREE;
        var myscene = this.scene;

        // Render the beetle
        const material0 = new THREE.MeshPhongMaterial({color: 0x403020});
        const material1 = new THREE.MeshPhongMaterial({color: 0xD7F9F7, opacity: 0.6,transparent: true});
        const material = new THREE.MeshLambertMaterial({color: 0x502f1a});

        objLoader.load(
            '/assets/obj/wing_r.obj',
            function ( object ) {
                object.traverse( function ( child ) {
                    if ( child instanceof THREE.Mesh ) {
                        child.material = material;
                    }
                } );
                wing_r = object;
                wing_r.scale.set(.1,.1,.1);
                wing_r.position.set(0.1,-1,-0.5);
                myscene.add( wing_r);
            },
        );
        objLoader.load(
            '/assets/obj/wing_l.obj',
            function ( object ) {
                object.traverse( function ( child ) {
                    if ( child instanceof THREE.Mesh ) {
                        child.material = material;
                    }
                } );
                wing_l = object;
                wing_l.scale.set(.1,.1,.1);
                wing_l.position.set(-0.1,-1,-0.5);
                myscene.add( wing_l );
            },
        );
        objLoader.load(
            '/assets/obj/body.obj',
            function ( object ) {
                object.traverse( function ( child ) {
                    if ( child instanceof THREE.Mesh ) {
                        child.material = material0;
                    }
                } );
                body = object;
                body.scale.set(.1,.1,.1);
                body.position.set(0,-1,-0.5);
                myscene.add( body );
            },
        );
        objLoader.load(
            '/assets/obj/fan_r.obj',
            function ( object ) {
                object.traverse( function ( child ) {
                    if ( child instanceof THREE.Mesh ) {
                        child.material = material1;
                    }
                } );
                fan_r = object;
                fan_r.scale.set(.1,.1,.1);
                fan_r.position.set(0.1,-1,-0.5);
                myscene.add( fan_r );
            },
        );
        objLoader.load(
            '/assets/obj/fan_l.obj',
            function ( object ) {
                object.traverse( function ( child ) {
                    if ( child instanceof THREE.Mesh ) {
                        child.material = material1;
                    }
                } );
                fan_l = object;
                fan_l.scale.set(.1,.1,.1);
                fan_l.position.set(-0.1,-1,-0.5);
                myscene.add( fan_l );
            },
        );
        // End rendering the beetle

        //ADD RENDERER
        this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true  })
        // this.renderer.setClearColor('#000000')
        this.renderer.setSize(width, height)
        this.renderer.maxAnisotropy=1024;
        this.mount.appendChild(this.renderer.domElement)
        // ADD LIGHT
        this.light = new THREE.PointLight( 0xFFFFFF, 2, 90 );
        this.light.position.set( 0, 15, 28 );
        this.scene.add( this.light );
        // this.ablight = new THREE.PointLight( 0xFFFFFF,1.5, 50 );
        // this.ablight.position.set( 0, 20, 0 );
        // this.ab1light = new THREE.AmbientLight( 0xFFFFFF, .1, 10 );
        // this.ab1light.position.set( 0, 5, 0 );
        // this.ab2light = new THREE.PointLight( 0xFFFFFF,1, 50 );
        // this.ab2light.position.set( 0, 0, -5 );
        // this.scene.add( this.light );
        // this.scene.add( this.ablight );
        // this.scene.add( this.ab1light );
        // this.scene.add( this.ab2light );
        this.start()
    }
    componentWillUnmount(){
        this.stop()
        this.mount.removeChild(this.renderer.domElement)
    }
    start = () => {
        if (!this.frameId) {
            this.frameId = requestAnimationFrame(this.animate)
        }
    }
    stop = () => {
        cancelAnimationFrame(this.frameId)
    }
    animate = () => {
        // if (this.state.hover){
        //     if(this.camera.position.x>0.00001||this.camera.position.x<=0.00001){
        //         var xdiff=this.camera.position.x/6;
        //         var ydiff=this.camera.position.y/6;
        //         this.camera.position.x-=xdiff;
        //         this.camera.position.y-=ydiff;
        //     }
        // }
        // else{
        //     this.angle += 0.012;
        //     this.camera.position.x = Math.cos(this.angle);
        //     this.camera.position.y =Math.sin(this.angle);
        // }
        // this.camera.lookAt( this.scene.position );
        this.angle += 0.012;
        if (this.scene.children[2]){
            this.scene.children[1].rotation.z= Math.sin(this.angle)/32;
            this.scene.children[2].rotation.z= -Math.sin(this.angle)/32;
            this.scene.children[1].rotation.y= Math.sin(this.angle)/100;
            this.scene.children[2].rotation.y= -Math.sin(this.angle)/100;
        }

        this.renderScene()
        this.frameId = window.requestAnimationFrame(this.animate)
    }
    renderScene = () => {
        this.renderer.render(this.scene, this.camera)
    }
    render(){
            return(
                    <div>
                        <div
                            style={{ width: '25vw', height: '30vw', overflow:"right"}}
                            ref={(mount) => { this.mount = mount }}
                            onPointerEnter={()=>this.setState({hover:true})} onPointerLeave={()=>this.setState({hover:false})}/>
                    </div>
            )

    }
}
export default ThreeBeetle


