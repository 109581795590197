import React from 'react';

class PBIn extends React.Component{
    constructor(){
        super();
        this.state = {

        };
    }
    render(){
        return(
            <div>
                {/*<h4>{this.props.data.title}</h4>*/}
                <div className="pofo-text-row" >
                    {/*<div className="pofo-text-col-sticky" style={{width:"40vw", flex:"none", position:"sticky",top:"0"}}>*/}
                    <div className="pofo-text-col-sticky" style={{width:"28vw"}}>
                        <h4 style={{width:"28vw", paddingBottom:"1.5vw",paddingTop:"1vw"}}>{this.props.data.title}</h4>
                        <div style={this.props.data.description==="" ? { display: 'none' }:{paddingBottom:"1vw",width:"28vw"}}>
                            {this.props.data.description}
                        </div>
                        <div className="pofo-text-col-col" style={{width:"30vw"}}>
                            <b>{this.props.data.title0}</b>{this.props.data.bullet0}
                        </div>
                        <div className="pofo-text-col-col" style={{width:"30vw"}}>
                            <b>{this.props.data.title1}</b>{this.props.data.bullet1}
                        </div>
                        <div className="pofo-text-col-col" style={{width:"30vw"}}>
                            <b>{this.props.data.title2}</b>{this.props.data.bullet2}
                        </div>
                        <div className="pofo-text-col-col" style={{width:"30vw"}}>
                            <b>{this.props.data.title3}</b>{this.props.data.bullet3}
                        </div>
                    </div>
                    <div className="pofo-img-col" style={{width:"48vw", overflow:"auto", marginTop:"5vw",flex:"none",position:"relative"}}>
                        <img id="display-c" src={this.props.data.imgUrl} style={{width:"42vw",float:"right",margin:"0",position:"relative"}}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default PBIn