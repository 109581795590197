const pofo3Data = [
    {
        id:1,
        imgUrl:"/assets/vt/thumb.jpg",
        title:"Inspection Tools for 3D Modeling",
        description: "Revamp the Inspection Experience for Field-Driven Design",
        link:"/viewtools",
        hashtag:"#UX #3D #UserResearch #Prototyping #WebGL #B2B"
    },
    {
        id:2,
        imgUrl:"/assets/cc/thumb.jpg",
        title:"Camera Control Interactions",
        description: "3D View Control with Ease and Familiarity",
        link:"/cameracontrols",
        hashtag:"#UX #3D #Usability #VisualPerception #Prototyping #Unity"
    },
    {
        id:3,
        imgUrl:"/assets/vr/thumb.jpg",
        title:"Transparency in 3D Modeling",
        description: "Research on New Rendering Modes for nTopology",
        link:"/transparency",
        hashtag:"#Research #3D #Rendering #RayMarching #VisualPerception"
    },
    {
        id:4,
        imgUrl:"/assets/vr/thumb.jpg",
        title:"WebXR Projects",
        description: "Virtual Reality Experiences Through the Web",
        link:"/vr",
        hashtag:"#VR #AR #XR #3D #UX #WebXR #WebGL #Javascript"
    },
]
export default pofo3Data