import React, { Component } from 'react';
import RayMarcher from '../../utils/shaderUtils/raymarcher.js';
import * as THREE from "three";
import {Link} from "react-router-dom";
let rm;
class ShaderScene1 extends Component{
    constructor(){
        super();
        this.state = {
            hover: false,
            onLoop: false
        };
        this.rm = rm;
        this.onWindowResize= this.onWindowResize.bind(this);
    }

    componentDidMount(){
        this.width = this.mount.clientWidth;
        this.height = this.mount.clientHeight;
        if (this.props.fs==0)
            this.rm = new RayMarcher(50, 0.01 ).setSize( this.width,this.height ).loadFragmentShader( "./fs/noise_bulb.fs", this.animate );
        else
            this.rm = new RayMarcher(50, 0.01 ).setSize( this.width,this.height ).loadFragmentShader( "./fs/noise_bulb_2.fs", this.animate );
        //this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true  })
        this.mount.appendChild( this.rm.domElement );
        this.init();
    }
    // this resize function doesn't work for now...
    onWindowResize = () => {
        this.rm.setSize( this.height,this.height );
        this.rm.renderer.setSize(this.width,this.height);
        this.rm.aspect = this.width/this.height;
        this.rm.camera.aspect = this.aspect;
        this.rm.camera.updateProjectionMatrix();
        this.animate();
    }

    init = () => {
        this.animate();
    }

    animate = () => {
        requestAnimationFrame( this.animate );
        //update uniforms
        this.rm.update();
        //render
        this.rm.render();
    }

    render(){
        //if(this.cmdOrCtrl)
        return(
            //<div style={{padding:"0",zIndex:"0",alignItems: "center", textAlign: "center",}}>
                <div
                style={{ width: '100vw', height: '70vh'}}
                ref={(mount) => { this.mount = mount }}
                onPointerEnter={()=>this.setState({hover:true})} onPointerLeave={()=>this.setState({hover:false})}/>
            //</div>
        )
        //else return(<div></div>);
    }
}

export default ShaderScene1