import React from 'react';

class Clickzoom21 extends React.Component{
    constructor(){
        super();
        this.state = {

        };
    }

    render(){
        return(
            <div>
                <div className="pofo-text-row">
                    <div className="pofo-text-col" style={{width:"80vw",padding:"2vw 15vw 0vw 14vw"}}>
                        <b style={this.props.data.title==="" ? { display: 'none' }:{fontSize:"18px", paddingBottom:"2vw"}}>{this.props.data.title}</b>
                        <p><span>{this.props.data.descriptionL}</span><span>{this.props.data.descriptionR}</span></p>
                    </div>
                </div>
                <div class="box" style={{backgroundColor:"#f8f8f8",width:"74vw",margin:" 1vw 3vw 5vw 3vw"}}>
                    <div id="lb-back-h">
                        <div id="lb-img-h"></div>
                    </div>
                    <div id="lb-back-w">
                        <div id="lb-img-w"></div>
                    </div>
                    <ul id="row-1-light" style={{padding: "2vw 4.4vw 0vw 4.4vw",display: "inline-flex", marginBlockStart: "0",marginBlockEnd: "0",marginInlineStart: "0",marginInlineEnd: "0"}}>
                        <li style={{margin:"2vw"}}><img src={this.props.data.imgUrl1} className="zoomDH" style={{width: "37vw",maxHeight:"50vh", margin:"0", boxShadow:"0px 0px 2px #ccc"}}/></li>
                        <li style={{margin:"2vw"}}><img src={this.props.data.imgUrl2} className="zoomDH" style={{width: "20vw",maxHeight:"50vh", margin:"0",boxShadow:"0px 0px 2px #ccc"}}/></li>
                    </ul>
                    <ul id="row-2-light" style={{padding:"0vw 4.4vw 1.6vw 4.4vw", margin: "0"}}>
                        <li style={{margin:"2vw"}}><img src={this.props.data.imgUrl3} className="zoomDW" style={{width: "61vw",margin:"0", boxShadow:"0px 0px 2px #ccc"}}/></li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Clickzoom21