import React from 'react';

class Clickzoom44 extends React.Component{
    constructor(){
        super();
        this.state = {
        };
    }

    render(){
        return(
            <div>
                <div className="pofo-text-row">
                    <div className="pofo-text-col" style={{width:"80vw",padding:"2vw 15vw 0 14vw"}}>
                        <b style={this.props.data.title==="" ? { display: 'none' }:{fontSize:"18px",paddingBottom:"2vw"}}>{this.props.data.title}</b>
                        <p style={this.props.data.descriptionL==="" ? { display: 'none' }:{paddingBottom:"10px"}}>
                            <div style={{paddingBottom:"14px"}}>{this.props.data.descriptionL}</div>
                            <div>{this.props.data.descriptionR}</div>
                        </p>
                    </div>
                </div>
                <div class="box" style={{backgroundColor:"#2e2e37", width:"74vw",margin:" 1vw 3vw 0vw 3vw"}}>
                    <div id="lb-back-h">
                        <div id="lb-img-h"></div>
                    </div>
                    <ul id="row-2-dark" style={{padding: "1vw 2.6vw 1vw 2.6vw",margin: "0", display: "inline-flex", marginBlockStart: "0",marginBlockEnd: "0",marginInlineStart: "0",marginInlineEnd: "0"}}>
                        <li style={{margin:"1.1vw"}}><p>{this.props.data.imgTxt1}</p><img src={this.props.data.imgUrl1} className="zoomDH" style={{width: "15vw", margin:"0"}}/></li>
                        <li style={{margin:"1.1vw"}}><p>{this.props.data.imgTxt2}</p><img src={this.props.data.imgUrl2} className="zoomDH" style={{width: "15vw", margin:"0"}}/></li>
                        <li style={{margin:"1.1vw"}}><p>{this.props.data.imgTxt3}</p><img src={this.props.data.imgUrl3} className="zoomDH" style={{width: "15vw", margin:"0"}}/></li>
                        <li style={{margin:"1.1vw"}}><p>{this.props.data.imgTxt4}</p><img src={this.props.data.imgUrl4} className="zoomDH" style={{width: "15vw", margin:"0"}}/></li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Clickzoom44