import React from 'react';
import ReactPlayer from 'react-player'

class PIV1 extends React.Component{
    constructor(){
        super();
        this.state = {

        };
    }

    // render(){
    //     return(
    //         <div>
    //             <div className="pofo-text-row">
    //                 <div className="pofo-text-col" style={{width:"80vw",paddingLeft:"16vw",paddingRight:"17vw"}}>
    //                     <b style={this.props.data.title1==="" ? { display: 'none' }:{}}>{this.props.data.title1}</b>
    //                 </div>
    //             </div>
    //             <img src={this.props.data.imgUrl} style={{paddingBottom:"30px"}}/>
    //             <div className="pofo-text-row">
    //                 <div className="pofo-text-col" style={{width:"80vw",paddingLeft:"16vw",paddingRight:"17vw"}}>
    //                     <b>{this.props.data.title2}</b>
    //                     <p><div style={{paddingBottom:"8px"}}>{this.props.data.descriptionL}</div>
    //                         {this.props.data.descriptionR}<a href={this.props.data.linkUrl}>{this.props.data.linkUrl}</a></p>
    //                     <img style={this.props.data.description==="" ? { display: 'none' }:{width:"23vw"}} src={this.props.data.imgUrl1} className="zoomDH" />
    //                 </div>
    //                 <div className="pofo-text-col" style={{paddingLeft:"2vw"}}>
    //                     <ReactPlayer url={this.props.data.vUrl} width={"40vw"} height={this.props.data.vHeight} style={{float:"left",margin:"0vw 0vw",position:"relative"}} playing loop/>
    //                 </div>
    //             </div>
    //
    //         </div>
    //     )
    // }
    render(){
        return(
            <div>
                <div className="pofo-text-row">
                    <div className="pofo-text-col" style={{width:"80vw",paddingLeft:"14vw",paddingRight:"15vw"}}>
                        <b style={this.props.data.title1==="" ? { display: 'none' }:{fontSize:"18px",lineHeight:"20px",paddingTop: "10px"}}>{this.props.data.title1}</b>
                    </div>
                </div>
                <img src={this.props.data.imgUrl} style={{paddingBottom:"0vw"}}/>
                <div className="pofo-text-row">
                    <div className="pofo-text-col" style={{width:"80vw",paddingLeft:"14vw",paddingRight:"15vw"}}>
                        <b style={this.props.data.title1==="" ? { display: 'none' }:{fontSize:"18px",lineHeight:"20px",paddingBottom:"1vw"}}>{this.props.data.title2}</b>
                        <p><div style={{paddingBottom:"14px"}}>{this.props.data.descriptionL}</div>
                            <div>{this.props.data.descriptionR}<a href={this.props.data.linkUrl}>{this.props.data.linkUrl}</a></div>
                            </p>
                    </div>

                </div>
                <div className="pofo-text-row">
                    <div className="pofo-text-col" style={this.props.data.imgUrl1=="" ? { display: 'none' }:{width:"50vw",paddingLeft:"4vw",paddingRight:"0vw"}}>
                        <img style={{width:"34vw", margin:"0"}} src={this.props.data.imgUrl1} className="zoomDH" />
                    </div>
                    <div className="pofo-text-col" style={this.props.data.imgUrl1=="" ? {margin:"0vw 21.5vw"}:{width:"36vw",paddingLeft:"0vw",paddingRight:"2.5vw"}}>
                        <ReactPlayer url={this.props.data.vUrl} width={this.props.data.imgUrl1=="" ? "36vw":"34vw"} height={this.props.data.imgUrl1=="" ?"28vw":"23vw"} style={{float:"left",position:"relative"}} playing loop/>
                    </div>
                </div>

            </div>
        )
    }
}

export default PIV1