import React from 'react'

import 'bootstrap/dist/js/bootstrap.js';
import $ from 'jquery';

import "../css/fonts.css"
import "../css/MainApp.css"
import "../css/pofo.css"
import "../css/about.css"
import pofo3Data from "../data/pofo3Data"
import "../css/pofo.css";
import ThreeCard from './threejsComponents/ThreeCard';
import ThreeCard3 from './threejsComponents/ThreeCard3';
import ThreeCard5 from './threejsComponents/ThreeCard5';
// import beetleData from "../data/beetleData"
// import ThreeBeetle from './threejsComponents/ThreeBeetle';
import ShaderScene1 from './shaderComponents/shader_scene_1.js';
import FS1 from './shaderComponents/FS1.js';


class PD3 extends React.Component {

    render() {
        $('#root').css('background-color', '#f8f8f8');
        // window.scrollTo(0, 1);

        this.pofoCards = pofo3Data.map(pofo => {
            if(pofo.id==3){
                return <ThreeCard5 key={pofo.id} data={pofo} macos={window.navigator.platform.match(/^Mac/)}/>
            }
            else if(pofo.id==4){
                return <ThreeCard3 key={pofo.id} data={pofo} macos={window.navigator.platform.match(/^Mac/)}/>
            }
            else{
                return <ThreeCard key={pofo.id} data={pofo} macos={window.navigator.platform.match(/^Mac/)}/>
            }
        })

        // if (window.innerWidth < 600)
        //     return (<div style={{paddingLeft:"4px",marginTop:"60px"}}><h4>* Please view on desktop screen :)!</h4></div>);

        return(
            <div className='todo-list'>
                <div style={{paddingLeft:"0vw", alignItems: "center", textAlign: "left", height:"62vh"}}>
                    <div style={{position:"fixed", top:"0px",zIndex:"0", margin:"0",padding:"0"}}>
                        { window.navigator.platform.match(/^Mac/) ?
                            <ShaderScene1 fs={0}/>:
                            <iframe style={{width: "100vw", height: "105vh", overflow: "hidden", marginTop: "-50px"}} scrolling="no"
                                    frameBorder="0" src="https://editor.p5js.org/yuepingwang/embed/S1G3K9XLb"></iframe>
                        }
                    </div>
                    <div style={{paddingLeft:"0vw", alignItems: "left",margin:"20vh 0 20vh 15vw", zIndex:"10"}}>
                        <h6 style={{fontSize: "34px", fontFamily: "MarkPro-Medium", color:"#000000", lineHeight:"2.1",fontWeight: "normal"}}><span style={{backgroundColor:"#f8f8f8"}}>Hi! I’m Yueping :)</span></h6>
                        <h6><span style={{backgroundColor:"#f8f8f8"}}>I’m a Product Designer and Programmer based in NYC.
                        <br/>Thanks for checking out my 3D UX designs :) </span></h6>
                    </div>
                </div>
                <div style={{zIndex:"98"}}>
                    {this.pofoCards}
                </div>
            </div>
        )
    };
}

export default PD3