import React from 'react';

class GalleryColLeft extends React.Component{
    constructor(){
        super();
        this.state = {

        };
    }

    render(){
        return(
                <div className="pofo-text-row">

                    <div className="pofo-text-col-small">
                        {this.props.data.description}
                    </div>
                    <div className="pofo-img-col-small">
                        <img src={this.props.data.imgUrl} />
                    </div>
                </div>
        )
    }
}

export default GalleryColLeft