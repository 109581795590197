import React from 'react';

class PI0 extends React.Component{
    constructor(){
        super();
        this.state = {

        };
    }

    render(){
        return(
            <div>
                <div className="pofo-text-row">
                    <div className="pofo-text-col" style={{width:"80vw",paddingLeft:"14vw",paddingRight:"15vw"}}>
                        <b style={this.props.data.title==""||!this.props.data.title ? { display: 'none' }:{fontSize:"18px",lineHeight:"22px",paddingTop:".5vw"}}>{this.props.data.title}</b>
                        <p style={this.props.data.descriptionL=="" ? { display: 'none' }:{paddingBottom:"10px"}}>
                            <div>{this.props.data.descriptionL}</div>
                            <div style={this.props.data.descriptionR=="" ? { display: 'none' }:{paddingTop:"14px"}}>{this.props.data.descriptionR}</div>
                        </p>
                    </div>
                </div>
                <img style={this.props.data.imgUrl==="" ? { display: 'none' }:{}} src={this.props.data.imgUrl}/>
            </div>
        )
    }
}

export default PI0