const aboutData = {
    id:100,
    intro_paragraphs:[
        {p:"Yueping Wang is a Product Designer and Programmer based in Brooklyn, NY. With 7 years of industry experience, she leads the design of B2B and B2C products for a variety of logistical, technical and business challenges."},
        {p:"Currenly she works on remote collaboration tools for trades professionals to tackle complex construction and renovation workflows. Previoulsy, she has led end-to-end product design of parametric modeling software at nTop (formerly nTopology), SaaS platforms for programmatic advertising at Roku and Xandr (a Microsoft company), and many 0-1 products at fast-paced startups."},
        {p:"Yueping has a background in both Industrial Design (RISD) and Computer Science (NYU), which allows her to fluently communicate technical trade-offs and opportunities with cross-functional teams."},
    ],
    cv_link:{
        pdfUrl:"/files/yueping_wang_resume_2024.pdf"
    },
    intro_img:{
        imgUrl:"/assets/profile.jpg"
    },
    experience_entries:{
        y2024:[
            {   title:"Forge",
                description:": Senior Product Designer",
            }
        ],
        y2022:[
            {   title:"nTop (f.k.a nTopology)",
                description:": Product Designer and Prototyper, 3D UX",
            },
            {   title:"NYU Courant",
                description:": Finished my MS CS degree (part-time)",
            }
        ],
        y2021:[
            {   title:"Xandr (now a Microsoft company)",
                description:": Product Designer II",
            }
        ],
        y2019:[
            {   title:"Xandr (now a Microsoft company)",
                description:": Product Designer",
            },
            {   title:"Ross School",
                description:": UI/UX Design Consultant",
            },
            {   title:"Other",
                description:": Freelance Work",
            },
        ],
        y2018:[
            {   title:"Ross School",
                description:": UI/UX Design Consultant",
            },
            {   title:"MakeCents (a Blockchain start-up)",
                description:": Design Intern",
            }
        ],
        y2017:[
            {   title:"RelPro (a B2B SaaS company)",
                description:": Design and Development Intern",
            },
            {   title:"NYU ITP",
                description:": Teaching Assistant for ITP Summer Camp",
            },
            {   title:"NYU Stern",
                description:": Start-up project with MBA candidates (first pitch for a high-tech product idea, then do product management for the MVP).",
            },
        ],
        y2016:[
            {   title:"Woobo (an EdTech start-up)",
                description:": Design Intern (software + hardware)",
            },
        ],
        y2015:[
            {   title:"LEGO Education",
                description:": Student Researcher during the joint Advanced Studio by RISD and LEGO",
            },
        ],
        y2014:[
            {   title:"NASA Lunar Rover Challenge",
                description:": Core team member for designing and building RISD's Lunar Rover. Racer of the team.",
            },
        ],
        pre:[
            {   title:"Miscellaneous experimental art school projects across many design disciplines",
                description:"",
            }
        ],
    },
    experience_2018:[
        {
            title:"Ross School",
            description:"Design Consultant",
        },
        {
            title:"Freelance Work",
            description:"",
        }
    ],
    updates:[
        {
            title:"More React: ",
            description:"Practicing using React to build reusable front-end components, and using Next.js to deploy simple server-side rendering.",
        },
        {
            title:"Implicit Geometry: ",
            description:"Experimenting with fragment shaders to model and render implicit geometry. ",
        },
        {
            title:"Machine Learning: ",
            description:"Reflecting on not just how it influences product design decisions, but also, contrarily, how UX design can be used to help collect more meaningful user data.",
        },
    ],
    contact:[
        {
            title:"Email:",
            description:"YuepingWang.Design@gmail.com"
        },
        {
            title:"Meet:",
            description:"I live in Brooklyn NY and work in NYC, but I'm also open to remote opportunities and checking out cool places."
        },
        {
            title:"Resume:",
            description:"(Please email me for a copy!)"
        }
    ],
}
export default aboutData