import React from 'react';
import Overview from './pofoComponents/Overview'
import PI0 from './pofoComponents/PI0'
import PBI1 from './pofoComponents/PBI1'
import PBI2 from './pofoComponents/PBI2'
import PBI3 from './pofoComponents/PBI3'
import PIV0 from './pofoComponents/PIV0'
import SI0 from './pofoComponents/SI0'
import GalleryColLeft from './pofoComponents/GalleryColLeft'
import DesignOutcome from './pofoComponents/DesignOutcome'
import rossData from "../data/rossData.js"
import "../css/pofo.css"
import "../css/sideNav.css"
import $ from 'jquery';

class Ross extends React.Component {

    constructor(){
        super();
        //pass data of this project
        this.state = {
            hasResearch: false,
            hasPrototype:false
        };
        // Outcome
        this.o0_PBI1 = rossData.o0_PBI1.map(idea=>{
            return <PBI1 data={idea}/>
        });
        this.o1_PBI1 = rossData.o1_PBI1.map(idea=>{
            return <PBI2 data={idea}/>
        });
        this.o2_PBI1 = rossData.o2_PBI1.map(idea=>{
            return <PBI3 data={idea}/>
        });
        // Research
        this.r0_PI0 = rossData.r0_PI0.map(idea=>{
            return <PI0 data={idea}/>
        });
        this.r1_PI0 = rossData.r1_PI0.map(idea=>{
            return <PI0 data={idea}/>
        });
        this.r2_PI0 = rossData.r2_PI0.map(idea=>{
            return <PI0 data={idea}/>
        });
        this.p0_PI0 =rossData.p0_PI0.map(idea=>{
            return <PI0 data={idea}/>
        });
        this.p1_PI0 =rossData.p1_PI0.map(idea=>{
            return <PI0 data={idea}/>
        });
        this.p2_PI0 =rossData.p2_PI0.map(idea=>{
            return <PI0 data={idea}/>
        });
        this.s0_PIV0 = rossData.prototyping.map(idea=>{
            return <PIV0 data={idea}/>
        })
    };

    componentDidMount(){
        //Switch white scheme
        $('#navbar').css('background-color', 'white');
        $('#root').css('background-color', 'white');
        document.body.style = 'background: white;';
        // nav -> content
        $(document).ready(function(){
            $('body').scrollspy({target: ".pofo-page", offset: 50});

            // Add smooth scrolling on all links inside the navbar
            $("#side-nav a").on('click', function(event) {
                // Make sure this.hash has a value before overriding default behavior
                if (this.hash !== "") {
                    // Prevent default anchor click behavior
                    event.preventDefault();
                    // Store hash
                    var targetHref = $(this).attr('href');
                    // Using jQuery's animate() method to add smooth page scroll
                    // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
                    $('html, body').animate({
                        scrollTop: $(targetHref).offset().top
                    }, 900);
                }  // End if
            });

            $(window).scroll(function() {
                var scrollDistance = $(window).scrollTop();
                // Assign active class to nav links while scolling
                $('.page-section').each(function(m) {
                    if ($(this).position().top-200<= scrollDistance) {
                        $('.side-nav div a.active').removeClass('active');
                        $('.side-nav div a').eq(m).addClass('active');
                    }
                    else{
                        $('.subgroup').each(function(n) {
                            $('nav ul li a.active').removeClass('active');
                        });
                    }
                });
                $('.subgroup').each(function(n) {
                    if ($(this).position().top-200 <= scrollDistance) {
                        $('nav ul li a.active').removeClass('active');
                        $('nav ul li a').eq(n).addClass('active');
                    }
                });
            }).scroll();

            $(document).ready(function(){
                $("#c-tab-1").on({
                    'click': function(){
                        var targetHref = $(this).attr('href');
                        $('html, body').animate({scrollTop: $(targetHref).offset().top}, 0);
                        // style changes:
                        $(this).css('opacity','1');
                        $('#display-c').attr('src','/assets/ross/Course-1.jpg');
                        $('#c-tab-2').css('opacity','0.5');
                        $('#c-tab-3').css('opacity','0.5');
                    }
                });
                $("#c-tab-2").on({
                    'click': function(){
                        var targetHref = $(this).attr('href');
                        $('html, body').animate({scrollTop: $(targetHref).offset().top}, 0);
                        // style changes:
                        $(this).css('opacity','1');
                        $('#display-c').attr('src','/assets/ross/Course-2.jpg');
                        $('#c-tab-1').css('opacity','0.5');
                        $('#c-tab-3').css('opacity','0.5');

                    }
                });
                $("#c-tab-3").on({
                    'click': function(){
                        var targetHref = $(this).attr('href');
                        $('html, body').animate({scrollTop: $(targetHref).offset().top}, 0);
                        // style changes:
                        $(this).css('opacity','1');
                        $('#display-c').attr('src','/assets/ross/Course-3.jpg');
                        $('#c-tab-1').css('opacity','0.5');
                        $('#c-tab-2').css('opacity','0.5');
                    }
                });
                $("#u-tab-1").on({
                    'click': function(){
                        var targetHref = $(this).attr('href');
                        $('html, body').animate({scrollTop: $(targetHref).offset().top}, 0);
                        // style changes:
                        $(this).css('opacity','1');
                        $('#display-u').attr('src','/assets/ross/Unit-1.jpg');
                        $('#u-tab-2').css('opacity','0.5');
                        $('#u-tab-3').css('opacity','0.5');
                    }
                });
                $("#u-tab-2").on({
                    'click': function(){
                        var targetHref = $(this).attr('href');
                        $('html, body').animate({scrollTop: $(targetHref).offset().top}, 0);
                        // style changes:
                        $(this).css('opacity','1');
                        $('#display-u').attr('src','/assets/ross/Unit-2.jpg');
                        $('#u-tab-1').css('opacity','0.5');
                        $('#u-tab-3').css('opacity','0.5');

                    }
                });
                $("#u-tab-3").on({
                    'click': function(){
                        var targetHref = $(this).attr('href');
                        $('html, body').animate({scrollTop: $(targetHref).offset().top},0);
                        // style changes:
                        $(this).css('opacity','1');
                        $('#display-u').attr('src','/assets/ross/Unit-3.jpg');
                        $('#u-tab-1').css('opacity','0.5');
                        $('#u-tab-2').css('opacity','0.5');
                    }
                });
                $("#m-tab-1").on({
                    'click': function(){
                        var targetHref = $(this).attr('href');
                        $('html, body').animate({scrollTop: $(targetHref).offset().top}, 0);
                        // style changes:
                        $(this).css('opacity','1');
                        $('#display-m').attr('src','/assets/ross/Module-1.jpg');
                        $('#m-tab-2').css('opacity','0.5');
                        $('#m-tab-3').css('opacity','0.5');
                    }
                });
                $("#m-tab-2").on({
                    'click': function(){
                        var targetHref = $(this).attr('href');
                        $('html, body').animate({scrollTop: $(targetHref).offset().top}, 0);
                        // style changes:
                        $(this).css('opacity','1');
                        $('#display-m').attr('src','/assets/ross/Module-2.jpg');
                        $('#m-tab-1').css('opacity','0.5');
                        $('#m-tab-3').css('opacity','0.5');
                    }
                });
            });
        });}

    render() {
        window.scrollTo(0, 0);
        if (window.innerWidth < 600){
            return(
                <div className="pofo-page mobile" >
                    <div style={{width:"90vw", marginLeft:"5vw", float:"left", display:"inline"}}>
                        {/*position: "relative", float:"left",*/}
                        <Overview data={rossData}/>
                        <div>
                            <h2 className="page-section" id="do">DESIGN OUTCOMES</h2>
                            <div id="o0" className="subgroup">
                                {/*<h4 id="o0" className="subgroup">COURSE PAGE</h4>*/}
                                {this.o0_PBI1}
                            </div>
                            <div id="o1" className="subgroup">
                                {/*<h4 id="o1" className="subgroup">UNIT PAGE</h4>*/}
                                {this.o1_PBI1}
                            </div>
                            <div id="o2" className="subgroup">
                                {/*<h4 id="o2" className="subgroup">MODULE PAGE</h4>*/}
                                {this.o2_PBI1}
                            </div>
                        </div>
                        <div>
                            <h2 className="page-section" id="rp">RESEARCH PROCESS</h2>
                            <div>
                                <h4 id="r0" className="subgroup">BACKGROUND STUDY</h4>
                                {this.r0_PI0}
                            </div>
                            <div>
                                <h4 id="r1" className="subgroup">IDENTIFY PAIN POINTS</h4>
                                {this.r1_PI0}
                            </div>
                            <div>
                                <h4 id="r2" className="subgroup">CREATE DESIGN BRIEF</h4>
                                {this.r2_PI0}
                            </div>
                        </div>
                        <div>
                            <h2 className="page-section" id="dp">DESIGN PROCESS</h2>
                            <div>
                                <h4 id="p0" className="subgroup">WIREFRAMING</h4>
                                {this.p0_PI0}
                            </div>
                            <div>
                                <h4 id="p1" className="subgroup">IDEATION & SKETCHING</h4>
                                {this.p1_PI0}
                            </div>
                            <div>
                                <h4 id="p2" className="subgroup">UI & COLOR SCHEMES</h4>
                                {this.p2_PI0}
                            </div>
                        </div>
                        <div>
                            <h2 className="page-section" id="ux">UX IMPROVEMENTS</h2>
                            <div id="sub5-page-section" className="subgroup">
                                {this.s0_PIV0}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return(
            <div className="pofo-page" >
                <div style={{width:"18vw", marginLeft: "0vw", float:"left"}}>
                    <nav className="side-nav" id="side-nav" style={{position: "fixed", display:"inline-block"}}>
                        <div><a href="#overview">OVERVIEW</a></div>
                        <div><a href="#do">DESIGN OUTCOMES</a></div>
                        <ul>
                            <li><a href="#o0">Course Page</a></li>
                            <li><a href="#o1">Unit Page</a></li>
                            <li><a href="#o2">Module Page</a></li>
                        </ul>
                        <div><a href="#rp">RESEARCH PROCESS</a></div>
                        <ul>
                            <li><a href="#r0">Background Study</a></li>
                            <li><a href="#r1">Identify Pain Points</a></li>
                            <li><a href="#r2">Create Design Brief</a></li>
                        </ul>
                        <div><a href="#dp">DESIGN PROCESS</a></div>
                        <ul>
                            <li><a href="#p0">Wireframing</a></li>
                            <li><a href="#p1">Ideation & Sketching</a></li>
                            <li><a href="#p2">UI & Color Schemes</a></li>
                        </ul>
                        <div><a href="#ux">UX IMPROVEMENTS</a>
                        </div>
                    </nav>
                </div>
                <div style={{width:"80vw", marginLeft:"18vw", float:"left", display:"inline"}}>
                    {/*position: "relative", float:"left",*/}
                    <Overview data={rossData}/>
                    <div>
                        <h2 className="page-section" id="do" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>DESIGN OUTCOMES</h2>
                        <div id="o0" className="subgroup">
                            {/*<h4 id="o0" className="subgroup">COURSE PAGE</h4>*/}
                            {this.o0_PBI1}
                        </div>
                        <div id="o1" className="subgroup">
                            {/*<h4 id="o1" className="subgroup">UNIT PAGE</h4>*/}
                            {this.o1_PBI1}
                        </div>
                        <div id="o2" className="subgroup">
                            {/*<h4 id="o2" className="subgroup">MODULE PAGE</h4>*/}
                            {this.o2_PBI1}
                        </div>
                    </div>
                    <div>
                        <h2 className="page-section" id="rp" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>RESEARCH PROCESS</h2>
                        <div>
                            <h4 id="r0" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"1vw"}}>Background Study</h4>
                            {this.r0_PI0}
                        </div>
                        <div>
                            <h4 id="r1" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"3vw"}}>Identify Pain Points</h4>
                            {this.r1_PI0}
                        </div>
                        <div>
                            <h4 id="r2" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"3vw"}}>Create Design Brief</h4>
                            {this.r2_PI0}
                        </div>
                    </div>
                    <div>
                        <h2 className="page-section" id="dp" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>DESIGN PROCESS</h2>
                        <div>
                            <h4 id="p0" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"1vw"}}>Wireframing</h4>
                            {this.p0_PI0}
                        </div>
                        <div>
                            <h4 id="p1" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"3vw"}}>Ideation & Sketching</h4>
                            {this.p1_PI0}
                        </div>
                        <div>
                            <h4 id="p2" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"3vw"}}>UI System & Color Schemes</h4>
                            {this.p2_PI0}
                        </div>
                    </div>
                    <div>
                        <h2 className="page-section" id="ux" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>UX IMPROVEMENTS</h2>
                        <div id="sub5-page-section" className="subgroup">
                            {this.s0_PIV0}
                        </div>
                    </div>
                    {/*<div>*/}
                        {/*<h2 className="page-section" id="do">DESIGN OUTCOMES</h2>*/}
                        {/*<div id="sub5-page-section" className="subgroup">{this.d0_SI0}</div>*/}
                    {/*</div>*/}
                </div>
            </div>
        );
    }
}

export default Ross