import React from 'react';
import ReactDOM from 'react-dom';
// import '../utils/side-nav-interaction'
import Overview from './pofoComponents/Overview'
import PI0 from './pofoComponents/PI0'
import PBI0 from './pofoComponents/PBI0'
import SI0 from './pofoComponents/SI0'
import SV0 from './pofoComponents/SV0'
import GalleryColLeft from './pofoComponents/GalleryColLeft'
import DesignOutcome from './pofoComponents/DesignOutcome'
import relproData2 from "../data/relproData2.js"
import "../css/pofo.css"
import "../css/sideNav.css"
import $ from 'jquery';

class Relpro extends React.Component {

    constructor(){
        super();
        //pass data of this project
        this.state = {
            hasResearch: false,
            hasPrototype:false
        };
        this.r0_PI0 = relproData2.r0_PI0.map(dt=>{
            return <PI0 data={dt}/>
        });
        this.r1_PI0 = relproData2.r1_PI0.map(dt=>{
            return <PI0 data={dt}/>
        });
        this.p0_PBI0 = relproData2.p0_PBI0.map(idea=>{
            return <PBI0 data={idea}/>
        });
        this.p1_PBI0 = relproData2.p1_PBI0.map(idea=>{
            return <PBI0 data={idea}/>
        });
        this.p2_PI0 = relproData2.p2_PI0.map(idea=>{
            return <PI0 data={idea}/>
        });
        this.p3_PI0 = relproData2.p3_PI0.map(idea=>{
            return <PI0 data={idea}/>
        });
        this.p4_PI0 = relproData2.p4_PI0.map(idea=>{
            return <PI0 data={idea}/>
        });
        this.prototyping = relproData2.prototyping.map(idea=>{
            return <GalleryColLeft data={idea}/>
        });
        this.d0_SI0 = relproData2.d0_SI0.map(ur=>{
            return <SI0 data={ur}/>
        });
        this.d1_SV0 = relproData2.d1_SV0.map(ur=>{
            return <SV0 data={ur}/>
        });
    };

    componentDidMount(){
        //Switch to white scheme
        $('#root').css('background-color', 'white');
        $('#navbar').css('background-color', 'white');

        $(document).ready(function(){
        $('.logo').css('width', '75px');
        $('body').scrollspy({target: ".pofo-page", offset: 50});

        // Add smooth scrolling on all links inside the navbar
        $("#side-nav a").on('click', function(event) {
            // Make sure this.hash has a value before overriding default behavior
            if (this.hash !== "") {
                // Prevent default anchor click behavior
                event.preventDefault();
                // Store hash
                var targetHref = $(this).attr('href');
                // Using jQuery's animate() method to add smooth page scroll
                // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
                $('html, body').animate({
                    scrollTop: $(targetHref).offset().top
                }, 900);
            }  // End if
        });

        $(window).scroll(function() {
            var scrollDistance = $(window).scrollTop();
            // Assign active class to nav links while scolling
            $('.page-section').each(function(m) {
                if ($(this).position().top-200 <= scrollDistance) {
                    $('.side-nav div a.active').removeClass('active');
                    $('.side-nav div a').eq(m).addClass('active');
                }
                else{
                    $('.subgroup').each(function(n) {
                        $('nav ul li a.active').removeClass('active');
                    });
                }
            });

            $('.subgroup').each(function(n) {
                if ($(this).position().top-200 <= scrollDistance) {
                    $('nav ul li a.active').removeClass('active');
                    $('nav ul li a').eq(n).addClass('active');
                }
            });


        }).scroll();

    });}

    render() {
        window.scrollTo(0, 0);


        if(window.innerWidth < 600){
            return(
                <div className="pofo-page mobile" >
                    <div style={{width:"90vw", marginLeft:"5vw", float:"left", display:"inline"}}>
                        {/*position: "relative", float:"left",*/}
                        <Overview data={relproData2}/>
                        <div>
                            <h2 className="page-section" id="do">DESIGN OUTCOMES</h2>
                            <div>{this.d1_SV0}</div>
                            <div>{this.d0_SI0}</div>
                        </div>
                        <div>
                            <h2 className="page-section" id="ur">USER RESEARCH</h2>
                            <div><h4 id="r0" className="subgroup">PROFILE EXISTING USERS</h4>
                                {this.r0_PI0}
                            </div>
                            <div><h4 id="r1" className="subgroup">ANALYZE HOW CURRENT FEATURES APPEAL TO USERS</h4>
                                {this.r1_PI0}
                            </div>
                        </div>

                        <div>
                            <h2 className="page-section" id="dp">DESIGN PROCESS</h2>
                            <div id="p0" className="subgroup">
                                {this.p0_PBI0}
                            </div>
                            <div id="p1" className="subgroup">
                                {this.p1_PBI0}
                            </div>
                            <div>
                                <h4 id="p2" className="subgroup">SURVEY ON MAP-BASED WEB APPS</h4>
                                {this.p2_PI0}
                            </div>
                            <div>
                                <h4 id="p3" className="subgroup">SURVEY ON MAP APIs</h4>
                                {this.p3_PI0}
                            </div>
                            <div >
                                <h4 id="p4" className="subgroup">UI FOR MAP-SEARCH</h4>
                                {this.p4_PI0}
                            </div>
                        </div>
                        <div>
                            <h2 className="page-section" id="gallery-col-left">PROTOTYPING</h2>
                            <div id="sub5-page-section" className="subgroup">{this.prototyping}</div>
                        </div>
                    </div>
                </div>
            );
        }

        return(
            <div className="pofo-page" >
                <div style={{width:"18vw", marginLeft: "0vw", float:"left"}}>
                    <nav className="side-nav" id="side-nav" style={{position: "fixed", display:"inline-block"}}>
                        <div><a href="#overview">OVERVIEW</a></div>
                        <div><a href="#do">DESIGN OUTCOMES</a></div>
                        <div><a href="#ur">USER RESEARCH</a></div>
                        <ul>
                            <li><a href="#r0">Profile Existing Users</a></li>
                            <li><a href="#r1">Analyze Current Features</a></li>
                        </ul>
                        <div><a href="#dp">DESIGN PROCESS</a></div>
                        <ul>
                            {/*<li><a href="#p0">Analyze User Flow</a></li>*/}
                            {/*<li><a href="#p1">Map Design Challenges</a></li>*/}
                            <li><a href="#p2">Survey on Map Apps</a></li>
                            <li><a href="#p3">Survey on Map APIs</a></li>
                            <li><a href="#p4">UI for Map-Search</a></li>
                        </ul>
                        <div><a href="#gallery-col-left">PROTOTYPING</a></div>
                    </nav>
                </div>
                <div style={{width:"80vw", marginLeft:"18vw", float:"left", display:"inline"}}>
                    {/*position: "relative", float:"left",*/}
                    <Overview data={relproData2}/>
                    <div>
                        <h2 className="page-section" id="do" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>DESIGN OUTCOMES</h2>
                        <div style={{marginBottom:"60vh",paddingBottom:"3vw"}}>{this.d1_SV0}</div>
                        <div >{this.d0_SI0}</div>
                    </div>
                    <div>
                        <h2 className="page-section" id="ur" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>USER RESEARCH</h2>
                        <div><h4 id="r0" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"1vw"}}>PROFILE EXISTING USERS</h4>
                            {this.r0_PI0}
                        </div>
                        <div><h4 id="r1" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"3vw"}}>ANALYZE HOW CURRENT FEATURES APPEAL TO USERS</h4>
                            {this.r1_PI0}
                        </div>
                    </div>

                    <div>
                        <h2 className="page-section" id="dp" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>DESIGN PROCESS</h2>
                        {/*<div id="p0" className="subgroup">*/}
                        {/*    {this.p0_PBI0}*/}
                        {/*</div>*/}
                        {/*<div id="p1" className="subgroup">*/}
                        {/*    {this.p1_PBI0}*/}
                        {/*</div>*/}
                        <div>
                            <h4 id="p2" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"1vw"}}>SURVEY ON MAP-BASED WEB APPS</h4>
                            {this.p2_PI0}
                        </div>
                        <div>
                            <h4 id="p3" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"3vw"}}>SURVEY ON MAP APIs</h4>
                            {this.p3_PI0}
                        </div>
                        <div >
                            <h4 id="p4" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"3vw"}}>UI FOR MAP-SEARCH</h4>
                            {this.p4_PI0}
                        </div>
                    </div>
                    <div>
                        <h2 className="page-section" id="gallery-col-left" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>PROTOTYPING</h2>
                        <div id="sub5-page-section" className="subgroup">{this.prototyping}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Relpro