import React from 'react';

class MySkills extends React.Component{
    constructor(){
        super();
    }

    render(){
        return(
            <div className="skill-text-row">
                <div className="skill-text-col">
                    <div>
                        <h2>{"What can I do (for you)?"}</h2>
                    </div>
                    <div>
                        <h5>{"Design "}</h5>
                        <p>{"Design Principles, User Research, Wireframing, Prototyping"}</p>
                        <p>{"Visual Design, Sketch, InVision, Adobe Creative Suite"}</p>
                        <p>{"3D Modeling (Rhino, Solidworks), Digital Farbrication"}</p>
                    </div>
                    <div>
                        <h5>{"Technical "}</h5>
                        <p>{"JavaScript (React, Three.js, vanilla JS)"}</p>
                        <p>{"HTML, CSS"}</p>
                        <p>{"C++, OpenGL/GLSL, Python, Java"}</p>
                        <div className="skill-text-italic">
                            <i>{"* While working as a designer, I've also been pursuing my CS Master's degree part-time at NYU Courant, with a focus on "}</i>
                            <i>{"Computer Graphics, Geometric Modeling, and Machine Learning."}</i><br/><i>{"I have recently finished my CS Master's degree in May 2023."}</i>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default MySkills