import React from 'react';

class PBI0 extends React.Component{
    constructor(){
        super();
        this.state = {
        };
    }
    render(){
        return(
            <div>
                {/*<h4>{this.props.data.title}</h4>*/}
                <div className="pofo-text-row">
                    <div className="pofo-text-col" style={{width:"80vw",paddingLeft:"14vw",paddingRight:"15vw"}}>
                        <h4 style={this.props.data.title==="" ? { display: 'none' }:{marginBottom:"2vh"}}>{this.props.data.title}</h4>
                        <p style={this.props.data.description==="" ? { display: 'none' }:{paddingBottom:"1vw",width:"46vw"}}>
                            <div>{this.props.data.description}</div>
                        </p>
                        <div className="pofo-text-col-col" style={{width:"46vw"}}>
                            <b>{this.props.data.title0}</b>{this.props.data.bullet0}
                        </div>
                        <div className="pofo-text-col-col" style={{width:"46vw"}}>
                            <b>{this.props.data.title1}</b>{this.props.data.bullet1}
                        </div>
                        <div className="pofo-text-col-col" style={{width:"46vw"}}>
                            <b>{this.props.data.title2}</b>{this.props.data.bullet2}
                        </div>
                    </div>
                    <div className="pofo-img-col" style={{width:"80vw",paddingLeft:"14vw",paddingRight:"15vw", marginBottom:"0px"}}>
                        <img src={this.props.data.imgUrl} style={this.props.data.imgUrl==="" ? { display: 'none' }:{width:"46vw",marginLeft:"0",marginBottom:"0"}}/>
                    </div>
                </div>
            </div>
        )
    }

    // render(){
    //     return(
    //         <div>
    //             {/*<h4>{this.props.data.title}</h4>*/}
    //             <div className="pofo-text-row">
    //                 <div className="pofo-text-col" style={{width:"40vw"}}>
    //                     <h4 style={{marginBottom:"2vh"}}>{this.props.data.title}</h4>
    //                     <div style={this.props.data.description==="" ? { display: 'none' }:{paddingBottom:"1vw"}}>
    //                         {this.props.data.description}
    //                     </div>
    //                     <div className="pofo-text-col-col">
    //                         <b>{this.props.data.title0}</b>{this.props.data.bullet0}
    //                     </div>
    //                     <div className="pofo-text-col-col" >
    //                         <b>{this.props.data.title1}</b>{this.props.data.bullet1}
    //                     </div>
    //                     <div className="pofo-text-col-col" >
    //                         <b>{this.props.data.title2}</b>{this.props.data.bullet2}
    //                     </div>
    //                 </div>
    //                 <div className="pofo-img-col">
    //                     <img src={this.props.data.imgUrl} style={{width:"40vw", float:"left",paddingTop:"2vh",marginTop:"2vh"}}/>
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }
}

export default PBI0