import React from 'react';
import ReactDOM from 'react-dom';
import Overview from './pofoComponents/Overview'
import PI from './pofoComponents/PI'
import PI0 from './pofoComponents/PI0'
import PI2 from './pofoComponents/PI2'
import PBIn from './pofoComponents/PBIn'
import SI0 from './pofoComponents/SI0'
import GalleryColLeft from './pofoComponents/GalleryColLeft'
import DesignOutcome from './pofoComponents/DesignOutcome'
import mkctData from "../data/mkctData.js"
import "../css/pofo.css"
import "../css/sideNav.css"
import $ from 'jquery';

class Makecents extends React.Component {

    constructor(){
        super();
        //pass data of this project
        this.state = {
            hasResearch: false,
            hasPrototype:false
        };
        this.o0_PBIn = mkctData.o0_PBIn.map(dt=>{
            return <PBIn data={dt}/>
        });
        this.o1_PI0 = mkctData.o1_PI0.map(dt=>{
            return <PI0 data={dt}/>
        });
        this.br0_PI2 = mkctData.br0_PI2.map(dt=>{
            return <PI2 data={dt}/>
        });
        this.bd0_PI2 = mkctData.bd0_PI2.map(dt=>{
            return <PI2 data={dt}/>
        });
        this.wr0_PI2 = mkctData.wr0_PI2.map(dt=>{
            return <PI2 data={dt}/>
        });
        this.wd0_PI2 = mkctData.wd0_PI2.map(dt=>{
            return <PI2 data={dt}/>
        });
        this.mo_PI2 = mkctData.mo_PI2.map(dt=>{
            return <PI2 data={dt}/>
        });
        this.mo_PBIn = mkctData.mo_PBIn.map(dt=>{
            return <PBIn data={dt}/>
        });
        this.mr_PI = mkctData.mr_PI.map(dt=>{
            return <PI data={dt}/>
        });
        this.mp_PI = mkctData.mp_PI.map(dt=>{
            return <PI data={dt}/>
        });
        this.mmo_PI2 = mkctData.mmo_PI2.map(dt=>{
            return <PI2 data={dt}/>
        });
        this.mmr_PI = mkctData.mmr_PI.map(dt=>{
            return <PI data={dt}/>
        });
        this.mmp_PI = mkctData.mmp_PI.map(dt=>{
            return <PI data={dt}/>
        });
    };

    componentDidMount(){
        // nav -> content
        $(document).ready(function(){
            //Switch to white scheme
            $('#root').css('background-color', 'white');
            $('#navbar').css('background-color', 'white');

            $('body').scrollspy({target: ".pofo-page", offset: 50});

            // Add smooth scrolling on all links inside the navbar
            $("#side-nav a").on('click', function(event) {
                // Make sure this.hash has a value before overriding default behavior
                if (this.hash !== "") {
                    // Prevent default anchor click behavior
                    event.preventDefault();
                    // Store hash
                    var targetHref = $(this).attr('href');
                    // Using jQuery's animate() method to add smooth page scroll
                    // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
                    $('html, body').animate({
                        scrollTop: $(targetHref).offset().top
                    }, 900);
                }  // End if
            });

            $(window).scroll(function() {
                var scrollDistance = $(window).scrollTop();
                // Assign active class to nav links while scolling
                $('.page-section').each(function(m) {
                    if ($(this).position().top-200 <= scrollDistance) {
                        $('.side-nav div a.active').removeClass('active');
                        $('.side-nav div a').eq(m).addClass('active');
                    }
                    else{
                        $('.subgroup').each(function(n) {
                            $('nav ul li a.active').removeClass('active');
                        });
                    }
                });

                $('.subgroup').each(function(n) {
                    if ($(this).position().top-200 <= scrollDistance) {
                        $('nav ul li a.active').removeClass('active');
                        $('nav ul li a').eq(n).addClass('active');
                    }
                });


            }).scroll();

        });}

    render() {
        const myLogo="Relpro";
        const uiux="UI/UX Design";
        const ctech="Creative Technology";
        const about="About";
        window.scrollTo(0, 0);

        if(window.innerWidth < 600){
            return(
                <div className="pofo-page mobile" >
                    <div style={{width:"90vw", marginLeft:"5vw", float:"left", display:"inline"}}>
                        <Overview data={mkctData}/>
                        <div>
                            <h2 className="page-section" id="bd">BRANDING DESIGN</h2>
                            <div><h4 id="bo" className="subgroup">DESIGN OUTCOMES</h4>
                                {this.o1_PI0}
                            </div>
                            <div><h4 id="br" className="subgroup">RESEARCH PROCESS</h4>
                                {this.br0_PI2}
                            </div>
                            <div><h4 id="bp" className="subgroup">DESIGN PROCESS</h4>
                                {this.bd0_PI2}
                            </div>
                        </div>
                        <div>
                            <h2 className="page-section" id="wd">WEBSITE DESIGN</h2>
                            <div><h4 id="wo" className="subgroup">DESIGN OUTCOMES</h4>
                                {this.o0_PBIn}
                            </div>
                            <div><h4 id="wr" className="subgroup">RESEARCH PROCESS</h4>
                                {this.wr0_PI2}
                            </div>
                            <div><h4 id="wp" className="subgroup">DESIGN PROCESS</h4>
                                {this.wd0_PI2}
                            </div>
                        </div>
                        <div>
                            <h2 className="page-section" id="md">MOBILE DESIGN - CONSUMER APP</h2>
                            <div><h4 id="mo" className="subgroup">DESIGN OUTCOMES</h4>
                                {this.mo_PI2}
                                {this.mo_PBIn}
                            </div>
                            <div><h4 id="mr" className="subgroup">RESEARCH PROCESS</h4>
                                {this.mr_PI}
                            </div>
                            <div><h4 id="mp" className="subgroup">DESIGN PROCESS</h4>
                                {this.mp_PI}
                            </div>
                        </div>
                        <div>
                            <h2 className="page-section" id="mmd">MOBILE DESIGN - MERCHANT APP</h2>
                            <div><h4 id="mmo" className="subgroup">DESIGN OUTCOMES</h4>
                                {this.mmo_PI2}
                            </div>
                            <div><h4 id="mmr" className="subgroup">RESEARCH PROCESS</h4>
                                {this.mmr_PI}
                            </div>
                            <div><h4 id="mmp" className="subgroup">DESIGN PROCESS</h4>
                                {this.mmp_PI}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return(
            <div className="pofo-page" >
                <div style={{width:"18vw", marginLeft: "0vw", float:"left"}}>
                    <nav className="side-nav" id="side-nav" style={{position: "fixed", display:"inline-block"}}>
                        <div><a href="#overview">OVERVIEW</a></div>
                        <div><a href="#bd">BRANDING DESIGN</a></div>
                        <ul>
                            <li><a href="#bo">Design Outcomes</a></li>
                            <li><a href="#br">Research Process</a></li>
                            <li><a href="#bp">Design Process</a></li>
                        </ul>
                        <div><a href="#wd">WEBSITE DESIGN</a></div>
                        <ul>
                            <li><a href="#wo">Pre-ICO Website</a></li>
                            <li><a href="#wr">Research Process</a></li>
                            <li><a href="#wp">Design Process</a></li>
                        </ul>
                        <div><a href="#md">MOBILE - CONSUMER APP</a></div>
                        <ul>
                            <li><a href="#mo">Design Outcome</a></li>
                            <li><a href="#mr">Research Process</a></li>
                            <li><a href="#mp">Design Process</a></li>
                        </ul>
                        <div><a href="#mmd">MOBILE - MERCHANT APP</a></div>
                        <ul>
                            <li><a href="#mmo">Design Outcome</a></li>
                            <li><a href="#mmr">Research Process</a></li>
                            <li><a href="#mmp">Design Process</a></li>
                        </ul>
                    </nav>
                </div>
                <div style={{width:"80vw", marginLeft:"18vw", float:"left", display:"inline"}}>
                    <Overview data={mkctData}/>
                    <div>
                        <h2 className="page-section" id="bd" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>BRANDING DESIGN</h2>
                        <div><h4 id="bo" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"1vw"}}>DESIGN OUTCOMES</h4>
                            {this.o1_PI0}
                        </div>
                        <div><h4 id="br" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"3vw"}}>RESEARCH PROCESS</h4>
                            {this.br0_PI2}
                        </div>
                        <div><h4 id="bp" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"3vw"}}>DESIGN PROCESS</h4>
                            {this.bd0_PI2}
                        </div>
                    </div>
                    <div>
                        <h2 className="page-section" id="wd" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>WEBSITE DESIGN</h2>
                        <div>
                            {/*<h4 id="wo" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"1vw"}}>DESIGN OUTCOMES</h4>*/}
                            {this.o0_PBIn}
                        </div>
                        <div><h4 id="wr" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"3vw"}}>RESEARCH PROCESS</h4>
                            {this.wr0_PI2}
                        </div>
                        <div><h4 id="wp" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"3vw"}}>DESIGN PROCESS</h4>
                            {this.wd0_PI2}
                        </div>
                    </div>
                    <div>
                        <h2 className="page-section" id="md" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>MOBILE DESIGN - CONSUMER APP</h2>
                        <div><h4 id="mo" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"1vw"}}>DESIGN OUTCOMES</h4>
                            {this.mo_PI2}
                            {this.mo_PBIn}
                        </div>
                        <div><h4 id="mr" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"3vw"}}>RESEARCH PROCESS</h4>
                            {this.mr_PI}
                        </div>
                        <div><h4 id="mp" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"3vw"}}>DESIGN PROCESS</h4>
                            {this.mp_PI}
                        </div>
                    </div>
                    <div>
                        <h2 className="page-section" id="mmd" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>MOBILE DESIGN - MERCHANT APP</h2>
                        <div><h4 id="mmo" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"1vw"}}>DESIGN OUTCOMES</h4>
                            {this.mmo_PI2}
                        </div>
                        <div><h4 id="mmr" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"3vw"}}>RESEARCH PROCESS</h4>
                            {this.mmr_PI}
                        </div>
                        <div><h4 id="mmp" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"3vw"}}>DESIGN PROCESS</h4>
                            {this.mmp_PI}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Makecents