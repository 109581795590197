import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import './css/index.css';
import './css/header.css';
import './utils/header-interaction'
import './utils/toggle-img'

// import * as serviceWorker from './serviceWorker';
import Header from './Header'
import Footer from './components/Footer';
import { BrowserRouter } from 'react-router-dom';
import {Route, Link} from 'react-router-dom';


ReactDOM.render(
    <div>
        <Header />
        {/*<MainApp />*/}
        <Footer />
    </div>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();