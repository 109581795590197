import React from 'react';
import ReactPlayer from 'react-player'


class PVV0 extends React.Component{
    constructor(){
        super();
        this.state = {

        };
    }

    render(){
        return(
            <div>
                {/*<h4>{this.props.data.title}</h4>*/}
                <div className="pofo-text-row">
                    <div className="pofo-text-col">
                        <div style={this.props.data.descriptionL==="" ? { display: 'none' }:{paddingBottom:".5vw"}}>
                            {this.props.data.descriptionL}
                        </div>
                    </div>
                    <div className="pofo-text-col">
                        <div style={this.props.data.descriptionR==="" ? { display: 'none' }:{paddingBottom:".5vw"}}>
                            {this.props.data.descriptionR}
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <ReactPlayer url={this.props.data.vUrl1}  width={"35vw"} height={"20vw"} style={{float:"left",padding:"0",marginRight:"5.5vw"}}/>
                    </div>
                    <div>
                        <ReactPlayer url={this.props.data.vUrl2}  width={"35vw"} height={"20vw"} style={{float:"left",padding:"0",position:"relative",marginBottom:"4vh"}}  />
                    </div>
                </div>
            </div>
        )
    }
}

export default PVV0