import React from 'react';
import IntroParagraph from "./IntroParagraph"
import aboutData from "../../data/aboutData.js"

class Intro1 extends React.Component{
    constructor(){
        super();
        this.intro= aboutData.intro_paragraphs.map(dt=>{
            return <IntroParagraph data={dt}/>
        });
    }

    render(){
        return(
            <div className="intro-text-row">
                <div className="intro-text-col">
                        {this.intro}
                    <a href={aboutData.cv_link.pdfUrl} style={{color:'#4ea6e6'}} without rel='noreferrer' target='_blank'>Yueping's CV</a>
                </div>

                <div className="intro-img">
                    <img src={aboutData.intro_img.imgUrl}/>
                </div>
            </div>
        )
    }
}

export default Intro1