
const ccData = {
    id:1,
    overview:{
        coverImgUrl:"/assets/cc/cover-image-1.png",
        descriptionBold:"I lead the design and prototyping for the view control experience of nTopology's 3D modeling interface.",
        descriptionRegular:"The new view control interface is equipped with mode-specific cursor icons, and allows user to map hardware input to match another CAD software that their muscle memory is already familiar with.",
        timelineImgUrl:"/assets/cc/project-timeline.jpg",

        role:"Lead Product Designer, Lead 3D UX Prototyper",
        theTeam:"Engineering Team, Product Manager, Myself",
        tools:"Figma, Adobe Illustrator, Unity, Qt, pen & paper, Jira",
        duration:"Q2 2022"
    },
    b0_PI0:[
        {
            descriptionL:"Controlling the camera view of 3D models is more complicated than navigating a 2D Canvas.",
            descriptionR:"For example, in 2D UI, the cursor position maps deterministically to the x and y coordinates of a 2D pixel grid. Similarly, translation and rotation are also based on x and y positions which can be easily coordinated with respect to cursor position and movements. But in a 3D scene, the x and y positions of the cursor referenced from a 2D screen is insufficient to determine which point in the 3D space the user is intending to point at, orbit around, or zoom around.",
            imgUrl:"/assets/cc/background-1.jpg"
        }
    ],
    b1_PV0: [{
        title:"",
        description:"The user experience with Camera Controls (pan, zoom, roll, orbit) in nTopology was considered unintuitive for mainly two reasons: ",
        vUrl:"https://player.vimeo.com/video/814060756?title=0&byline=0&portrait=0&transparent=0&autoplay=1&loop=1",
        title0:"① Muscle Memory and Familiarity with Existing CAD Interfaces: ",
        bullet0:"CAD users who uses nTop are used to the mouse and keyboard gestures in the other software they use.",
        title1:"② Lack of Visual Cues to Indicate Current Control State: ",
        bullet1:"The cursor icon always stays the same no matter what view control mode user is in.",
        title2:"",
        bullet2:""
    }],
    ov_SV0:[
        {
            title:"Demo: New Mode-Specific Cursor UI & Camera Control Mapping",
            vUrl:"https://player.vimeo.com/video/814170225?title=0&byline=0&portrait=0&transparent=0&autoplay=1&loop=1"
        }
    ],
    o0_PI2:[
        {   title:"",
            descriptionL:"The first objective of this project is to study how camera viewed are controlled in the most commonly used CAD software that nTopology users spend time with.",
            descriptionR:"The second objective of the project is to indicate to user what modes they are in, by designing a set of cursor icons to correspond to the control modes that the user is currently in.",
            imgUrl:"/assets/cc/outcome-1.jpg",
        },
    ],
    o1_PI2:[
        {   title:"",
            descriptionL:"I aggregated all the non-confidential nTop files (by reaching out to the presales teams), then selected and organized the files to create a structured set of test cases that both designers and engineers can use to valid the usability of our prototypes.",
            descriptionR:"While this test set might seem small, it is the first systematically organized set of cases that allows nTop's R&D team to quickly validate usability across the 6 main industries where nTop users come from.",
            imgUrl:"/assets/cc/outcome-2.jpg",
        },
    ],
    p0_PI2:[
        {
            title:"Research and Understand nTopology's Default Camera Control I/O",
            descriptionL:"To indicate to user which camera control mode (zoom, pan, orbit, etc.) they are in, I designed a set of icons that correspond to each mode and accentuate cursor position relative to the 3D scene.",
            descriptionR:"However because the type of geometries in nTop is much more complex than traditional CAD, the requirements for effective visual perception of icons in the 3D scene is very high. Therefore, this set of icons are amongst the most challenging (and interesting) icons I’ve ever designed.",
            imgUrl:"/assets/cc/process-1.jpg"
        },
        {
            title:"Compare with Other CAD Software’s Camera Control I/O",
            descriptionL:"I surveyed which other CAD software are frequently used alongside nTopology, and had shadowing sessions with internal users (application engineers) to learn about their setup and observe how they control 3D views while designing a component. ",
            descriptionR: "I then analyzed the I/O (key press, scroll wheel directions, orbit and zoom centers) of these CAD software according to the same metrics as in the chart.",
            imgUrl:"/assets/cc/process-2.jpg"
        },
    ],
    p1_PI2:[
        {
            title:"",
            descriptionL:"The types of geometry designed in nTopology are often more complicated and intricate than the 3D models made in traditional CAD. This means that there is a dense amount of variations in brightness and hues in the 3D scene, making it hard to discern the cursor.",
            descriptionR:"Therefore, to design icons that can be easily spotted and tracked by the human eye in this context is challenging. I looked into books and papers on human visual system to discover what are the effective visual cues I can incorporate to design the cursor icons.",
            imgUrl:"/assets/cc/process-3.jpg"
        },
    ],
    pt0_PI2:[
        {
            title:"",
            descriptionL:"After exploring design options in 2D and placing the icons against a variety of screenshots from nTopology, I noticed that the color contrast and readability of these icons varies quite a bit depending on the light reflections of the 3D models.",
            descriptionR:"So instead of testing the icons against static screenshots of the 3D scene, I created a 3D environment in Unity (using a similar three-point lighting and dark/light backgrounds as the original app), imported 3D models of different geometric details, and imported cursor icons as 2D sprites in Unity to test the look & feel in action.",
            imgUrl:"/assets/cc/prototyping-1.jpg"
        },
    ],
    pt1_PVV0:[
        {
            descriptionL:"Blue-Tone Icons (Light Mode)",
            descriptionR:"Blue-Tone Icons (Dark Mode)",
            vUrl1:"https://player.vimeo.com/video/814138126?title=0&byline=0&portrait=0&transparent=0&autoplay=1&loop=1",
            vUrl2:"https://player.vimeo.com/video/814138349?title=0&byline=0&portrait=0&transparent=0&autoplay=1&loop=1",
        },
        {
            descriptionL:"Teal-Tone Icons (Light Mode)",
            descriptionR:"Teal-Tone Icons (Dark Mode)",
            vUrl1:"https://player.vimeo.com/video/814139025?title=0&byline=0&portrait=0&transparent=0&autoplay=0&loop=1",
            vUrl2:"https://player.vimeo.com/video/814138923?title=0&byline=0&portrait=0&transparent=0&autoplay=0&loop=1",
        },
        {
            descriptionL:"Black & White Icons (Light Mode)",
            descriptionR:"Black & White Icons (Dark Mode)",
            vUrl1:"https://player.vimeo.com/video/814138555?title=0&byline=0&portrait=0&transparent=0&autoplay=0&loop=1",
            vUrl2:"https://player.vimeo.com/video/814138802?title=0&byline=0&portrait=0&transparent=0&autoplay=0&loop=1",
        },
    ]
}

export default ccData