import React from 'react';
import ReactDOM from 'react-dom';
import Overview from './pofoComponents/Overview'
import PI from './pofoComponents/PI'
import SV0 from './pofoComponents/SV0'
import PI2 from './pofoComponents/PI2'
import PBI2 from './pofoComponents/PBI2'
import SI0 from './pofoComponents/SI0'
import vrData from "../data/vrData.js"
import "../css/pofo.css"
import "../css/sideNav.css"
import $ from 'jquery';

class VR extends React.Component {

    constructor(){
        super();
        //pass data of this project
        this.state = {
            hasResearch: false,
            hasPrototype:false
        };
        this.pb_PI = vrData.pb_PI.map(dt=>{
            return <PI data={dt}/>
        });
        this.ss_PI = vrData.ss_PI.map(dt=>{
            return <PI data={dt}/>
        });
        this.pn_PI = vrData.pn_PI.map(dt=>{
            return <PI data={dt}/>
        });
        this.ov_SV0 = vrData.ov_SV0.map(dt=>{
            return <SV0 data={dt}/>
        });
    };

    componentDidMount(){
        // nav -> content
        $(document).ready(function(){
            //Switch to white scheme
            $('#root').css('background-color', 'white');
            $('#navbar').css('background-color', 'white');

            $('body').scrollspy({target: ".pofo-page", offset: 50});

            // Add smooth scrolling on all links inside the navbar
            $("#side-nav a").on('click', function(event) {
                // Make sure this.hash has a value before overriding default behavior
                if (this.hash !== "") {
                    // Prevent default anchor click behavior
                    event.preventDefault();
                    // Store hash
                    var targetHref = $(this).attr('href');
                    // Using jQuery's animate() method to add smooth page scroll
                    // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
                    $('html, body').animate({
                        scrollTop: $(targetHref).offset().top
                    }, 900);
                }  // End if
            });

            $(window).scroll(function() {
                var scrollDistance = $(window).scrollTop();
                // Assign active class to nav links while scolling
                $('.page-section').each(function(m) {
                    if ($(this).position().top-200 <= scrollDistance) {
                        $('.side-nav div a.active').removeClass('active');
                        $('.side-nav div a').eq(m).addClass('active');
                    }
                    else{
                        $('.subgroup').each(function(n) {
                            $('nav ul li a.active').removeClass('active');
                        });
                    }
                });


            }).scroll();

        });}

    render() {
        window.scrollTo(0, 0);

        if(window.innerWidth < 600){
            return(
                <div className="pofo-page mobile" >
                    <div style={{width:"90vw", marginLeft:"5vw", float:"left", display:"inline"}}>
                        <Overview data={vrData}/>
                        <div>
                            <h2 className="page-section" id="pb">PROJECT BACKGROUND</h2>
                            <div>
                                {this.pb_PI}
                            </div>
                        </div>
                        <div>
                            <h2 className="page-section" id="ss">SCENE SETUP</h2>
                            <div>
                                {this.ss_PI}
                            </div>
                        </div>
                        <div>
                            <h2 className="page-section" id="pn">PLAYER NAVIGATION</h2>
                            <div>
                                {this.pn_PI}
                            </div>
                        </div>
                        <div>
                            <h2 className="page-section" id="dr">DEMO RECORDING</h2>
                            {this.ov_SV0}
                        </div>
                    </div>
                </div>
            )
        }
        return(
            <div className="pofo-page" >
                <div style={{width:"18vw", marginLeft: "0vw", float:"left"}}>
                    <nav className="side-nav" id="side-nav" style={{position: "fixed", display:"inline-block"}}>
                        <div><a href="#overview">OVERVIEW</a></div>
                        <div><a href="#pb">PROJECT BACKGROUND</a></div>
                        <div><a href="#ss">SCENE SETUP</a></div>
                        <div><a href="#pn">PLAYER NAVIGATION</a></div>
                        <div><a href="#dr">DEMO RECORDINGS</a></div>
                    </nav>
                </div>
                <div style={{width:"80vw", marginLeft:"18vw", float:"left", display:"inline"}}>
                    <Overview data={vrData}/>
                    <div>
                        <h2 className="page-section" id="pb" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>PROJECT BACKGROUND</h2>
                        <div>
                            {this.pb_PI}
                        </div>
                    </div>
                    <div>
                        <h2 className="page-section" id="ss" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>SCENE SETUP</h2>
                        <div>
                            {this.ss_PI}
                        </div>
                    </div>
                    <div>
                        <h2 className="page-section" id="pn" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>PLAYER NAVIGATION</h2>
                        <div>
                            {this.pn_PI}
                        </div>
                    </div>
                    <div>
                        <h2 className="page-section" id="dr" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>DEMO RECORDING</h2>
                            {this.ov_SV0}
                    </div>
                </div>
            </div>
        )
    }
}

export default VR