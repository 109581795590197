import React from 'react';

class PI2 extends React.Component{
    constructor(){
        super();
        this.state = {

        };
    }

    render(){
        return(
            <div>
                <div className="pofo-text-row">
                    <div className="pofo-text-col" style={{width:"80vw",paddingLeft:"14vw",paddingRight:"15vw"}}>
                        <b style={this.props.data.title==="" ? { display: 'none' }:{fontSize:"18px",lineHeight:"22px"}}>{this.props.data.title}</b>
                        <p style={this.props.data.descriptionL==="" ? { display: 'none' }:{}}>
                            <div style={{paddingTop:"10px"}}>{this.props.data.descriptionL}</div>
                            <div style={this.props.data.descriptionR==="" ? { display: 'none' }:{paddingTop:"14px"}}>{this.props.data.descriptionR}</div>
                        </p>
                        {/*<p style={this.props.data.descriptionL==="" ? { display: 'none' }:{paddingBottom:"10px"}}>*/}
                        {/*    <div style={{paddingBottom:"14px"}}>{this.props.data.descriptionL}</div>*/}
                        {/*    <div>{this.props.data.descriptionR}</div></p>*/}
                    </div>
                </div>
                <img style={this.props.data.imgUrl==="" ? { display: 'none' }:{}} src={this.props.data.imgUrl}/>
            </div>
        )
    }
    // render(){
    //     return(
    //         <div>
    //             <div className="pofo-text-row">
    //                 <div className="pofo-text-col" >
    //                     <b>{this.props.data.title}</b>
    //                     <p><div style={this.props.data.description==="" ? { display: 'none' }:{paddingBottom:"8px"}}>{this.props.data.descriptionL}</div><div>{this.props.data.descriptionR}</div></p>
    //                 </div>
    //                 <div className="pofo-text-col" style={{paddingLeft:"1vw"}}>
    //                 </div>
    //             </div>
    //             <img style={this.props.data.imgUrl==="" ? { display: 'none' }:{}} src={this.props.data.imgUrl}/>
    //         </div>
    //     )
    // }
}

export default PI2