import React from 'react';

class Overview extends React.Component{
    constructor(){
        super();
        // state is an object
        this.state = {
        };
    };

    render(){
        if (window.innerWidth < 600){
            return(
                <div className="page-section" id="overview">
                    {/*<img src ={"/assets/temp.png"} style={{width:"80vw", height:"24vw", float:"left",paddingBottom:"5vw"}}/>*/}
                    <img src={this.props.data.overview.coverImgUrl} style={{paddingTop:"0vw"}}/>
                    <h2 style={{paddingBottom:"32px", paddingTop:"10vh"}}>OVERVIEW</h2>
                    <div className="pofo-text-row">
                        <div className="pofo-text-col" style={{paddingLeft:"0", paddingRight:"5vw"}}>
                            <b>{this.props.data.overview.descriptionBold}</b>
                            <p>{this.props.data.overview.descriptionRegular}</p>
                        </div>
                    </div>
                    <div className="pofo-text-row">
                        <div className="pofo-text-col" style={{paddingLeft:"0", paddingRight:"5vw"}}>
                            <div className="pofo-text-col-col" >
                                <b style={{width: "40vw", marginRight:"1vw", float:"left"}}>My Role: </b>
                                <div style={{width:"40vw", marginRight:"0vw",float:"left", paddingBottom:"5px"}}>{this.props.data.overview.role}</div>
                            </div>
                            <div className="pofo-text-col-col" >
                                <b style={{width: "40vw", marginRight:"1vw", float:"left"}}>The Team: </b>
                                <div style={{width:"40vw", marginRight:"0vw",float:"left", paddingBottom:"5px"}}>{this.props.data.overview.theTeam}</div>
                            </div>
                            <div className="pofo-text-col-col" >
                                <b style={{width: "40vw", marginRight:"1vw", float:"left"}}>Duration: </b>
                                <div style={{width:"40vw", marginRight:"0vw",float:"left", paddingBottom:"5px"}}>{this.props.data.overview.duration}</div>
                            </div>
                            <div className="pofo-text-col-col" >
                                <b style={{width: "40vw", marginRight:"1vw", float:"left"}}>Tools: </b>
                                <div style={{width:"40vw", marginRight:"0vw",float:"left", paddingBottom:"5px"}}>{this.props.data.overview.tools}</div>
                            </div>
                        </div>
                    </div>
                    <h4 style={this.props.data.overview.timelineImgUrl==="" ? { display: 'none' }:{paddingTop:"1vh",paddingBottom:"1vh"}}>PROJECT TIMELINE</h4>
                    <img style={this.props.data.overview.timelineImgUrl==="" ? { display: 'none' }:{}} src={this.props.data.overview.timelineImgUrl}/>
                </div>
            )
        }
        return(
            <div className="page-section" id="overview">
                <img src={this.props.data.overview.coverImgUrl} style={{paddingTop:"0vw"}}/>
                <h2 style={{paddingLeft:"14vw",paddingRight:"15vw",paddingBottom:"3vw", paddingTop:"8vw"}}>OVERVIEW</h2>
                <div className="pofo-text-row" style={{paddingBottom:"0vw",marginBottom:"0vw"}}>
                    <div className="pofo-text-col" style={{width:"80vw",paddingLeft:"14vw",paddingRight:"15vw"}}>
                        <b>{this.props.data.overview.descriptionBold}</b>
                        <p>{this.props.data.overview.descriptionRegular}</p>
                    </div>
                    <div className="pofo-text-col" style={{width:"80vw",paddingLeft:"14vw",paddingRight:"15.5vw",paddingTop:"1.8vw"}}>
                        <div className="pofo-text-col-col" >
                            <b style={{width:"9vw", marginRight:"1vw", marginTop:"0", float:"left"}}>My Role: </b>
                            <div style={{width:"35vw", marginRight:"0vw",float:"left", paddingBottom:".4vw"}}>{this.props.data.overview.role}</div>
                        </div>
                        <div className="pofo-text-col-col" >
                            <b style={{width:"9vw", marginRight:"1vw",marginTop:"0", float:"left"}}>The Team: </b>
                            <div style={{width:"35vw", marginRight:"0vw",float:"left",paddingBottom:".4vw"}}>{this.props.data.overview.theTeam}</div>
                        </div>
                        <div className="pofo-text-col-col" >
                            <b style={{width:"9vw", marginRight:"1vw", marginTop:"0", float:"left"}}>Duration: </b>
                            <div style={{width:"35vw", marginRight:"0vw",float:"left",paddingBottom:".4vw"}}>{this.props.data.overview.duration}</div>
                        </div>
                        <div className="pofo-text-col-col" >
                            <b style={{width:"9vw", marginRight:"1vw", marginTop:"0", float:"left"}}>Tools: </b>
                            <div style={{width:"35vw", marginRight:"0vw",float:"left",paddingBottom:"0vw"}}>{this.props.data.overview.tools}</div>
                        </div>
                    </div>
                </div>
                {/*<h4 style={this.props.data.overview.timelineImgUrl==="" ? { display: 'none' }:{width:"80vw",paddingLeft:"17vw",paddingRight:"17vw",paddingTop:"1vw",paddingBottom:"1vw"}}>PROJECT TIMELINE</h4>*/}
                {/*<img style={this.props.data.overview.timelineImgUrl==="" ? { display: 'none' }:{paddingTop:"1vw"}} src={this.props.data.overview.timelineImgUrl}/>*/}
            </div>
        )
        // return(
        //     <div className="page-section" id="overview">
        //         {/*<img src ={"/assets/temp.png"} style={{width:"80vw", height:"24vw", float:"left",paddingBottom:"5vw"}}/>*/}
        //         <img src={this.props.data.overview.coverImgUrl} style={{paddingTop:"0vw"}}/>
        //         <h2 style={{paddingBottom:"32px", paddingTop:"10vh"}}>OVERVIEW</h2>
        //         <div className="pofo-text-row">
        //             <div className="pofo-text-col">
        //                 <b>{this.props.data.overview.descriptionBold}</b>
        //                 <p>{this.props.data.overview.descriptionRegular}</p>
        //             </div>
        //             <div className="pofo-text-col">
        //                 <div className="pofo-text-col-col" >
        //                     <b style={{width:"6vw", marginRight:"1vw",float:"left"}}>My Role: </b>
        //                     <div style={{width:"28vw", marginRight:"0vw",float:"left"}}>{this.props.data.overview.role}</div>
        //                 </div>
        //                 <div className="pofo-text-col-col" >
        //                     <b style={{width:"6vw", marginRight:"1vw",float:"left"}}>The Team: </b>
        //                     <div style={{width:"28vw", marginRight:"0vw",float:"left"}}>{this.props.data.overview.theTeam}</div>
        //                 </div>
        //                 <div className="pofo-text-col-col" >
        //                     <b style={{width:"6vw", marginRight:"1vw",float:"left"}}>Duration: </b>
        //                     <div style={{width:"28vw", marginRight:"0vw",float:"left"}}>{this.props.data.overview.duration}</div>
        //                 </div>
        //                 <div className="pofo-text-col-col" >
        //                     <b style={{width:"6vw", marginRight:"1vw",float:"left"}}>Tools: </b>
        //                     <div style={{width:"28vw", marginRight:"0vw",float:"left"}}>{this.props.data.overview.tools}</div>
        //                 </div>
        //             </div>
        //         </div>
        //         <h4 style={this.props.data.overview.timelineImgUrl==="" ? { display: 'none' }:{paddingTop:"1vh",paddingBottom:"1vh"}}>PROJECT TIMELINE</h4>
        //         <img style={this.props.data.overview.timelineImgUrl==="" ? { display: 'none' }:{}} src={this.props.data.overview.timelineImgUrl}/>
        //     </div>
        // )
    }
}

export default Overview
