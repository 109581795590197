import React from 'react';
import ReactPlayer from "react-player";

class PV0 extends React.Component {
    constructor(){
        super();
    }

    render(){
        return(
            <div>
                <div className="pofo-text-row">
                    <div className="pofo-text-col" style={{width:"80vw",paddingLeft:"14vw",paddingRight:"15vw"}}>
                        <h4 style={this.props.data.title==="" ? { display: 'none' }:{}}>{this.props.data.title}</h4>
                        <div style={this.props.data.description==="" ? { display: 'none' }:{paddingBottom:"1vw",width:"46vw"}}>
                            <b>{this.props.data.description}</b>
                        </div>
                        <div className="pofo-text-col-col" style={this.props.data.title0==="" ? { display: 'none' }:{width:"46vw", paddingBottom:"1vw"}}>
                            <b>{this.props.data.title0}</b>{this.props.data.bullet0}
                        </div>
                        <div className="pofo-text-col-col" style={this.props.data.title1==="" ? { display: 'none' }:{width:"46vw", paddingBottom:"1vw"}}>
                            <b>{this.props.data.title1}</b>{this.props.data.bullet1}
                        </div>
                        <div className="pofo-text-col-col" style={this.props.data.title2==="" ? { display: 'none' }:{width:"46vw", paddingBottom:"1vw"}}>
                            <b>{this.props.data.title2}</b>{this.props.data.bullet2}
                        </div>
                        <div className="pofo-text-col-col" style={this.props.data.title3==="" ? { display: 'none' }:{width:"46vw", paddingBottom:"1vw"}}>
                            <b>{this.props.data.title3}</b>{this.props.data.bullet3}
                        </div>
                    </div>
                    {/*<div className="pofo-img-col" style={{width:"48vw", overflow:"auto", paddingTop:"12vh",flex:"none",position:"relative"}}>*/}
                    <div style={{width:"80vw",paddingLeft:"14vw",paddingRight:"15vw"}} >
                        <ReactPlayer url={this.props.data.vUrl} height={"28vw"} width={"49vw"}style={{marginRight:"0vw",position:"relative"}} playing loop/>
                    </div>
                </div>
            </div>
        )
    }

    // render(){
    //     return(
    //         <div>
    //             <div className="pofo-text-row">
    //                 <div className="pofo-text-col-sticky" style={{width:"26vw"}}>
    //                     <h4>{this.props.data.title}</h4>
    //                     <div style={this.props.data.description==="" ? { display: 'none' }:{paddingBottom:"1vw",width:"30vw"}}>
    //                         <b>{this.props.data.description}</b>
    //                     </div>
    //                     <div className="pofo-text-col-col" style={{width:"28vw"}}>
    //                         <b>{this.props.data.title0}</b>{this.props.data.bullet0}
    //                     </div>
    //                     <div className="pofo-text-col-col" style={{width:"28vw"}}>
    //                         <b>{this.props.data.title1}</b>{this.props.data.bullet1}
    //                     </div>
    //                     <div className="pofo-text-col-col" style={{width:"28vw"}}>
    //                         <b>{this.props.data.title2}</b>{this.props.data.bullet2}
    //                     </div>
    //                     <div className="pofo-text-col-col" style={{width:"28vw"}}>
    //                         <b>{this.props.data.title3}</b>{this.props.data.bullet3}
    //                     </div>
    //                 </div>
    //                 {/*<div className="pofo-img-col" style={{width:"48vw", overflow:"auto", paddingTop:"12vh",flex:"none",position:"relative"}}>*/}
    //                 <div>
    //                     <ReactPlayer url={this.props.data.vUrl} width={"40vw"} height={"22.8vw"} style={{marginRight:"4vw",position:"relative"}} playing loop/>
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }
}

export default PV0;