import React from 'react'

import 'bootstrap/dist/js/bootstrap.js';
import $ from 'jquery';

import "../css/fonts.css"
import "../css/MainApp.css"
import "../css/pofo.css"
import "../css/about.css"
import pofoData from "../data/pofoData"
import beetleData from "../data/beetleData"
import "../css/pofo.css";
import ThreeCard from './threejsComponents/ThreeCard';
import ThreeBeetle from './threejsComponents/ThreeBeetle';
import ShaderScene1 from './shaderComponents/shader_scene_1.js';



class PD extends React.Component {

    render() {
        $('#root').css('background-color', '#f8f8f8');
        // window.scrollTo(0, 0);

        this.pofoCards = pofoData.map(pofo => {
            return <ThreeCard key={pofo.id} data={pofo} macos={window.navigator.platform.match(/^Mac/)}/>
        })

        // if (window.innerWidth < 600)
            //return (<div style={{paddingLeft:"4px",marginTop:"60px"}}><h4>* Please view on desktop screen :)!</h4></div>);

        return(
            <div className='todo-list'>
                <div style={{paddingLeft:"0vw", alignItems: "center", textAlign: "left", height:"64vh"}}>
                    <div style={{position:"fixed", top:"0px",zIndex:"0", margin:"0",padding:"0"}}>
                        { window.navigator.platform.match(/^Mac/) ?
                            <ShaderScene1 fs={1}/>:
                            <iframe style={{width: "100vw", height: "105vh", overflow: "hidden", marginTop: "-50px"}} scrolling="no"
                                    frameBorder="0" src="https://editor.p5js.org/yuepingwang/full/S1G3K9XLb"></iframe>
                        }
                    </div>
                    <div style={{paddingLeft:"0vw", alignItems: "left",margin:"20vh 0 20vh 15vw", zIndex:"10"}}>
                        <h6 style={{fontSize: "34px", fontFamily: "MarkPro-Medium", color:"#000000", lineHeight:"2.1",fontWeight: "normal"}}><span style={{backgroundColor:"#f8f8f8"}}>Hi! I’m Yueping :)</span></h6>
                        <h6><span style={{backgroundColor:"#f8f8f8"}}>I’m a Product Designer and Programmer based in NYC.
                        <br/>Thanks for checking out my product design work :) </span></h6>
                    </div>
                </div>
                <div style={{zIndex:"98"}}>
                    {this.pofoCards}
                </div>
            </div>
        )
    };
}

export default PD