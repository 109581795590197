import React from 'react';

class SPI0 extends React.Component{
    constructor(){
        super();
        this.state = {
        };
    }

    render(){
        return(
            <div>
                <h4 style={{width:"46vw", lineHeight:"28px",paddingLeft:"14vw",paddingRight:"15vw"}}>{this.props.data.title}</h4>
                <div className="pofo-text-row">
                    <div className="pofo-text-col" style={{width:"80vw",paddingLeft:"14vw",paddingRight:"15vw"}}>
                        {this.props.data.descriptionL}
                    </div>
                </div>
                <div className="pofo-text-row">
                    <div className="pofo-text-col" style={{width:"80vw",paddingLeft:"14vw",paddingRight:"15vw"}}>
                        {this.props.data.descriptionR}
                    </div>
                </div>
                <img src={this.props.data.imgUrl}/>
            </div>
        )
    }
}

export default SPI0