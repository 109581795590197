import React from 'react'
import 'bootstrap/dist/js/bootstrap.js';
import $ from 'jquery';

import "../css/fonts.css"
import "../css/MainApp.css"
import "../css/pofo.css"
import "../css/about.css"
import "../css/pofo.css";
import ThreeCard4 from './threejsComponents/ThreeCard4.js';


class ThreeTests extends React.Component {
    render() {
        $('#root').css('background-color', '#f8f8f8');

        return(
            <div className='todo-list'>
                <div style={{paddingLeft:"0vw", alignItems: "center", textAlign: "center", position:"absolute",left:"0px",top:"0px"}}>
                    {/*<div style={{position:"absolute", top:"0px",zIndex:"0", margin:"0",padding:"0"}}>*/}
                    {/*<FS1 fs={"./fs/testPrimitives3.fs"}/>*/}
                    <ThreeCard4 id={0}/>
                    {/*<FS1 fs={"./fs/testPrimitives.fs"}/>*/}
                    {/*</div>*/}
                </div>
            </div>
        )
    };
}

export default ThreeTests