const atomuData = {
    id:1,
    overview:{
        coverImgUrl:"/assets/atomu/01-cover.jpg",
        descriptionBold:"For my final project of Physical Computing class at NYU ITP, I created an interactive display of human anatomy in 3D, in which the section views of organs are revealed according to  their physical depth.",
        descriptionRegular:"This project investigates new ways to interact with virtual information in physical space. It integrates and overlays the digital “interface” on top of tangible experiences and spacial deformations. ",
        timelineImgUrl:"",

        role:"Concept Development, Design, Coding, Fabrication",
        theTeam:"Myself",
        tools:"Arduino, Processing (a Java-based graphics library), Adobe PhotoShop, Maya",
        duration:"Nov 2016 - Dec 2016"
    },
    o0_PBI0:[
        {   description:"Atomu is an Augmented Reality installation that allows people to dissect and view human organs through touching the physical interface in 3D. The installation supports a life size human figure covered by deformable fabric, and the visual content is projected onto the fabric from behind.",
            title0:"How it's built: ",
            bullet0:"Behind the fabric, 12 proximity sensors are installed. Depending on how deep the users press into the fabric, the depth data is collected and sent to Processing, to select the section views of the human body at the corresponding depth.",
            imgUrl:"/assets/atomu/atomu.gif"
        }
    ],
    o1_SV1:[
        {
            title:"DEMO VIDEO:",
            vUrl:"https://player.vimeo.com/video/196811120?title=0&byline=0&portrait=0&transparent=0"
        }
    ],
    o2_PI0:[
        {
            imgUrl:"/assets/atomu/diagram-s.jpg",
        }
    ],
    e0_SI0:[
        {
            title:"DEMO AT ITP WINTER SHOW 2016",
            imgUrl:"/assets/atomu/e-01.jpg",
        }
    ],
    e1_SPI0:[
        {
            title:"SHOW AT NEW YORK HALL OF SCIENCE",
            descriptionL:"After its debut at the ITP Winter Show 2016, Atomu received many exciting feedback from visitors. It was also selected to attend the exhibition at New York Hall of Science (NYSCI), during its STEM Night event.",
            descriptionR:"Hundreds of high school students came to NYSCI during the exhibit, and I had the chance to teach them the technology I used to build this installation.",
            imgUrl:"/assets/atomu/e-02.jpg",
        }
    ],
    p0_SPI0:[
        {
            title:"SLICE ANATOMIC MODELS INTO INDIVIDUAL LAYERS",
            descriptionL:"To create a image database for the interface, 3D models of human organs are first sliced and rendered into 22 discrete layers, using the Maya software.",
            descriptionR:"",
            imgUrl:"/assets/atomu/p-01.jpg"
        },
        {
            title:"BUILD PHYSICAL STRUCTURE USING CNC",
            descriptionL:"Using computer-aided fabrication tools, I constructed a human-sized rigid wooden frame to support the deformable tactile interface.",
            descriptionR:"",
            imgUrl:"/assets/atomu/p-02.jpg"
        },
    ],
}

export default atomuData