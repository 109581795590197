var prevScrollpos = window.pageYOffset;

window.onscroll = function() {
    var currentScrollPos = window.pageYOffset;
    var nav= document.getElementById("navbar");

    var mylogo = document.getElementById("logo");

    if (document.getElementById("side-nav")) {
        mylogo.style.width = "48px";
        // if(currentScrollPos<2){
        //     nav.style.boxShadow = "none";
        // } else{
            if (currentScrollPos<=150) {
                nav.style.boxShadow = "none";
            }
            else{
                nav.style.background = "#ffffff";
                nav.style.boxShadow = "1px 1px 4px rgba(0, 0, 0, .05)";
            }
        //}
    }
    else {
        nav.style.boxShadow = "none";
        nav.style.background = "transparent";
        // if(currentScrollPos<5){
        //     mylogo.style.width = "120px";
        //     nav.style.background = "transparent";
        // } else{
        if (currentScrollPos>=150){
                //nav.style.boxShadow = "1px 1px 4px rgba(0, 0, 0, .05)";
                nav.style.background = "#f8f8f8";
                mylogo.style.width = "48px";
            }
        else{
            //nav.style.boxShadow = "none";
            var resize = 64 - currentScrollPos*0.25;
            mylogo.style.width = resize+"px";
        }
        //}
    }

    if (prevScrollpos > currentScrollPos | currentScrollPos<5) {
        nav.style.top = "0";
    } else {
        nav.style.top = "-60px";
    }
    prevScrollpos = currentScrollPos;
}