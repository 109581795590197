import React from 'react';
import ReactPlayer from 'react-player'

class SV0 extends React.Component{
    constructor(){
        super();
        this.state = {

        };
    }

    render(){
        return(
            <div>
                <h4 style={{width:"50vw",paddingLeft:"14vw",paddingRight:"15vw"}}>{this.props.data.title}</h4>
                <div>
                    <ReactPlayer url={this.props.data.vUrl} width={"58vw"} height={"31vw"} style={{float:"right",margin:"1vw 6vw",paddingTop:".0vw", position:"relative"}} playing loop/>
                </div>
            </div>
        )
    }
}

export default SV0