import React from 'react';

class PBI1 extends React.Component{
    constructor(){
        super();
        this.state = {

        };
    }
    render(){
        return(
            <div>
                {/*<h4>{this.props.data.title}</h4>*/}
                <div className="pofo-text-row" >
                    {/*<div className="pofo-text-col-sticky" style={{width:"40vw", flex:"none", position:"sticky",top:"0"}}>*/}
                    <div className="pofo-text-col-sticky" style={{width:"30vw", paddingLeft:"5vw"}}>
                    <h4>{this.props.data.title}</h4>
                        <div style={this.props.data.description==="" ? { display: 'none' }:{paddingBottom:"2vh",paddingTop:"1vh",width:"30vw"}}>
                            <b>{this.props.data.description}</b>
                        </div>
                        <div className="pofo-text-col-col" style={{width:"30vw",paddingBottom:"1vh"}}>
                            <b>{this.props.data.title0}</b>{this.props.data.bullet0}
                        </div>
                        <div className="pofo-text-col-col" style={{width:"30vw",paddingBottom:"1vh"}}>
                            <b>{this.props.data.title1}</b>{this.props.data.bullet1}
                        </div>
                        <div className="pofo-text-col-col" style={{width:"30vw",paddingBottom:"1vh"}}>
                            <b>{this.props.data.title2}</b>{this.props.data.bullet2}
                        </div>
                        <div style={{display:window.innerWidth < 600?"none":""}}>
                            <div style={{paddingTop:"2vh"}}><b>Example: Course Page for Grade 8 "Media Studies"</b></div>
                            <div className="toggle-img">
                                <img src="/assets/ross/thumb-c-1.jpg" id="c-tab-1" href="#o0" style={{opacity:"1", margin:"0"}}/><p>"Overview" Tab</p>
                            </div>
                            <div className="toggle-img">
                                <img src="/assets/ross/thumb-c-2.jpg" id="c-tab-2" href="#o0" style={{margin:"0"}}/><p>"Resources" Tab</p>
                            </div>
                            <div className="toggle-img">
                                <img src="/assets/ross/thumb-c-3.jpg" id="c-tab-3" href="#o0" style={{margin:"0"}}/><p>"Units" Tab</p>
                            </div>
                        </div>
                    </div>

                    <div className="pofo-img-col" style={{width:"40vw", overflow:"auto", marginTop:"5vw",  flex:"none",position:"relative"}}>
                        <img id="display-c" src={this.props.data.imgUrl} style={{width:"38vw",float:"left", margin:"0",position:"relative"}}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default PBI1