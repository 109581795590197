import React, { Component } from 'react';
import { Link } from "react-router-dom";
// Three js
import * as THREE from 'three';
import * as OBJLoader from 'three-obj-loader';
import * as MTLLoader from 'three-mtl-loader';
OBJLoader(THREE);

class ThreeCard5 extends Component{
    constructor(){
        super();
        this.state = {
            hover: false,
            onLoop: false
        };
    };
    componentDidMount(){
        this.angle=0;
        const width = this.mount.clientWidth;
        const height = this.mount.clientHeight;
        //ADD SCENE
        this.scene = new THREE.Scene();
        var myscene = this.scene;
        //ADD CAMERA
        // var frustumSize = 8;
        // this.camera = new THREE.OrthographicCamera( frustumSize / - 2, frustumSize / 2, frustumSize / 2, frustumSize / - 2, 0, 1000 );
        this.camera = new THREE.PerspectiveCamera(
            45,
            width/height,
            0.1,
            20
        );
        this.camera.position.z = 7.5;
        //FOR TEXTURE
        const tLoader = new THREE.TextureLoader();
        const mapOverlay = tLoader.load(this.props.data.imgUrl);
        // FOR OBJ
        this.THREE = THREE;

        //const objLoader = new this.THREE.OBJLoader();

        const mtlLoader = new MTLLoader();
        const objLoader = new this.THREE.OBJLoader();
        var material = new THREE.MeshLambertMaterial({color: 0x3f95d5, transparent: true, opacity: 0.5});
        objLoader.load(
            // resource URL
            '/assets/obj/shell.obj',
            // called when resource is loaded
            function ( object ) {
                object.material = material;
                object.traverse( function ( child ) {
                    if ( child instanceof THREE.Mesh ) {
                        object.scale.set(.018,.018,.018);
                        object.rotateX(Math.PI/3);
                        object.rotateY(-.4);
                        object.rotateZ(.3);
                        object.position.set(-.19,-1,0);
                        child.material = material;
                    }
                } );
                myscene.add( object);
            },
            // called when loading is in progresses
            function ( xhr ) {
                console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
            },
            // called when loading has errors
            function ( error ) {
                console.log( 'An error happened (OBJ Loader)' );
            }
        );

        mtlLoader.load(
            '/assets/obj/solid.mtl',
            (materials) => {
                materials.preload()
                console.log(materials)
                const objLoader = new this.THREE.OBJLoader()
                objLoader.setMaterials(materials)
                objLoader.load(
                    '/assets/obj/solid.obj',
                    (object) => {
                        object.scale.set(.018,.018,.018);
                        object.rotateX(Math.PI/3);
                        object.rotateY(-.4);
                        object.rotateZ(.3);
                        object.position.set(-.2,-1,0);
                        this.scene.add(object)
                    },
                    (xhr) => {
                        console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                    },
                    (error) => {
                        console.log('An error happened')
                    }
                )
            },
            (xhr) => {
                console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
            },
            (error) => {
                console.log('An error happened')
            }
        );


        //CREATE PLANE
        // const material0 = new THREE.MeshBasicMaterial({map: mapOverlay});
        // this.planeGeometry = new THREE.PlaneGeometry(4.2, 2.5, 0, 0);
        // this.plane = new THREE.Mesh(this.planeGeometry, material0);
        // this.plane.rotateY(.5);
        // this.plane.rotateX(-.3);
        // this.plane.position.set(-1,-1.4,-2);

        //ADD RENDERER
        this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true  })
        // this.renderer.setClearColor('#000000')
        this.renderer.setSize(width, height)
        this.renderer.maxAnisotropy=1024;
        this.mount.appendChild(this.renderer.domElement)
        // ADD LIGHT
        this.light2 = new THREE.AmbientLight( 0xFFFFFF, .9 );
        this.light = new THREE.PointLight( 0xFEFBFB, 1.75, 50 );
        this.light.position.set( 5, 10, 10 );

        this.scene.add( this.light2 );
        this.scene.add( this.light );
        this.scene.add(this.plane);

        this.mount.addEventListener("pointerenter", (event) => {this.setState({hover:true})});
        this.mount.addEventListener("pointerleave", (event) => {this.setState({hover:false})});

        this.start()
    }
    componentWillUnmount(){
        this.stop()
        this.mount.removeChild(this.renderer.domElement)
    }
    start = () => {
        if (!this.frameId) {
            this.frameId = requestAnimationFrame(this.animate)
        }
    }
    stop = () => {
        cancelAnimationFrame(this.frameId)
    }
    animate = () => {
        if (this.state.hover){
            if(this.camera.position.x>0.00001||this.camera.position.x<=0.00001){
                var xdiff=this.camera.position.x/6;
                var ydiff=this.camera.position.y/6;
                this.camera.position.x-=xdiff;
                this.camera.position.y-=ydiff;
            }
            this.light.position.set( 0, 11, 15 );
            this.light.color.set( 0xFFF9F7 );
        }
        else{
            this.angle += 0.006;
            this.camera.position.x = Math.cos(this.angle);
            this.camera.position.y =Math.sin(this.angle);
            this.light.position.set( 0, 13, 15 );
            this.light.color.set( 0xFFFBFE );
        }
        this.camera.lookAt( this.scene.position );
        this.renderScene()
        this.frameId = window.requestAnimationFrame(this.animate)
    }
    renderScene = () => {
        this.renderer.render(this.scene, this.camera)
    }
    render(){
        if (window.innerWidth < 600){
            return(
                <div className="pofo-card" style={{backgroundColor:this.props.data.id%2==0?"#f8f8f8":"#fff"}}>
                    <div className = "pofo-card-3d-container mobile" >
                        <Link to={this.props.data.link} >
                            <div className="pofo-card-3d-view mobile"
                                 ref={(mount) => { this.mount = mount }}/>
                        </Link>
                    </div>

                    <div className="pofo-card-texts mobile"  >
                        <h3><Link to={this.props.data.link} style={{textDecoration:"none",color:"#000"}}>
                            {this.props.data.title}
                        </Link>
                        </h3>
                        <p style={{display:this.props.data.description? "block":"none"}}>{this.props.data.description}</p>
                        <h4>{this.props.data.hashtag}</h4>
                    </div>

                </div>)
        }
        if(this.props.data.id%2==0){
            return(
                <div className="pofo-card" style={{ backgroundColor:this.props.macos? "#f8f8f8":"transparent"}}>
                    <Link to={this.props.data.link} style={{paddingLeft:"10vw"}}><div
                        style={{ width: '30vw', height: '30vw' }}
                        ref={(mount) => { this.mount = mount }} />
                    </Link>
                    <div className="pofo-card-texts" style={{paddingLeft:"6vw"}}>
                        <h3><Link to={this.props.data.link} style={{textDecoration:"none",color:"#000"}}>
                            {this.props.data.title}</Link>
                        </h3>
                        <p style={{display:this.props.data.description? "block":"none"}}>{this.props.data.description}</p>
                        <h4>{this.props.data.hashtag}</h4>
                    </div>
                </div>
            )
        }
        else{
            return(
                <div className="pofo-card" style={{backgroundColor:"#fff"}}>
                    <div className="pofo-card-texts"  style={{paddingLeft:"15vw"}}>
                        <h3><Link to={this.props.data.link} style={{textDecoration:"none",color:"#000"}}>
                            {this.props.data.title}
                        </Link>
                        </h3>
                        <p style={{display:this.props.data.description? "block":"none"}}>{this.props.data.description}</p>
                        <h4>{this.props.data.hashtag}</h4>
                    </div>
                    <div style={{ width: '30vw', height: '30vw'}}>
                        <Link to={this.props.data.link} ><div
                            style={{ width: '30vw', height: '30vw'}}
                            ref={(mount) => { this.mount = mount }} />
                        </Link>
                    </div>
                </div>
            )
        }

    }
}
export default ThreeCard5