const mkctData = {
    id:2,
    overview:{
        coverImgUrl:"/assets/mkct/01-cover.jpg",
        descriptionBold:"As a UI & UX design intern, I lead the designs of the re-branding, ICO website and part of mobile UI for MakeCents, an early-stage FinTech startup who's building a mobile payment app on Blockchain.",
        descriptionRegular:"MakeCents LLC is a tech start-up company. It builds on top of Blockchain technology and introduces a new type of mobile payment application that allow users to store and transfer their cash through a decentralized, secure and efficient network.",
        timelineImgUrl:"/assets/mkct/01-timeline.jpg",

        role:"Visual, UI and Web Design Intern",
        theTeam:"Myself, another design Intern, two full stack developers, and two android developers",
        tools:"Sketch, Invision, Zeplin, Adobe Illustrator, XMind",
        duration:"Feb 2018 - April 2018"
    },
    o0_PBIn: [{
        title:"Design of the Pre-ICO Website",
        description:"Unlike many Blockchain companies out there, MakeCents is at the same time a distributed ledger network for software developers to use, and a consumer-facing application for users to create account, store cash on Blockchain network, and sent/receive payments at point of sale.",
        imgUrl:"/assets/mkct/full-page-s.jpg",
        title0:"Communicate the company and its mission statements: ",
        bullet0:"Given the unique positioning of MakeCents, the Pre-ICO website should speak to both business audience and consumer audience",
        title1:"Flexibility for future updates: ",
        bullet1:"The team was planning to launch their Initial Coin Offering (ICO) very soon, so this website serves both as an introduction to the company, and as a basis on which new functions can be added.",
        title2:"",
        bullet2:""
    }],
    o1_PI0:[
        {   descriptionL:"Visual Identity for MakeCents (Business and Consumer-Facing):",
            imgUrl:"/assets/mkct/vi-01.jpg"
        },
        {   descriptionL:"Visual Identity for MakeCents Foundation (Developer-Facing):",
            imgUrl:"/assets/mkct/vi-02.jpg",
        }
    ],
    br0_PI2:[
        {
            title:"① Interview Founders and the Team:",
            descriptionL:"We created a questionnaire to ask the whole team about the key aspects of MakeCents as a company and as a product/service.",
            descriptionR:"Based on the feedback, we identified keywords to summarize MakeCents's Vision, Strategy, Technology, and Product.",
            imgUrl:"/assets/mkct/survey-01.jpg",
        },
        {
            title:"② Design Survey 1: Blockchain Apps",
            descriptionL:"We picked 7 Blockchain start-ups whose branding were well-done, and analyzed their attributes according to key aspects including Value Proposition, Function, Audience and Visual Style",
            descriptionR:"",
            imgUrl:"/assets/mkct/survey-02.jpg",
        },
        {
            title:"③ Design Survey 2: Consumer Apps",
            descriptionL:"Unlike other blockchain products, MakeCents is facing everyday consumers. Therefore, the visual language should express not only \"high-tech\", but also \"user-friendliness\".",
            descriptionR:"We also found design inspirations based on the keywords that we gathered earlier.",
            imgUrl:"/assets/mkct/survey-03.jpg",
        }
    ],
    bd0_PI2:[
        {
            title:"① Brainstorm & Collect Feedback from Team:",
            descriptionL:"",
            descriptionR:"",
            imgUrl:"/assets/mkct/bd-1.jpg",
        },
        {
            title:"② Refine Design (Digitally):",
            descriptionL:"",
            descriptionR:"",
            imgUrl:"/assets/mkct/bd-2.jpg",
        },
        {
            title:"③ Finalize Design for Different Platforms:",
            descriptionL:"We fine-tuned the logo details in vector drawing, and defined the color scheme by comparing a number of existing payment-related branding designs.",
            descriptionR:"The typeface of \"MakeCents\" and \"MakeCents Foundation\" is also customized, by deriving from Open Sans and using rounded edges to appear more consumer-friendly.",
            imgUrl:"/assets/mkct/bd-3.jpg",
        }
    ],
    wr0_PI2:[
        {
            title:"① Analyze existing blockchain company websites",
            descriptionL:"To understand how their site architecture helps to introduce the various blockchain products/services",
            descriptionR:"",
            imgUrl:"/assets/mkct/wr-1.jpg",
        },
        {
            title:"② Propose site map for MakeCents",
            descriptionL:"Identify the key components of MakeCents as a company and as a product",
            descriptionR:"",
            imgUrl:"/assets/mkct/wr-2.jpg",
        }
    ],
    wd0_PI2:[
        {
            title:"① Organize Content and Design Layout",
            descriptionL:"I sorted out the main aspects that should be covered by MakeCents' ICO website, and created a mock-up of the web layout, in order to effectively communicate with the product manager.",
            descriptionR:"",
            imgUrl:"/assets/mkct/wd-1.jpg",
        },
        {
            title:"② Revise content and layout design",
            descriptionL:"Based on the feedback ( in regards to current progress of development and copy-writing), update the content and layout.",
            descriptionR:"",
            imgUrl:"/assets/mkct/wd-2.jpg",
        },
        {
            title:"③ Create visual design",
            descriptionL:"",
            descriptionR:"",
            imgUrl:"/assets/mkct/wd-3.jpg",
        }
    ],
    mo_PI2:[
        {
            descriptionL:"The MakeCents consumer app allows a customer to pay the merchant by either cash or MakeCents coins.",
            descriptionR:"If the customer choose to pay by cash, such as first time users, they will receive the change in the form of Makecents coins, automatically stored in the app and available to be used next time.",
            imgUrl:"/assets/mkct/consumer_app-1.jpg",
        },
        {
            imgUrl:"/assets/mkct/consumer_app-2.jpg",
        },
    ],
    mo_PBIn: [{
        title:"UI Components Library for Android App (MVP)",
        description:"Based on the new branding of MakeCents, I designed the UI components for the MVP mobile app to incorporate similar visual language.",
        imgUrl:"/assets/mkct/ui_guildline.jpg",
        title0:"",
        bullet0:"",
        title1:"",
        bullet1:"",
        title2:"",
        bullet2:""
    }],
    mr_PI:[
        {
            title:"① Understand business logic",
            descriptionL:"Understand the different forms of assets are stored in the MakeCents app, and survey landing experiences of popular payment apps.",
            imgUrl:"/assets/mkct/c_research_process-1.jpg",
        },
        {
            title:"② Mapping out user flows",
            imgUrl:"/assets/mkct/c_research_process-2.jpg",
        },
    ],
    mp_PI:[
        {
            title:"① Apply New Branding Scheme to all app screens",
            imgUrl:"/assets/mkct/c_design_process-1.jpg",
        },
        {
            title:"② Design features for for account settings and app learnability",
            imgUrl:"/assets/mkct/c_design_process-2.jpg",
        },
        {
            title:"③ Establish UI Component Library",
            imgUrl:"/assets/mkct/c_design_process-3.jpg",
        }
    ],
    mmo_PI2:[
        {
            descriptionL:"The MakeCents merchant app allows a merchant to charge the customer by either cash or MakeCents coins.",
            descriptionR:"The initial version of the merchant app requires the merchant to manually input a specific amount, and a later iteration of the app allows for a menu-based entries at point of sale.",
            imgUrl:"/assets/mkct/merchant_app-1.jpg",
        },
        {
            imgUrl:"/assets/mkct/merchant_app-2.jpg",
        },
    ],
    mmr_PI:[
        {
            title:"① Understand different payment methods and ideate on the corresponding check-out processes",
            imgUrl:"/assets/mkct/m_research_process-1.jpg",
        },
        {
            title:"② Improve user-flows to make the check out process more smooth",
            imgUrl:"/assets/mkct/m_research_process-2.jpg",
        },
    ],
    mmp_PI:[
        {
            title:"① Improve the checkout (receive payment) workflow",
            imgUrl:"/assets/mkct/m_design_process-1.jpg",
        },
        {
            title:"② Design a menu/shopping cart feature to allow fast check-out",
            imgUrl:"/assets/mkct/m_design_process-2.jpg",
        },
    ],
}

export default mkctData