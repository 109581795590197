import React from 'react';

class Clickzoom41 extends React.Component{
    constructor(){
        super();
        this.state = {
        };
    }

    render(){
        return(
            <div>
                <div className="pofo-text-row">
                    <div className="pofo-text-col" style={{width:"80vw",padding:"0vw 15vw 0 14vw"}}>
                        <b style={this.props.data.title==="" ? { display: 'none' }:{}}>{this.props.data.title}</b>
                        <p style={this.props.data.descriptionL==="" ? { display: 'none' }:{}}><span>{this.props.data.descriptionL}</span></p>
                    </div>
                </div>
                <div class="box1" style={{backgroundColor:"#F7F6FA",width:"74vw",margin:" 1vw 3vw 0vw 3vw"}}>
                    <div id="lb-back-h">
                        <div id="lb-img-h"></div>
                    </div>
                    <ul id="row-2-light" style={{padding: "1.7vw 2.7vw 1.7vw 2.7vw",margin: "0", display: "inline-flex", marginBlockStart: "0",marginBlockEnd: "0",marginInlineStart: "0",marginInlineEnd: "0"}}>
                        <li style={{margin:"1.0vw"}}><b>{this.props.data.imgH1}</b><br/><p>{this.props.data.imgTxt1}</p><img src={this.props.data.imgUrl1} className="zoomDH" style={{width: "15vw", margin:"0"}}/></li>
                        <li style={{margin:"1.0vw"}}><b>{this.props.data.imgH2}</b><br/><p>{this.props.data.imgTxt2}</p><img src={this.props.data.imgUrl2} className="zoomDH" style={{width: "15vw", margin:"0"}}/></li>
                        <li style={{margin:"1.0vw"}}><b>{this.props.data.imgH3}</b><br/><p>{this.props.data.imgTxt3}</p><img src={this.props.data.imgUrl3} className="zoomDH" style={{width: "15vw", margin:"0"}}/></li>
                        <li style={{margin:"1.0vw"}}><b>{this.props.data.imgH4}</b><br/><p>{this.props.data.imgTxt4}</p><img src={this.props.data.imgUrl4} className="zoomDH" style={{width: "15vw", margin:"0"}}/></li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Clickzoom41