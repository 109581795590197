import React from 'react';
import ReactDOM from 'react-dom';
import Overview from './pofoComponents/Overview'
import PI0 from './pofoComponents/PI0'
import SI0 from './pofoComponents/SI0'
import PBI0 from './pofoComponents/PBI0'
import SPI0 from './pofoComponents/SPI0'
import SV1 from './pofoComponents/SV1'

import atomuData from "../data/atomuData.js"
import "../css/pofo.css"
import "../css/sideNav.css"
import $ from 'jquery';

class Atomu extends React.Component {

    constructor(){
        super();
        //pass data of this project
        this.state = {
            hasResearch: false,
            hasPrototype:false
        };
        this.o0_PBI0 = atomuData.o0_PBI0.map(idea=>{
            return <PBI0 data={idea}/>
        });
        this.o1_SV1 = atomuData.o1_SV1.map(dt=>{
            return <SV1 data={dt}/>
        });
        this.o2_PI0= atomuData.o2_PI0.map(dt=>{
            return <PI0 data={dt}/>
        });
        this.e0_SI0=atomuData.e0_SI0.map(dt=>{
            return <SI0 data={dt}/>
        });
        this.e1_SPI0=atomuData.e1_SPI0.map(dt=>{
            return <SPI0 data={dt}/>
        });
        this.p0_SPI0=atomuData.p0_SPI0.map(dt=>{
            return <SPI0 data={dt}/>
        });
    };

    componentDidMount(){
        // nav -> content
        $(document).ready(function(){
            //Switch to white scheme
            $('#root').css('background-color', 'white');
            $('#navbar').css('background-color', 'white');
            $('body').scrollspy({target: ".pofo-page", offset: 50});

            // Add smooth scrolling on all links inside the navbar
            $("#side-nav a").on('click', function(event) {
                // Make sure this.hash has a value before overriding default behavior
                if (this.hash !== "") {
                    // Prevent default anchor click behavior
                    event.preventDefault();
                    // Store hash
                    var targetHref = $(this).attr('href');
                    // Using jQuery's animate() method to add smooth page scroll
                    // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
                    $('html, body').animate({
                        scrollTop: $(targetHref).offset().top
                    }, 900);
                }  // End if
            });

            $(window).scroll(function() {
                var scrollDistance = $(window).scrollTop();
                // Assign active class to nav links while scolling
                $('.page-section').each(function(m) {
                    if ($(this).position().top <= scrollDistance) {
                        $('.side-nav div a.active').removeClass('active');
                        $('.side-nav div a').eq(m).addClass('active');
                    }
                    else{
                        $('.subgroup').each(function(n) {
                            $('nav ul li a.active').removeClass('active');
                        });
                    }
                });

                $('.subgroup').each(function(n) {
                    if ($(this).position().top <= scrollDistance) {
                        $('nav ul li a.active').removeClass('active');
                        $('nav ul li a').eq(n).addClass('active');
                    }
                });


            }).scroll();

        });}

    render() {
        window.scrollTo(0, 0);

        if(window.innerWidth < 600){
            return(
                <div className="pofo-page mobile" >
                    <div style={{width:"90vw", marginLeft:"5vw", float:"left", display:"inline"}}>
                        <Overview data={atomuData}/>
                        <div>
                            <h2 className="page-section" id="do">DESIGN OUTCOMES</h2>
                            <div>{this.o0_PBI0}</div>
                            <div>{this.o2_PI0}</div>
                            <div>{this.o1_SV1}</div>
                        </div>
                        <div>
                            <h2 className="page-section" id="ex">EXHIBITIONS</h2>
                            <div>{this.e0_SI0}</div>
                            <div>{this.e1_SPI0}</div>
                        </div>
                        <div>
                            <h2 className="page-section" id="dp">DESIGN PROCESS</h2>
                            <div>{this.p0_SPI0}</div>
                        </div>
                    </div>
                </div>
            );
        }
        return(
            <div className="pofo-page" >
                <div style={{width:"18vw", marginLeft: "0vw", float:"left"}}>
                    <nav className="side-nav" id="side-nav" style={{position: "fixed", display:"inline-block"}}>
                        <div><a href="#overview">OVERVIEW</a></div>
                        <div><a href="#do">DESIGN OUTCOME</a></div>
                        <div><a href="#ex">EXHIBITIONS</a></div>
                        <div><a href="#dp">DESIGN PROCESS</a></div>
                    </nav>
                </div>
                <div style={{width:"80vw", marginLeft:"18vw", float:"left", display:"inline"}}>
                    <Overview data={atomuData}/>
                    <div>
                        <h2 className="page-section" id="do" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>DESIGN OUTCOMES</h2>
                        <div>{this.o0_PBI0}</div>
                        <div>{this.o2_PI0}</div>
                        <div>{this.o1_SV1}</div>
                    </div>
                    <div>
                        <h2 className="page-section" id="ex" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>EXHIBITIONS</h2>
                        <div>{this.e0_SI0}</div>
                        <div>{this.e1_SPI0}</div>
                    </div>
                    <div>
                        <h2 className="page-section" id="dp" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>DESIGN PROCESS</h2>
                        <div>{this.p0_SPI0}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Atomu