import React, { Component } from 'react';
import { Link } from "react-router-dom";
// Three js
import * as THREE from 'three';

function facetedBox(w, h, d, f, isWireframed){
    let hw = w * 0.5, hh = h * 0.5, hd = d * 0.5;
    let vertices = [
        // px
        hw, hh - f, -hd + f,   // 0
        hw, -hh + f, -hd + f,  // 1
        hw, -hh + f, hd - f,   // 2
        hw, hh - f, hd - f,    // 3

        // pz
        hw - f, hh - f, hd,    // 4
        hw - f, -hh + f, hd,   // 5
        -hw + f, -hh + f, hd,  // 6
        -hw + f, hh - f, hd,   // 7

        // nx
        -hw, hh - f, hd - f,   // 8
        -hw, -hh + f, hd - f,  // 9
        -hw, -hh + f, -hd + f, // 10
        -hw, hh - f, -hd + f,  // 11

        // nz
        -hw + f, hh - f, -hd,  // 12
        -hw + f, -hh + f, -hd, // 13
        hw - f, -hh + f, -hd,  // 14
        hw - f, hh - f, -hd,   // 15

        // py
        hw - f, hh, -hd + f,   // 16
        hw - f, hh, hd - f,    // 17
        -hw + f, hh, hd - f,   // 18
        -hw + f, hh, -hd + f,  // 19

        // ny
        hw - f, -hh, -hd + f,  // 20
        hw - f, -hh, hd - f,   // 21
        -hw + f, -hh, hd - f,  // 22
        -hw + f, -hh, -hd + f  // 23
    ];

    let indices = [
        0, 2, 1, 3, 2, 0,
        4, 6, 5, 7, 6, 4,
        8, 10, 9, 11, 10, 8,
        12, 14, 13, 15, 14, 12,
        16, 18, 17, 19, 18, 16,
        20, 21, 22, 23, 20, 22,

        // link the sides
        3, 5, 2, 4, 5, 3,
        7, 9, 6, 8, 9, 7,
        11, 13, 10, 12, 13, 11,
        15, 1, 14, 0, 1, 15,

        // link the lids
        // top
        16, 3, 0, 17, 3, 16,
        17, 7, 4, 18, 7, 17,
        18, 11, 8, 19, 11, 18,
        19, 15, 12, 16, 15, 19,
        // bottom
        1, 21, 20, 2, 21, 1,
        5, 22, 21, 6, 22, 5,
        9, 23, 22, 10, 23, 9,
        13, 20, 23, 14, 20, 13,

        // corners
        // top
        3, 17, 4,
        7, 18, 8,
        11, 19, 12,
        15, 16, 0,
        // bottom
        2, 5, 21,
        6, 9, 22,
        10, 13, 23,
        14, 1, 20
    ];

    let indicesWire = [
        0, 1, 1, 2, 2, 3, 3, 0,
        4, 5, 5, 6, 6, 7, 7, 4,
        8, 9, 9, 10, 10, 11, 11, 8,
        12, 13, 13, 14, 14, 15, 15, 12,
        16, 17, 17, 18, 18, 19, 19, 16,
        20, 21, 21, 22, 22, 23, 23, 20,
        // link the sides
        2, 5, 3, 4,     //px - pz
        6, 9, 7, 8,     // pz - nx
        10, 13, 11, 12, // nx - nz
        15, 0, 14, 1,   // nz - px

        // link the lids
        // top
        16, 0, 17, 3,   // px
        17, 4, 18, 7,   // pz
        18, 8, 19, 11,  // nx
        19, 12, 16, 15,  // nz
        // bottom
        20, 1, 21, 2,
        21, 5, 22, 6,
        22, 9, 23, 10,
        23, 13, 20, 14
    ];

    let geom = new THREE.BufferGeometry();
    geom.setAttribute("position", new THREE.BufferAttribute(new Float32Array(vertices), 3));
    geom.setIndex(isWireframed ? indicesWire : indices);
    if (!isWireframed) geom.computeVertexNormals();
    return geom;
}

const boxWidth = .5;
class ThreeCard4 extends Component{
    constructor(){
        super();
        this.state = {
            hover: false,
            onLoop: false
        };
    };
    componentDidMount(){
        this.angle=0;
        const width = this.mount.clientWidth;
        const height = this.mount.clientHeight;
        //ADD SCENE
        this.scene = new THREE.Scene();
        //ADD CAMERA
        // var frustumSize = 8;
        // this.camera = new THREE.OrthographicCamera( frustumSize / - 2, frustumSize / 2, frustumSize / 2, frustumSize / - 2, 0, 1000 );
        this.camera = new THREE.PerspectiveCamera(
            45,
            width/height,
            0.1,
            20
        );
        this.camera.position.z = 5;
        this.camera.position.y = 2;
        // mapOverlay.wrapT = THREE.RepeatWrapping;
        // FOR OBJ
        this.THREE = THREE;
        const material =new THREE.MeshLambertMaterial( { color: 0xf0f0f0} );
        // let material1 =  new THREE.ShaderMaterial({
        //     fragmentShader: fragmentShader(),
        //     vertexShader: vertexShader(),
        // });
        //CREATE PLANE
        this.boxGeometry = new THREE.BoxGeometry( boxWidth, boxWidth/8, boxWidth );
        this.boxes = new Array();
        let w = Math.floor(width/(2*boxWidth));
        w=10;
        let d = 5; // depth
        for (let i = 0;i<w; i++)
        {
            for (let j = 0;j<d; j++){
                let box = new THREE.Mesh(this.boxGeometry, material);
                // box.position.set(i*boxWidth*1.1-width/2,j*boxWidth*1.1-height/2,0);
                box.position.set(i*boxWidth*1.05-w/4,.2,j*boxWidth*1.05-d/2);
                this.boxes.push(box);
            }
        }
        // this.box = new THREE.Mesh(this.boxGeometry, material);
        // this.box.position.set(0,0,0);
        // this.scene.add(this.box);

        //ADD RENDERER
        this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true  })
        // this.renderer.setClearColor('#000000')
        this.renderer.setSize(width, height);
        this.renderer.maxAnisotropy=1024;
        this.mount.appendChild(this.renderer.domElement)

        this.light = new THREE.DirectionalLight(0xffffff, 1);
        this.light.position.set( 2, 10, 5 );
        this.scene.add(this.light);
        // this.light1 = new THREE.DirectionalLight(0xAE70FF, .5);
        // this.light1.position.set( -50, 10, 20 );
        // this.scene.add(this.light1);
        for (let i= 0;i<this.boxes.length;i++){
            //this.boxes[i].position.set(i,0,0);
            this.scene.add(this.boxes[i]);
        }

        this.mount.addEventListener("pointerenter", (event) => {this.setState({hover:true})});
        this.mount.addEventListener("pointerleave", (event) => {this.setState({hover:false})});

        this.start();
    }
    componentWillUnmount(){
        this.stop()
        this.mount.removeChild(this.renderer.domElement)
    }
    start = () => {
        if (!this.frameId) {
            this.frameId = requestAnimationFrame(this.animate)
        }
    }
    stop = () => {
        cancelAnimationFrame(this.frameId)
    }
    animate = () => {
        // if (this.state.hover){
        // }
        // else{
        // }
        let time = this.frameId;
        for (let i=0; i<this.boxes.length; i++){
            this.boxes[i].position.y = Math.sin(time/100)/10;
        }
        this.renderScene()
        this.frameId = window.requestAnimationFrame(this.animate);
    }
    renderScene = () => {
        this.renderer.render(this.scene, this.camera)
    }
    render(){
        if(this.props.id%2==0){
            return(
                <div className="pofo-card" style={{ backgroundColor:this.props.macos? "#f8f8f8":"transparent"}}>
                <div
                        style={{ width: '100vw', height: '70vh'}}
                        ref={(mount) => { this.mount = mount }} />
                </div>
            )
        }
        else{
            return(
                <div className="pofo-card" style={{backgroundColor:"#fff"}}>
                </div>
            )
        }

    }
}

export default ThreeCard4