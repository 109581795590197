import React from 'react';

class PI extends React.Component{
    constructor(){
        super();
        this.state = {

        };
    }

    render(){
        return(
            <div>
                <div className="pofo-text-row">
                    <div className="pofo-text-col" style={{width:"80vw",paddingLeft:"14vw",paddingRight:"15vw"}}>
                        <b style={this.props.data.title==="" ? { display: 'none' }:{}}>{this.props.data.title}</b>
                        <div style={this.props.data.descriptionL==="" ? { display: 'none' }:{paddingBottom:"10px"}}><p>{this.props.data.descriptionL}<div>{this.props.data.descriptionR}</div></p></div>
                    </div>
                </div>
                <img src={this.props.data.imgUrl}/>
            </div>
        )
    }
    // render(){
    //     return(
    //         <div>
    //             <div className="pofo-text-row">
    //                 <div className="pofo-text-col" >
    //                     <b style={this.props.data.description==="" ? { display: 'none' }:{}}>{this.props.data.title}</b>
    //                     <div style={this.props.data.description==="" ? { display: 'none' }:{paddingBottom:"8px"}}><p>{this.props.data.descriptionL}<div>{this.props.data.descriptionR}</div></p></div>
    //                 </div>
    //                 <div className="pofo-text-col" style={{paddingLeft:"1vw"}}>
    //                 </div>
    //             </div>
    //             <img src={this.props.data.imgUrl}/>
    //         </div>
    //     )
    // }
}

export default PI