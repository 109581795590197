import React from 'react';
import ReactPlayer from 'react-player'

class SV1 extends React.Component{
    constructor(){
        super();
        this.state = {

        };
    }

    render(){
        return(
            <div>
                <h4 style={{paddingLeft:"14vw",paddingRight:"15vw"}}>{this.props.data.title}</h4>
                <div>
                    <ReactPlayer url={this.props.data.vUrl} width={"53vw"} height={"30vw"} style={{float:"right",margin:"2vw 7vw",position:"relative"}}/>
                </div>
            </div>
        )
    }
}

export default SV1