import React from 'react';
import Overview from './pofoComponents/Overview'
import PI0 from './pofoComponents/PI0'
import PI2 from './pofoComponents/PI2'
import P from './pofoComponents/P'
import PBIn from './pofoComponents/PBIn'
import PBI21 from './pofoComponents/PBI21'

import GalleryColLeft from './pofoComponents/GalleryColLeft'
import DesignOutcome from './pofoComponents/DesignOutcome'
import dvData from "../data/dvData.js"
import "../css/pofo.css"
import "../css/sideNav.css"
import $ from 'jquery';

class Dv extends React.Component {

    constructor(){
        super();
        //pass data of this project
        this.state = {
            hasResearch: false,
            hasPrototype:false
        };
        // Outcome
        this.o0_PBI21 = dvData.o0_PBI21.map(idea=>{
            return <PBI21 data={idea}/>
        });

        // Research
        this.r0_PI2 = dvData.r0_PI2.map(idea=>{
            return <PI2 data={idea}/>
        });
        this.r1_PI2 = dvData.r1_PI2.map(idea=>{
            return <PI2 data={idea}/>
        });
        this.r2_PI0 = dvData.r2_PI0.map(idea=>{
            return <PI0 data={idea}/>
        });
        this.p0_PI2 = dvData.p0_PI2.map(idea=>{
            return <PI2 data={idea}/>
        });
        this.p1_PI2 = dvData.p1_PI2.map(idea=>{
            return <PI2 data={idea}/>
        });
        this.p2_PI2= dvData.p2_PI2.map(idea=>{
            return <PI2 data={idea}/>
        });
        this.additional_P = dvData.o0_PBI21.map(idea=>{
            return <P data={idea}/>
        });
    };

    componentDidMount(){
        // nav -> content
        $(document).ready(function(){
            //Switch to white scheme
            $('#root').css('background-color', 'white');
            $('#navbar').css('background-color', 'white');

            $('body').scrollspy({target: ".pofo-page", offset: 50});

            // Add smooth scrolling on all links inside the navbar
            $("#side-nav a").on('click', function(event) {
                // Make sure this.hash has a value before overriding default behavior
                if (this.hash !== "") {
                    // Prevent default anchor click behavior
                    event.preventDefault();
                    // Store hash
                    var targetHref = $(this).attr('href');
                    // Using jQuery's animate() method to add smooth page scroll
                    // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
                    $('html, body').animate({
                        scrollTop: $(targetHref).offset().top
                    }, 900);
                }  // End if
            });

            $(window).scroll(function() {
                var scrollDistance = $(window).scrollTop();
                // Assign active class to nav links while scolling
                $('.page-section').each(function(m) {
                    if ($(this).position().top-200<= scrollDistance) {
                        $('.side-nav div a.active').removeClass('active');
                        $('.side-nav div a').eq(m).addClass('active');
                    }
                    else{
                        $('.subgroup').each(function(n) {
                            $('nav ul li a.active').removeClass('active');
                        });
                    }
                });
                $('.subgroup').each(function(n) {
                    if ($(this).position().top-200 <= scrollDistance) {
                        $('nav ul li a.active').removeClass('active');
                        $('nav ul li a').eq(n).addClass('active');
                    }
                });
            }).scroll();

            $(document).ready(function(){
                $("#dv-tab-1").on({
                    'click': function(){
                        var targetHref = $(this).attr('href');
                        $('html, body').animate({scrollTop: $(targetHref).offset().top}, 0);
                        // style changes:
                        $(this).css('opacity','1');
                        $('#display-dv').attr('src','/assets/dv/Dashboard-default.png');
                        $('#dv-tab-2').css('opacity','0.5');
                    }
                });
                $("#dv-tab-2").on({
                    'click': function(){
                        var targetHref = $(this).attr('href');
                        $('html, body').animate({scrollTop: $(targetHref).offset().top}, 0);
                        // style changes:
                        $(this).css('opacity','1');
                        $('#display-dv').attr('src','/assets/dv/Dashboard-hover.png');
                        $('#dv-tab-1').css('opacity','0.5');
                    }
                });


            });
        });}

    render() {
        window.scrollTo(0, 0);

        if (window.innerWidth < 600){
            return(
                <div className="pofo-page mobile" >
                    <div style={{width:"90vw", marginLeft:"5vw", float:"left", display:"inline"}}>
                        <Overview data={dvData}/>
                        <div>
                            <h2 className="page-section" id="do">DESIGN OUTCOMES</h2>
                            <div id="o0" className="subgroup">
                                {this.additional_P}
                                {this.o0_PBI21}
                            </div>
                        </div>
                        <div>
                            <h2 className="page-section" id="rp">RESEARCH PROCESS</h2>
                            <div>
                                <h4 id="r0" className="subgroup">BACKGROUND & OBJECTIVES</h4>
                                {this.r0_PI2}
                            </div>
                            <div>
                                <h4 id="r1" className="subgroup">ABOUT THEY SURVEY</h4>
                                {this.r1_PI2}
                            </div>
                            <div>
                                <h4 id="r2" className="subgroup">EXPAND THE DATASET</h4>
                                {this.r2_PI0}
                            </div>
                        </div>
                        <div>
                            <h2 className="page-section" id="dp">DESIGN PROCESS</h2>
                            <div>
                                <h4 id="p0" className="subgroup">INITIAL EXPLORATION</h4>
                                {this.p0_PI2}
                            </div>
                            <div>
                                <h4 id="p1" className="subgroup">PRIORITIZATION</h4>
                                {this.p1_PI2}
                            </div>
                            <div>
                                <h4 id="p1" className="subgroup">DESIGN ITERATIONS</h4>
                                {this.p2_PI2}
                            </div>
                        </div>

                    </div>
                </div>
            );
        }
        return(
            <div className="pofo-page" >
                <div style={{width:"18vw", marginLeft: "0vw", float:"left"}}>
                    <nav className="side-nav" id="side-nav" style={{position: "fixed", display:"inline-block"}}>
                        <div><a href="#overview">OVERVIEW</a></div>
                        <div><a href="#do">DESIGN OUTCOMES</a></div>
                        <ul>
                            <li><a href="#o0">AppCues Survey Report</a></li>
                        </ul>
                        <div><a href="#rp">RESEARCH PROCESS</a></div>
                        <ul>
                            <li><a href="#r0">Background & Objectives</a></li>
                            <li><a href="#r1">About the Survey</a></li>
                            <li><a href="#r2">Expand the Dataset</a></li>
                        </ul>
                        <div><a href="#dp">DESIGN PROCESS</a></div>
                        <ul>
                            <li><a href="#p0">Initial Exploration</a></li>
                            <li><a href="#p1">Prioritization</a></li>
                            <li><a href="#p2">Design Iterations</a></li>
                        </ul>
                        {/*<div><a href="#ux">UX REFINEMENTS</a>*/}
                        {/*</div>*/}
                    </nav>
                </div>
                <div style={{width:"80vw", marginLeft:"18vw", float:"left", display:"inline"}}>
                    {/*position: "relative", float:"left",*/}
                    <Overview data={dvData}/>
                    <div>
                        <h2 className="page-section" id="do" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>DESIGN OUTCOMES</h2>
                        {this.additional_P}
                        <div id="o0" className="subgroup">
                            {this.o0_PBI21}
                        </div>
                    </div>
                    <div>
                        <h2 className="page-section" id="rp" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>RESEARCH PROCESS</h2>
                        <div id="r0" className="subgroup" >
                            <h4 style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"1vw"}}>Project Background & Objectives</h4>
                            {this.r0_PI2}
                        </div>
                        <div id="r1" className="subgroup" >
                            <h4 style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"3vw"}}>About The Survey</h4>
                            {this.r1_PI2}
                        </div>
                        <div id="r2" className="subgroup" >
                            <h4 style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"3vw"}}>Expand The Dataset</h4>
                            {this.r2_PI0}
                        </div>
                    </div>
                    <div>
                        <h2 className="page-section" id="dp" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>DESIGN PROCESS</h2>
                        <div id="p0" className="subgroup" >
                            <h4 style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"1vw"}}>Initial Exploration</h4>
                            {this.p0_PI2}
                        </div>
                        <div id="p1" className="subgroup" >
                            <h4 style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"3vw"}}>Prioritization</h4>
                            {this.p1_PI2}
                        </div>
                        <div id="p1" className="subgroup" >
                            <h4 style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"3vw"}}>Design Iteration</h4>
                            {this.p2_PI2}
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default Dv