const dtsData = {
    id:0,
    overview:{
        coverImgUrl:"/assets/dts/intro/dts-cover.png",
        descriptionBold:"I led the designs for the Deal Troubleshooting and Monitoring interface, a set of tools allowing Xandr Monetize users to monitor programmatic deals, inspect and resolve errors efficiently, and have a smoother transaction experience.",
        descriptionRegular:"The Deal Troubleshooting interface situates in Xandr Monetize, the sell-side platform for media publishers to monetize their ad inventories by reaching to a wide range of buyers (advertisers or media agencies) and using a variety of audience-targeting channels.",
        timelineImgUrl:"/assets/dts/intro/timeline.jpg",

        role:"Lead UX Designer",
        theTeam:"Product Manager, Engineering Team, Myself, Internal Ad Operations and technical solutions consultants",
        tools:"Sketch, Invision, Abstract, pen & paper, Jira, Google Jamboard",
        duration:"Q2 2020 - Q4 2020 (Alpha Release)"
    },
    ov_SV0:[
        {
            title:"Demo: Troubleshooting a Deal Line Item",
            vUrl:"https://player.vimeo.com/video/600372703?title=0&byline=0&portrait=0&transparent=0&autoplay=1&loop=1"
        }
    ],
    o0_PBI11: [{
        title:"IMPRESSION FUNNEL",
        description:"An interactive visual representation of the programmatic deal impression funnel – along with custom settings. This helps users understand at a glance where issues might be occurring within an individual deal.",
        imgUrl:"/assets/dts/win-rate.jpg",
        title0:"",
        bullet0:"Because the 4 conversion rates (Bid Request Rate/Bid Rate/Win Rate/Render Rate) are important deal health indicators that Ad Ops users look at, we offer an inline experience that shows the errors for a given deal, broken out into specific, actionable and complete error messages.",
        title1:"",
        bullet1:"",
        title2:"",
        bullet2:""
    }],
    o1_PBI12:[
        {
            title:"DEAL ERROR REPORT",
            description:"Bringing in Xandr’s reporting capability as an in-line experience.",
            imgUrl:"/assets/dts/er-1.jpg",
            title0:"",
            bullet0:"This allows users to quickly pull error reports during the troubleshooting process, customize dimensions (table columns) and filters on the fly, and view or export the detailed deal error reports directly – instead of navigating to a separate Reporting UI.",
            title1:"",
            bullet1:"",
            title2:"",
            bullet2:""
        }
    ],
    o2_PBI13:[
        {
            title:"BID REQUEST SAMPLER",
            description:"A simple, self-serving workflow for users to generate and download an outgoing bid request to a bidder for a given deal ID.",
            imgUrl:"/assets/dts/brs-2.jpg",
            title0:"",
            bullet0:"Previously, users had to submit tickets to Xandr’s customer support team and wait for 24 hours. But now they can sample, view and share the raw bid requests themselves.",
            title1:"",
            bullet1:"",
            title2:"",
            bullet2:""
        }
    ],
    o3_CZ3:[
        {
            title:"Feedback from Team and Clients",
            descriptionL:"",
            descriptionR:"",
            imgTxt1:"Reactions from Product & Engineering",
            imgTxt2:"Feedback from Initial Release",
            imgTxt3:"Client Usage",
            imgUrl1:"/assets/dts/intro/feedback-1.png",
            imgUrl2:"/assets/dts/intro/feedback-2.png",
            imgUrl3:"/assets/dts/intro/feedback-3.png",
        }
    ],
    r0_PI2:[
        {   title:"Programmatic Ad Buying",
            descriptionL:"As Programmatic Deal buying in Private Marketplace(PMP) becomes increasing popular, it's critical for Xandr Monetize to provide sellers and their Ad Operations teams a frictionless deal experience for them to stay and transact more deals on our platform.",
            imgUrl:"/assets/dts/project-background.jpg"
        },
        {
            title:"Complexity and Challenge with using Deals ",
            descriptionL:"Troubleshooting programmatic deal line items on Monetize has been prohibitively challenging: it requires in-depth knowledge of the metrics that Xandr offer – which are very technical and can be daunting to new users, and there isn’t substantially useful tools to help diagnose and fix issues that block a deal’s delivery.",
            imgUrl:"/assets/dts/background-1.jpg",
        }
    ],
    r0_CZ21:[
        {   title:"Programmatic Ad Buying using Deals",
            descriptionL:"As Programmatic Deal buying in Private Marketplace(PMP) becomes increasing popular, it's critical for Xandr Monetize to provide sellers and their Ad Operations teams a frictionless deal experience for them to stay and transact more deals on our platform.",
            imgUrl1:"/assets/dts/intro/ssp-dsp.png",
            imgUrl2:"/assets/dts/intro/private-rtb.png",
            imgUrl3:"/assets/dts/intro/ad-call.png",
        }
    ],
    r1_CZ13:[
        {
            title:"Seller Monitoring Workflow (SMW)",
            descriptionL:"The Deal Troubleshooting Tab will sit on top of Xandr Monetize's existing Seller Monitoring Workflow - a monitoring dashboard for Ad Ops users to track their on-going deals.",
            descriptionR:"It will help publishers troubleshoot why a deal isn't working, arm them with in-depth reporting, and provide tools to resolve the errors they discover.",
            imgUrl1:"/assets/dts/intro/smw-long.png",
            imgUrl2:"/assets/dts/intro/columns.png",
            imgUrl3:"/assets/dts/intro/filters.png",
            imgUrl4:"/assets/dts/intro/side-panel.png",
        }
    ],
    r1_PI2:[
        {
            title:"Xandr's Vision & Opportunity",
            descriptionL:"The Deal Troubleshooting Tab will help publishers troubleshoot why a deal isn't working, arm them with in-depth reporting, and provide tools to resolve the errors they discover.",
            descriptionR:"This new feature will be built on top of Xandr Monetize's existing Seller Monitoring Workflow, and appear in a side panel when a line item is selected.",
            imgUrl:"/assets/dts/xandr-smw.jpg",
        },
        {   title:"Current User Demand",
            descriptionL:"Based on insights from Xandr’s customer support team and client facing PMs, we created initial personals and user stories about our publisher clients.",
            descriptionR:"This helped us to better understand how publishers use programmatic deals these days, when they may get stuck, and what they need to identify and resolve issues.",
            imgUrl:"/assets/dts/current-demand.jpg"
        }
    ],
    r2_PI2:[
        {
            title:"",
            descriptionL:"Based on insights from Xandr’s customer support team and client facing PMs, we created initial personals and user stories about our publisher clients.",
            descriptionR:"Throughout our design process we interviewed both internal technical support teams and Xandr Monetize’s external clients, and tested every iteration of our designs with them.",
            imgUrl:"/assets/dts/intro/current-demand.jpg"
        },
    ],
    p0_CZ44:[
        {
            title:"",
            descriptionL:"To begin with, I reviewed the product requirements initially created by the Product Manager, and asked further questions on UX and workflow, as well as potential technical blockers.",
            descriptionR:"I also analyzed the troubleshooting features from Xandr's competitors, and what tools they provide the Ad Ops users and how they used data visualization in the workflow.",
            imgTxt1:"Product Wiki Page",
            imgTxt2:"PM's Wireframe",
            imgTxt3:"Virtual Whiteboarding",
            imgTxt4:"My High-Level Summary",
            imgTxt5:"Google Adx",
            imgTxt6:"Rubicon",
            imgTxt7:"SpotX",
            imgTxt8:"Freewheel",
            imgUrl1:"/assets/dts/explore/pm-wiki.png",
            imgUrl2:"/assets/dts/explore/pm-wireframe.png",
            imgUrl3:"/assets/dts/explore/jamboard.png",
            imgUrl4:"/assets/dts/explore/my-summary.png",
            imgUrl5:"/assets/dts/explore/adx1.png",
            imgUrl6:"/assets/dts/explore/rubicon.png",
            imgUrl7:"/assets/dts/explore/spotx.png",
            imgUrl8:"/assets/dts/explore/freewheel.png",
        },
    ],
    p0_PI2:[
        {
            title:"",
            descriptionL:"I reviewed the product requirements initially created by the Product Manager, and asked further questions on UX and workflow, as well as potential technical blockers.",
            descriptionR:" * The product requirement and scoping shown below has been updated over the course of the design, user testing and development process to reflect our newest learning.",
            imgUrl:"/assets/dts/process/product-requirement.jpg",
        },
        {
            title:"Problem Discovery after Initial sketches",
            descriptionL:"While I was very lucky to work with a PM that likes to visualize her ideas, I quickly realized that that her wireframes could be misleading, as they failed to address the varying data complexity and different user workflows.",
            descriptionR:"For example, by creating a few layouts based on the PM’s idea for Impressions Funnel, I found that the troubleshooting metrics and error logs can easily overflow the screen.",
            imgUrl:"/assets/dts/process/problem-discovery.jpg",
        }
    ],
    p1_CZ4:[
        {
            title:"Brainstorm with a focus on data visualization, readability and efficiency",
            descriptionL:"By trying a few times and showing why the initial layouts could go wrong, I convinced the stakeholders that we need to reconsider a more adaptive UI that’s easy to scan through even when the metrics are dense and error messages complicated.",
            descriptionR:"Data visualization is explored extensively here, because not only does a visual flow of impressions helps the Ad Ops user understand their deal performance, but also it improves the users' workflow from (1) seeing the metrics at a glance -> (2) drilling down the specific under-delivery causes -> (3) resolving the high-impact blockers.",
            // descriptionR:"After sorting out the various edge cases around metrics and types of errors messages, I explored a wide range of layouts for the visual funnel, breakdown of metrics and lists of assorted actions.",
            imgTxt1:"Mocks in the PM's Initial Direction",
            imgTxt2:"Back to the Drawing Board",
            imgTxt3:"More Hand Sketching",
            imgTxt4:"Digital UI and DataViz Explorations",
            imgUrl1:"/assets/dts/explore/explore-1.png",
            imgUrl2:"/assets/dts/explore/explore-2.png",
            imgUrl3:"/assets/dts/explore/explore-3.png",
            imgUrl4:"/assets/dts/explore/explore-4.png",
        },
    ],
    p1_PI2:[
        {
            title:"With a focus on data visualization, readability and efficiency",
            descriptionL:"By showing why the initial layouts could go wrong,I convinced the stakeholders that we need to reconsider a more adaptive UI that’s easy to scan through even when the metrics are dense and error messages complicated.",
            descriptionR:"After sorting out the various edge cases around metrics and types of errors messages, I explored a wide range of layouts for the visual funnel, breakdown of metrics and lists of assorted actions.",
            imgUrl:"/assets/dts/process/hand-sketches.jpg",
        },
        {
            title:"",
            descriptionL:"Data visualization is explored extensively here, because not only does a visual flow of impressions helps the Ad Ops user understand their deal performance, but also it improves the users' workflow from (1) seeing the metrics at a glance -> (2) drilling down the specific under-delivery causes -> (3) resolving the high-impact blockers.",
            imgUrl:"/assets/dts/process/digital-sketches.jpg",
        }
    ],
    p2_CZ23: [
        {
            title:"Iterations on Impression Funnel",
            descriptionL:"I circulated my initial designs within Product Management, Engineering, internal Design Team and Xandr's Solutions Consultants for feedback. I also reached out to learn from past designs of Xandr’s buy-side UI.",
            descriptionR:"By using hi-fi prototypes to test with internal and external clients, I was able to have a deeper understanding of their workflow, and used those insights to help guide my design.",
            imgTxt1:"Xandr's Buy-side Design",
            imgTxt2:"Critique Session with the Design Team",
            imgTxt3:"Early-Stage Designs",
            imgTxt4:"Mid-Stage Designs",
            imgTxt5:"Final Designs",
            imgUrl1:"/assets/dts/iterations/imps-funnel-buy-side.png",
            imgUrl2:"/assets/dts/iterations/imps-funnel-critique.png",
            imgUrl3:"/assets/dts/iterations/imps-funnel-iteration1.png",
            imgUrl4:"/assets/dts/iterations/imps-funnel-iteration2.png",
            imgUrl5:"/assets/dts/iterations/imps-funnel-iteration3.png",
        },
        {
            title:"Iterations on Deal Error Report",
            descriptionL:"While designing a much more simplified in-line reporting experience, I made sure to also use similar components and call to action that are already used in the platform.",
            descriptionR:"And based on new requirements from Engineering and PM, I updated the designs to reflect that more dimensions and time interval selection will be made available for the in-line Deal Error Report tool.",
            imgTxt1:"Xandr's Full Reporting UI",
            imgTxt2:"Sample VAST Error Report",
            imgTxt3:"Early-Stage Designs",
            imgTxt4:"Mid-Stage Designs",
            imgTxt5:"Final Designs",
            imgUrl1:"/assets/dts/iterations/full-reporting.png",
            imgUrl2:"/assets/dts/iterations/sample-vast-report.png",
            imgUrl3:"/assets/dts/iterations/error-report-iteration1.png",
            imgUrl4:"/assets/dts/iterations/error-report-iteration2.png",
            imgUrl5:"/assets/dts/iterations/error-report-iteration3.png",
        },
        {
            title:"Iterations on Bid Sampler",
            descriptionL:"The Bid Request Sampler is Xandr's first self-serving tool for external clients to sample a previous bid request, with many options to filter their desired bid request.",
            descriptionR:"It later turned out that due to privacy-related constraints, only Bid Request can sampled. I then optimized for both the settings and code display in the UI.",
            imgUrl1:"",
            imgUrl2:"",
            imgTxt3:"Early-Stage Designs (Bid Request & Bid Response)",
            imgTxt4:"Mid-Stage Designs (Bid Request & Bid Response)",
            imgTxt5:"Final Designs (Only for Bid Request due to Privacy Issues)",
            imgUrl3:"/assets/dts/iterations/brs-iteration1.png",
            imgUrl4:"/assets/dts/iterations/brs-iteration2.png",
            imgUrl5:"/assets/dts/iterations/brs-iteration3.png",
        },
    ],
    p3_CZ22:[
        {   title:"",
            descriptionL:"I used Invision for hi-fi clickable prototypes to show workflows, and Abstract for communicating UI specs in detail.",
            descriptionR:"During the development we found out a few edge cases that weren’t addressed in detail previously. I scheduled with PM and Engineering to discuss the options, and then documented the design changes in Jira Tickets.",
            imgTxt1:"Share Technical Specs using Abstract",
            imgTxt2:"Clickable Prototypes in InVision",
            imgTxt3:"Guidelines on Interactive Chart",
            imgTxt4:"Jira Tickets during QA",
            imgUrl1:"/assets/dts/delivery/delivery-1.png",
            imgUrl2:"/assets/dts/delivery/delivery-2.png",
            imgUrl3:"/assets/dts/delivery/delivery-3.png",
            imgUrl4:"/assets/dts/delivery/delivery-4.png",
        }
    ],
    p2_PBIn: [
        {
            title:"① First Round of Iteration",
            description:"I circulated Design within Product Management, Engineering, internal Design Team and Xandr's Solutions Consultants for feedback.",
            imgUrl:"/assets/dts/process/round-1.jpg",
            title0:"1. Learn from past designs of Xandr’s buy-side UI:  ",
            bullet0:"I talked to the designer who worked on the buy-side troubleshooting tool (a different UI in the buy-side platform) to understand their user scenarios, goals and constraints, and what they have learned through their process.",
            title1:"2. Imps Funnel – finding the right visualization:  ",
            bullet1:"1) Pie Chart is visually harder to scan through and see relationships than bar charts; 2) the way bars for the funnel are put in individual tabs seems very disjoint, and fails to convey the idea of a funnel.",
            title2:"3. Deal Error Report:  ",
            bullet2:"Based on updates from engineering and PM, there will be more dimensions and time interval selection available for the in-line Deal Error Report tool.",
            title3:"4. Bid Request/Response:  ",
            bullet3:"The bid request and response sampling should be coordinated via the user’s settings, and users should have more filtering options before sampling."
        },
        {
            title:"② Second Round of Iteration",
            description:"I joined the PM to interview Xandr's internal users and external clients, and gethered their feedback by both guiding them though our features and leting them try the hi-fi prototypes. I also continued to integrate new engineering updates.",
            imgUrl:"/assets/dts/process/round-2.jpg",
            title0:"1. Imps Funnel – improve workflow with more user feedback:  ",
            bullet0:"We learned that our users: 1) would dive into the issues of a specific funnel stage to troubleshoot - this means that while the funnel visualization give a general view of deal health, it doesn’t have to be aligned right next to the detailed breakdowns of each funnel stage; 2) care about “rates” (% of Imps passed from the previous stage to the current stage) more than raw numbers, because they give a intuitive sense of deal health regardless of how large or small a deal is.",
            title1:"2. Logarithmic scale:  ",
            bullet1:"In the case the impressions in the later stage of the funnel gets relatively small, the linear scaling of the bar chart can make it almost invisible on screen. Users should have access to seeing the data in logarithmic scale so that they’re more visible and relatable.",
            title2:"3. Make metrics and error messages more actionable:  ",
            bullet2:"Add tool tips to explain each of the 4 rates -  more friendly to new Ad Ops users. Separated Error messages into buyer’s actions and seller’s actions, and provided UI shortcut for copy-pasting the actions so the Ad Ops person can easily share them.",
            title3:"4. Additional Error Report Type:  ",
            bullet3:"For video ads, sthere are many video-player-specific metrics that should be easily accessible to the user. A sample report for video ad was provided to me as a reference."
        },
        {
            title:"③ Thrid Round of Iteration",
            description:"With more concrete insights on different user scenarios, I redesigned the layout and optimized for more efficient workflows. I then worked with Xandr's UI designer to customize a new color palette for Deal Troubleshooting.",
            imgUrl:"/assets/dts/process/round-3.jpg",
            title0:"1. Customize funnel views for varying use cases:  ",
            bullet0:"Users need more granular control on the metrics and custom time intervals, and the increased settings means that we need to reconsider the Impression Funnel layout.",
            title1:"2. Expand Xandr’s Color Palette:  ",
            bullet1:"The existing chart colors were dull and was originally designed for basic data and not meant for communicating a funnel. I worked with the UI designer to look for a new set of colors that look less dull and still look consistent next to Xandr’s existing color schemes.",
            title2:"3. Integrate Multiple report Types into One Workflow:  ",
            bullet2:"User can swtich between report types via a dropdown, and the dimensions will be updated accordingly.",
            title3:"4. Update and Refine Bid Request Sampler:  ",
            bullet3:"Due to privacy-related constraints, only Bid Request can sampled. I optimized both the settings and code display in the sampling UI."
        }
    ],
    p3_PI2:[
        {   title:"",
            descriptionL:"I used Invision for hi-fi clickable prototypes to show workflows, and Abstract for communicating UI specs in detail.",
            descriptionR:"During the development we found out a few edge cases that weren’t addressed in detail previously. I scheduled with PM and Engineering to discuss the options, and then documented the design changes in Jira Tickets.",
            imgUrl:"/assets/dts/process/delivery.jpg"
        }
    ],

}

export default dtsData