const dvData = {
    id:0,
    overview:{
        coverImgUrl:"/assets/dv/cover.jpg",
        descriptionBold:"I teamed up with our UX Researcher and UI Designer to design a data visualization template that analyzes AppCues surveys in Xandr’s DSP.",
        descriptionRegular:"Though initially implemented for DSP, this AppCues survey is expected to be adopted by Xandr’s SSP and TV platforms in the future.",
        timelineImgUrl:"",

        role:"Lead Designer and Developer",
        theTeam:"UX Researcher, UI Designer",
        tools:"Sketch, Invision, D3.js, React.js, HTML/CSS, pen & paper",
        duration:"Q4 2020"
    },
    o0_PBI21: [{
        title:"",
        descriptionL:"I helped the UX Researcher to design and develop an interactive data visualization template for showing user feedback on a quarterly basis.",
        descriptionR:"The visualization template consists for three main sections, and the graphs interactively shows user more insights of each metric.",
        imgUrl:"/assets/dv/Dashboard-hover.png",
        title0:"The top shows a rating overview ",
        bullet0:"of the most recently quarterly ratings, accompanied by a line chart that correlates rating changes over time and when new feature or product updates are implemented in Xandr’s DSP.",
        title1:"The overall ratings are followed by horizontal bar charts and a 2D scatter plot ",
        bullet1:"which gives details on the distribution of ratings for “Usefulness” and “Usability”, and their correlation with clients' Ad spend and size.",
        title2:"The user comments are selected and curated by our UX Researcher.",
        bullet2:"Those are displayed at the bottom of the page to enrich the above quotative visualizations above with qualitative insights."
    }],
    r0_PI2:[
        {   title:"",
            descriptionL:"Xandr Invest is a newly launched DSP and as a design team we would like to collect user feedback not only from conducting interviews with selected Traders, but also through surveying a wide range of users.",
            descriptionR:"The goal of this survey is mainly twofold: 1) measuring UX improvements and demonstrate to Xandr’s stakeholders, 2) help the design team analyze AppCues feedback and improve design",
            imgUrl:"/assets/dv/1.jpg"
        },
    ],
    r1_PI2:[
        {   title:"",
            descriptionL:"The UX researcher had launched a short AppCues survey that situate in the landing page of Xandr Invest. The survey was designed to be short and simple to complete, and consists of two ratings and an optional input field.",
            descriptionR:"The survey had been set up and sent out for 2 months by the time I joined the project, and changing survey content is out of the project scope. This means l had to stay with the given two metrics  discover and visualize insights.",
            imgUrl:"/assets/dv/2.jpg"
        },
        {
            title:"Pre-existing Work",
            descriptionL:"While by the time I joined the project our UX Researcher had made a very straightforward translation of the survey output into a pdf view, we believed that there could be more insights drawn from the survey.",
            imgUrl:"/assets/dv/3.jpg",
        }
    ],
    r2_PI0:[
        {
            title:"Brainstorm How We Could Expand the Dataset",
            descriptionL:"I noticed that even though it seemed that our survey only had two ratings, we can use the “member ID” to associate the survey data with Xandr’s own client database and relevant metrics.",
            descriptionR:"We can massively enrich our data and insights by pulling in metrics such as company size, number of users on Xandr’s platform, current Ad spend, etc.",
            imgUrl:"/assets/dv/4.jpg",
        }
    ],
    p0_PI2:[
        {
            title:"",
            descriptionL:"We began by diving into all kinds of information what we can possibly get out of the survey and explored a variety of ways to visualize them.",
            imgUrl:"/assets/dv/5.jpg",
        }
    ],
    p1_PI2:[
        {
            title:"",
            descriptionL:"Our team met regularly during the design process to clarify our goals and the expected audiences of the visualization.",
            descriptionR:"We also discussed and aligned the short-term and long- term objectives for this survey.",
            imgUrl:"/assets/dv/6.jpg",
        },
    ],
    p2_PI2:[
        {
            title:"",
            descriptionL:"While we did a lot of explorations of charts and diagrams, we agree with the general idea that there should be an “at a glance” view of the survey result, followed by a breakdown of the survey if the viewer has more time to dig deeper.",
            imgUrl:"/assets/dv/7.jpg",
        },
        {
            title:"Draw correlation between ratings and Xandr’s platform updates",
            descriptionL:"",
            imgUrl:"/assets/dv/8.jpg",
        },
        {
            title:"Page Layout and Interactive filtering",
            descriptionL:"In the more detailed section, we want to show: ",
            descriptionR:"1) how does our clients that have big spends feel about our product? 2) how has the ratings changes over time, and what are some changes we made to the platform that might have affected the rating?",
            imgUrl:"/assets/dv/9.jpg",
        }
    ],
}

export default dvData