const vtData = {
    id:2,
    overview:{
        coverImgUrl:"/assets/vr/cover.jpg",
        descriptionBold:"This is a multiplayer VR game built on the web. It simulates an colorful oceanic environment by mimicking the vision and movements under water.",
        descriptionRegular:"We used WebXR as the main development frame work, so that the experience can be easily accessible through the internal broswer of VR headsets. We also used Croquet's API to achieve data sharing and facilitate multi-user experience.",
        timelineImgUrl:"",

        role:"Designing the game, Coding, Testing and Debuging",
        theTeam:"Myself and one other classmate",
        tools:"WebGL, WebXR, GLSL, Javascript, Croquet.io, Blender",
        duration:"May 2023"
    },
    pb_PI:[
        {
            title:"",
            descriptionL:"",
            descriptionR:"",
            imgUrl:"/assets/vr/project-background.jpg",
        }
    ],
    ss_PI:[
        {
            title:"",
            descriptionL:"",
            descriptionR:"",
            imgUrl:"/assets/vr/scene-setup.jpg",
        },
    ],
    pn_PI:[
        {
            title:"",
            descriptionL:"",
            descriptionR:"",
            imgUrl:"/assets/vr/navigation.jpg",
        },
    ],
    ov_SV0:[
        {
            title:"DEMO: NAVIGATING THE UNDERWATER SCENE (USING JOYSTICK)",
            vUrl:"https://player.vimeo.com/video/827404470?title=0&byline=0&portrait=0&transparent=0&autoplay=1&loop=1"
        },
        {
            title:"TWO PLAYERS' PERSPECTIVE OF THE SAME GAME",
            vUrl:"https://player.vimeo.com/video/827924816?title=0&byline=0&portrait=0&transparent=0&autoplay=1&loop=1"
        },
    ],
}

export default vtData