import React from 'react';

class DesignOutcome extends React.Component{
    constructor(){
        super();
        this.state = {};
    }

    render(){
        return(
            <div>
                <h4>{this.props.data.title}</h4>
                <div className="pofo-text-row">
                    {/*<div className="pofo-text-col" >*/}
                        {/*<p>{this.props.data.description}</p>*/}
                    {/*</div>*/}
                </div>
                <img src={this.props.data.researchImgUrl}/>
            </div>
        )
    }
}

export default DesignOutcome