import React from 'react';
import Overview from './pofoComponents/Overview'
import PI2 from './pofoComponents/PI2_new'
import PBIn from './pofoComponents/PBIn'

import "../css/pofo.css"
import "../css/sideNav.css"
import windowsData from  "../data/windowsData1.js"
import $ from 'jquery'

class Windows extends React.Component {

    constructor(){
        super();
        // TODO: add page compoenets
        this.pb0_PI2 = windowsData.pb0_PI2.map(dt=> {
            return <PI2 data = {dt} />
        });
        this.rp0_PI2 = windowsData.rp0_PI2.map(dt=> {
            return <PI2 data = {dt} />
        });
        this.rp1_PBIn = windowsData.rp1_PBIn.map(dt=> {
            return <PBIn data = {dt} />
        });
        this.dp0_PI2 = windowsData.dp0_PI2.map(dt=> {
            return <PI2 data = {dt} />
        });
        this.dp1_PI2 = windowsData.dp1_PI2.map(dt=> {
            return <PI2 data = {dt} />
        });
        this.do0_PI2 = windowsData.do0_PI2.map(dt=> {
            return <PI2 data = {dt} />
        });
        this.do1_PI2 = windowsData.do1_PI2.map(dt=> {
            return <PI2 data = {dt} />
        });
        this.do2_PI2 = windowsData.do2_PI2.map(dt=> {
            return <PI2 data = {dt} />
        });
    };
    // TODO: add scroll interaction

    componentDidMount(){
        // nav -> content
        $(document).ready(function(){
            //Switch to white scheme
            $('#root').css('background-color', 'white');
            $('#navbar').css('background-color', 'white');

            $('body').scrollspy({target: ".pofo-page", offset: 50});

            // Add smooth scrolling on all links inside the navbar
            $("#side-nav a").on('click', function(event) {
                // Make sure this.hash has a value before overriding default behavior
                if (this.hash !== "") {
                    // Prevent default anchor click behavior
                    event.preventDefault();
                    // Store hash
                    var targetHref = $(this).attr('href');
                    // Using jQuery's animate() method to add smooth page scroll
                    // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
                    $('html, body').animate({
                        scrollTop: $(targetHref).offset().top
                    }, 900);
                }  // End if
            });

            $(window).scroll(function() {
                var scrollDistance = $(window).scrollTop();
                // Assign active class to nav links while scolling
                $('.page-section').each(function(m) {
                    if ($(this).position().top-200 <= scrollDistance) {
                        $('.side-nav div a.active').removeClass('active');
                        $('.side-nav div a').eq(m).addClass('active');
                    }
                    else{
                        $('.subgroup').each(function(n) {
                            $('nav ul li a.active').removeClass('active');
                        });
                    }
                });

                $('.subgroup').each(function(n) {
                    if ($(this).position().top-200 <= scrollDistance) {
                        $('nav ul li a.active').removeClass('active');
                        $('nav ul li a').eq(n).addClass('active');
                    }
                });


            }).scroll();

        });}

    render(){
        window.scrollTo(0, 0);

        if(window.innerWidth < 600){
            return(
                <div className="pofo-page mobile">
                    <div style={{width:"90vw", marginLeft:"2vw", float:"left", display:"inline"}}>
                        <div style={{marginLeft:"3vw"}} ><Overview data = {windowsData}/></div>
                        <div>
                            <h2 className="page-section" id="pb" style={{ paddingLeft:"14vw",paddingRight:"15vw"}}>PROJECT BACKGROUND</h2>
                            <br/>
                            {this.pb0_PI2}
                        </div>
                        <div style={{display:"none"}}>
                            <h2 className="page-section" id="rp" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>RESEARCH PROCESS</h2>
                            <h4 id="r0" className="subgroup" style={{ width:"50vw",paddingLeft:"14vw",paddingRight:"15vw"}}>
                                How can digital technology improve the trades - an industry that’s largely manual and non-digitized?
                            </h4>
                            {this.rp0_PI2}
                            <div id="r1" className="subgroup">{this.rp1_PBIn}</div>
                        </div>
                        <div>
                            <h2 className="page-section" id="dp" style={{ paddingLeft:"14vw",paddingRight:"15vw"}}>DESIGN PROCESS</h2>
                            <br/>
                            <h4 id="p0" className="subgroup" style={{ paddingLeft:"14vw",paddingRight:"15vw"}}>Junior Pros: Onsite Measure Process</h4>
                            {this.dp0_PI2}
                            <h4 id="p0" className="subgroup" style={{ paddingLeft:"14vw",paddingRight:"15vw"}}>Senior Pros: Offsite Review Workflow</h4>
                            {this.dp1_PI2}
                        </div>
                        <div>
                            <h2 className="page-section" id="do" style={{ paddingBottom:"30px", paddingLeft:"14vw",paddingRight:"15vw"}}>DESIGN OUTCOME</h2>
                            <br/>
                            <h4 id="o0" className="subgroup" style={{padding:"2vw 15vw 2vw 14vw"}}>Smart Glasses Interface for Onsite Recording</h4>
                            {this.do0_PI2}
                            <h4 id="o1" className="subgroup" style={{padding:"2vw 15vw 2vw 14vw"}}>Review Off-site and Submit for Approval</h4>
                            {this.do1_PI2}
                            <h4 id="o2" className="subgroup" style={{padding:"2vw 15vw 2vw 14vw"}}>Capture Job-level and Window-level Requirements</h4>
                            {this.do2_PI2}
                        </div>
                    </div>

                </div>
            )
        }

        else return(
            <div className="pofo-page">
                <div style={{width:"18vw", marginLeft: "0vw", float:"left"}}>
                    <nav className="side-nav" id="side-nav" style={{position: "fixed", display:"inline-block"}}>
                        <div><a href="#overview">OVERVIEW</a></div>
                        <div><a href="#pb">PROJECT BACKGROUND</a></div>
                        <div><a href="#rp">RESEARCH PROCESS</a></div>
                        <ul>
                            <li><a href="#r0">Challenges</a></li>
                            <li><a href="#r1">Approaches</a></li>
                        </ul>
                        <div><a href="#dp">DESIGN PROCESS</a></div>
                        <ul>
                            <li><a href="#p0">Jr. Pros' Process</a></li>
                            <li><a href="#p1">Sr. Pros' Workflow</a></li>
                        </ul>
                        <div><a href="#do">DESIGN OUTCOME</a></div>
                        <ul>
                            <li><a href="#o0">Smart Glasses Experience</a></li>
                            <li><a href="#o1">Review Measurement on iPad</a></li>
                            <li><a href="#o2">Job Data Intake on iPad</a></li>
                        </ul>
                    </nav>
                </div>
                <div style={{width:"80vw", marginLeft:"18vw", float:"left", display:"inline"}}>
                    <Overview data = {windowsData}/>
                    <div>
                        <h2 className="page-section" id="pb" style={{ paddingLeft:"14vw",paddingRight:"15vw"}}>PROJECT BACKGROUND</h2>
                        <br/>
                        {this.pb0_PI2}
                    </div>
                    <div>
                        <h2 className="page-section" id="rp" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>RESEARCH PROCESS</h2>
                        <h4 id="r0" className="subgroup" style={{ width:"50vw",paddingLeft:"14vw",paddingRight:"15vw"}}>
                            How can digital technology improve the trades - an industry that’s largely manual and non-digitized?
                        </h4>
                        {this.rp0_PI2}
                        <div id="r1" className="subgroup">{this.rp1_PBIn}</div>
                    </div>
                    <div>
                        <h2 className="page-section" id="dp" style={{ paddingLeft:"14vw",paddingRight:"15vw"}}>DESIGN PROCESS</h2>
                        <br/>
                        <h4 id="p0" className="subgroup" style={{ paddingLeft:"14vw",paddingRight:"15vw"}}>Junior Pros: Onsite Measure Process</h4>
                        {this.dp0_PI2}
                        <h4 id="p0" className="subgroup" style={{ paddingLeft:"14vw",paddingRight:"15vw"}}>Senior Pros: Offsite Review Workflow</h4>
                        {this.dp1_PI2}
                    </div>
                    <div>
                        <h2 className="page-section" id="do" style={{ paddingBottom:"30px", paddingLeft:"14vw",paddingRight:"15vw"}}>DESIGN OUTCOME</h2>
                        <br/>
                        <h4 id="o0" className="subgroup" style={{padding:"2vw 15vw 2vw 14vw"}}>Smart Glasses Interface for Onsite Recording</h4>
                        {this.do0_PI2}
                        <h4 id="o1" className="subgroup" style={{padding:"2vw 15vw 2vw 14vw"}}>Review Offsite and Submit to Sr. Pros for Approval</h4>
                        {this.do1_PI2}
                        <h4 id="o2" className="subgroup" style={{padding:"2vw 15vw 2vw 14vw"}}>Intake Job-level and Window-level Information</h4>
                        {this.do2_PI2}
                    </div>
                </div>

            </div>
        )
    }
}

export default Windows