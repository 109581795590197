import React from 'react';
import aboutData from "../../data/aboutData.js"
import ExperienceEntry from "./ExperienceEntry";

class MyExperience extends React.Component{
    constructor(){
        super();
        this.y2024= aboutData.experience_entries.y2024.map(dt=>{
            return <ExperienceEntry data={dt}/>
        });
        this.y2022= aboutData.experience_entries.y2022.map(dt=>{
            return <ExperienceEntry data={dt}/>
        });
        this.y2021= aboutData.experience_entries.y2021.map(dt=>{
            return <ExperienceEntry data={dt}/>
        });
        this.y2019= aboutData.experience_entries.y2019.map(dt=>{
            return <ExperienceEntry data={dt}/>
        });
        this.y2018= aboutData.experience_entries.y2018.map(dt=>{
            return <ExperienceEntry data={dt}/>
        });
        this.y2017= aboutData.experience_entries.y2017.map(dt=>{
            return <ExperienceEntry data={dt}/>
        });
        this.y2016= aboutData.experience_entries.y2016.map(dt=>{
            return <ExperienceEntry data={dt}/>
        });
        this.y2015= aboutData.experience_entries.y2015.map(dt=>{
            return <ExperienceEntry data={dt}/>
        });
        this.y2014= aboutData.experience_entries.y2014.map(dt=>{
            return <ExperienceEntry data={dt}/>
        });
        this.pre= aboutData.experience_entries.pre.map(dt=>{
            return <ExperienceEntry data={dt}/>
        });
    }

    render(){
        return(
            <div className="exp-text-row">
                <div  className="exp-text-col">
                    <div>
                        <h2>{"What have I been working on?"}</h2>
                    </div>
                    <div>
                        <h5>2024 - Present</h5><div>{this.y2024}</div>
                    </div>
                    <div>
                        <h5>2022 - 2023</h5><div>{this.y2022}</div>
                    </div>
                    <div>
                        <h5>2020 - 2021</h5><div>{this.y2021}</div>
                    </div>
                    <div>
                        <h5>2019</h5><div>{this.y2019}</div>
                    </div>
                    <div>
                        <h5>2018</h5><div>{this.y2018}</div>
                    </div>
                    <div>
                        <h5>2017</h5>{this.y2017}
                    </div>
                    <div>
                        <h5>2016</h5>{this.y2016}
                    </div>
                    <div>
                        <h5>2015</h5>{this.y2015}
                    </div>
                    <div>
                        <h5>2014</h5>{this.y2014}
                    </div>
                    <div>
                        <h5>2011 ~ 2013</h5>{this.pre}
                    </div>

                </div>
            </div>
        )
    }
}

export default MyExperience