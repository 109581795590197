import React from 'react';

class Clickzoom3 extends React.Component{
    constructor(){
        super();
        this.state = {
        };
    }

    render(){
        return(
            <div>
                <div className="pofo-text-row">
                    <div className="pofo-text-col" style={{width:"80vw",padding:"4vw 15vw 0 14vw"}}>
                        <h4 style={this.props.data.title==="" ? { display: 'none' }:{}}>{this.props.data.title}</h4>
                        <p style={this.props.data.descriptionL==="" ? { display: 'none' }:{paddingBottom:"10px"}}>
                            <div style={{paddingBottom:"14px"}}>{this.props.data.descriptionL}</div>
                            <div>{this.props.data.descriptionR}</div>
                        </p>
                    </div>
                </div>
                <div class="box" style={{backgroundColor:"#f8f8f8",width:"74vw",margin:" 2vw 3vw 0vw 3vw"}}>
                    <div id="lb-back-h">
                        <div id="lb-img-h" ></div>
                    </div>
                    <ul id="row-1-light" style={{padding: "3vw 5.5vw", margin: "0",display: "inline-flex",marginBlockStart: "0",marginBlockEnd: "0",marginInlineStart: "0",marginInlineEnd: "0"}}>
                        <li style={{width: "24vw"}}><b>{this.props.data.imgTxt1}</b><img src={this.props.data.imgUrl1} className="zoomDH" style={{width: "17vw",margin:"1vw 0"}}/></li>
                        <li style={{width: "23vw"}}><b>{this.props.data.imgTxt2}</b><img src={this.props.data.imgUrl2} className="zoomDH" style={{width: "17vw", margin:"1vw 0"}}/></li>
                        <li style={{width: "21vw"}}><b>{this.props.data.imgTxt3}</b><img src={this.props.data.imgUrl3} className="zoomDH" style={{width: "17vw",margin:"1vw 0"}}/></li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Clickzoom3