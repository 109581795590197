const brData = {
    id:2,
    overview:{
        coverImgUrl:"/assets/br/br-cover.jpg",
        descriptionBold:"This is a C++ implementation of the SIGGRAPH paper \"Apperance Mimicking Surfaces\" on Geometry Processing, by C. Shüller, D. Panozzo and O. Sorkine-Hornung. In addition to recreating the paper’s application for generating relief-like meshes, I explored a variation for generating relief on spherical surfaces.",
        descriptionRegular:"Because light reflections are determined by the angles between incoming light and the normal vectors along the surface, if we can re-parameterize the surface normals of the mesh by their vertices’ positions, then we can optimize for these vertex positions to make the lighting effect appear as close as possible to the original mesh, while constraining the original model to a much shallower (relief) depth.",
        timelineImgUrl:"",

        role:"Solving Math, Coding, Debugging and Rendering",
        theTeam:"Myself",
        tools:"C++, Eigen, libIGL, CLion, Blender",
        duration:"April - May 2019"
    },
    o0_PI2:[
        {
            title:"",
            descriptionL:"I first wrote the C++ implementation of the algorithms, and then used it on a few open-source OBJ models I found online, such as the infamous Stanford Bunny, horses and teapot.",
            descriptionR:"The algorithm calculates and produces the relief versions of those models as matrices, and I then used libIGL to write the vertices into OBJ files.",
            imgUrl:"/assets/br/outputs.jpg",
        },
        {
            title:"These relief-version models are exported in OBJ format, and I used Blender to render these OBJ models using colors and textures that mimic porcelain.",
            descriptionL:"",
            descriptionR:"",
            imgUrl:"/assets/br/render1.png",
        },
        {
            title:"",
            descriptionL:"",
            descriptionR:"",
            imgUrl:"/assets/br/render2.png",
        }
    ],
    p0_PI2:[
        {
            title:"Brainstorm for Project Ideas",
            descriptionL:"While searching for ideas for my final project for the Geometric Modeling class at NYU, I came across an interesting paper about generating Bash-Relief from 3D models, written by my professor during his postdoc.",
            descriptionR:"The technique reminded me of Jasperware from the 18th Century and their subtle yet vivid decorative style. I wondered if I could implement the paper in such way that generates Bas-relieves not just on flat surfaces, but on curved surfaces as well.",
            imgUrl:"/assets/br/inspiration.jpg",
        },
        {
            title:"Find ways to Implement the Constrained Convex Optimization using C++ and Eigen",
            descriptionL:"While the paper discussed the high-level mathematical principles of the algorithm, not a word was mentioned about how they coded it in a finite state machine. It was my job to figure out how to process the mesh, allocate memory, work around rounding errors of floating point numbers, avoid mesh intersections, etc.",
            descriptionR:"(Source code for this project can be found at: https://github.com/yuepingwang/relief/tree/master/src)",
            imgUrl:"/assets/br/process.jpg",
        },
    ],
}

export default brData