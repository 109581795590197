import React from 'react';

class Clickzoom44 extends React.Component{
    constructor(){
        super();
        this.state = {
        };
    }

    render(){
        return(
            <div>
                <div className="pofo-text-row">
                    <div className="pofo-text-col" style={{width:"80vw",padding:"2vw 15vw 0vw 14vw"}}>
                        <p style={this.props.data.descriptionL==="" ? { display: 'none' }:{paddingBottom:"10px"}}>
                            <div style={{paddingBottom:"14px"}}>{this.props.data.descriptionL}</div>
                            <div>{this.props.data.descriptionR}</div>
                        </p>
                    </div>
                </div>
                <div class="box" style={{backgroundColor:"#2e2e37",width:"74vw",margin:" 1vw 3vw 0vw 3vw"}}>
                    <div id="lb-back-h">
                        <div id="lb-img-h"></div>
                    </div>
                    <ul id="row-1-dark" style={{padding:"1.5vw 3vw 1vw 3vw", margin: "0",display: "inline-flex",marginBlockStart: "0",marginBlockEnd: "0",marginInlineStart: "0",marginInlineEnd: "0"}}>
                        <li style={{margin:"1vw"}}><p>{this.props.data.imgTxt1}</p><img src={this.props.data.imgUrl1} className="zoomDH" style={{width: "15vw",margin:"0"}}/></li>
                        <li style={{margin:"1vw"}}><p style={{paddingLeft:"2.4vw"}}>{this.props.data.imgTxt2}</p><img src={this.props.data.imgUrl2} className="zoomDH" style={{width: "15vw",margin:"0"}}/></li>
                        <li style={{margin:"1vw"}}><p>{this.props.data.imgTxt3}</p><img src={this.props.data.imgUrl3} className="zoomDH" style={{width: "15vw",margin:"0"}}/></li>
                        <li style={{margin:"1vw"}}><p>{this.props.data.imgTxt4}</p><img src={this.props.data.imgUrl4} className="zoomDH" style={{width: "15vw",margin:"0"}}/></li>
                    </ul>
                    <ul id="row-2-dark" style={{padding: "0vw 3vw 3vw 3vw",margin: "0", display: "inline-flex", marginBlockStart: "0",marginBlockEnd: "0",marginInlineStart: "0",marginInlineEnd: "0"}}>
                        <li style={{margin:"1vw"}}><p>{this.props.data.imgTxt5}</p><img src={this.props.data.imgUrl5} className="zoomDH" style={{width: "15vw", margin:"0"}}/></li>
                        <li style={{margin:"1vw"}}><p>{this.props.data.imgTxt6}</p><img src={this.props.data.imgUrl6} className="zoomDH" style={{width: "15vw", margin:"0"}}/></li>
                        <li style={{margin:"1vw"}}><p>{this.props.data.imgTxt7}</p><img src={this.props.data.imgUrl7} className="zoomDH" style={{width: "15vw",margin:"0"}}/></li>
                        <li style={{margin:"1vw"}}><p>{this.props.data.imgTxt8}</p><img src={this.props.data.imgUrl8} className="zoomDH" style={{width: "15vw",margin:"0"}}/></li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Clickzoom44