
const trData = {
    id:4,
    overview:{
        coverImgUrl:"/assets/tr/cover-image-1.png",
        descriptionBold:"I initiated and led the research and discovery phase for nTop’s effort to introduce “transparency” render mode for the first time. This research process demonstrates that by interpreting the problem space from different angles, we can steer a seemingly unsolvable problem into tangible resolutions.",
        descriptionRegular:"Previously, users of nTop can only view geometries in solid colors, which makes it hard to see the interiors of components, spacial relationships between parts in an assembly, and differences between each iteration of generative design.",
        timelineImgUrl:"",

        role:"UX research, Competitive Analysis, Project Management",
        theTeam:"Myself, Engineering team, Customer-facing Teams at nTop",
        tools:"WebGL, Shaders, 3D Math, Computer Graphics journals and documentations",
        duration:"Q1 2023"
    },
    b0_PI0:[
        {
            descriptionL:"Design Engineers using nTop wants to have see-through rendering in their workflow, and Jira tickets requesting for \"object transparency\" have been piling up in the Graphics team's backlog for years before I joined the company.",
            descriptionR:"However, not only is the word \"transparency\" ambiguous without a concrete context, but also in Computer Graphics, simulating translucent effect is a hard problem to solve and remains as an active field of research.",
            imgUrl:"/assets/tr/background-1.jpg"
        }
    ],
    b1_PI0:[
        {
            descriptionL:"3D renderings that require \"light\" to penetrate surfaces (instead of simple reflections) tend to be complex to implement and expensive to compute at run time.",
            descriptionR:"As an actively on-going research topic, the approaches vary significantly by the different priorities of real world application.",
            imgUrl:"/assets/tr/background-2.jpg"
        },
        {
            descriptionL:"Sphere-tracing (also known as ray marching) is a rendering technique where for each pixel, a ray is shoot out from the camera and approaches the the object through re-evaluated step size in each iteration.",
            descriptionR:"Whereas most popular CAD software on the market use rasterization (a different rendering techniques that allows off-the-shelf OpenGL functions calls), nTop's unique sphere-tracing infrastructure requires a custom approach.",
            imgUrl:"/assets/tr/background-3.jpg"
        }
    ],
    r0_PI0:[
        {
            // title:"Read Research Papers on Popular and Relevant Approaches",
            descriptionL:"Read research papers on popular and relevant approaches.",
            descriptionR:"",
            imgUrl:"/assets/tr/research-1.jpg"
        },
        {
            // title:"Read Research Papers on Popular and Relevant Approaches",
            descriptionL:"Compare different approaches and trade-offs.",
            descriptionR:"",
            imgUrl:"/assets/tr/research-2.jpg"
        }
    ],
    r1_PI0:[
        {
            title:"Qualitative Research to Understand the Rendering Needs in Their Original Context",
            descriptionL:"",
            descriptionR:"",
            imgUrl:"/assets/tr/research-3.jpg"
        }
    ],
    rd0_PI0:[
        {
            descriptionL:"",
            descriptionR:"",
            imgUrl:"/assets/tr/delivery-1.jpg"
        }
    ],
    rd1_PI0:[
        {
            descriptionL:"",
            descriptionR:"",
            imgUrl:"/assets/tr/delivery-2.jpg"
        }
    ],
    rd2_PI0:[
        {
            descriptionL:"",
            descriptionR:"",
            imgUrl:"/assets/tr/delivery-3.jpg"
        }
    ],
    rd3_PI0:[
        {
            descriptionL:"",
            descriptionR:"",
            imgUrl:"/assets/tr/delivery-4.jpg"
        }
    ],
}

export default trData