import React, { Component } from 'react';
import * as d3 from 'd3';
import BarChart from './d3Components/BarChart'

const temperatureData = [ 8, 5, 13, 9, 12 ];
class D3Tests extends Component{

    constructor(){
        super();
        this.temperatureData = [ 8, 5, 13, 9, 12 ];

    }

    componentDidMount(){
        d3.select(this.refs.temperatures)
            .selectAll("h2")
            .data(this.temperatureData)
            .enter()
            .append("h2")
            .text((datapoint) => "New Temperature " + datapoint + " degrees");
        d3.select(this.refs.temperatures)
            .selectAll("p")
            .style("color", function() {
                    return "hsl(" + Math.random() * 360 + ",100%,50%)";
                }
            );
    }

    render(){
        return(
            <div style={{ width: '100vw', height: '70vh'}}
                 ref = "temperatures"// ref={(mount) => { this.mount = mount }}
                >
                <p>0</p>
                <p>1</p>
                {/*<h1>Hello</h1>*/}
                <BarChart />
            </div>
        )
    }
}

export default D3Tests;