const rossData = {
    id:0,
    overview:{
        coverImgUrl:"/assets/ross/02-cover.jpg",
        descriptionBold:"As a Design consultant, I lead the UX and UI design for the digital education platform of Ross School - a K-12 Private School in East Hampton pioneering in integrated learning and teaching strategies.",
        descriptionRegular:"Ross Learning System is a platform for digitization of teaching materials, collaboration on curriculum design by school faculty, and easy access to the teaching innovations of Ross School for students, parents and educators beyond Ross School.",
        timelineImgUrl:"/assets/ross/01-timeline.jpg",

        role:"UI UX Design Consultant",
        theTeam:"Project Manager, Curriculum Design Specialists, Digital Archive Specialist, Designers from related projects",
        tools:"Sketch, InVision, Adobe Illustrator, Adobe Photoshop",
        duration:"Oct 2018 - Feb 2019"
    },
    o0_PBI1: [{
        title:"COURSE PAGE",
        description:"For each Grade, there are 8 courses, each corresponding to a particular \"Domain\"(subject).",
        imgUrl:"/assets/ross/Course-1.jpg",
        title0:"Structural Requirements: ",
        bullet0:"the Course Page should communicate both a general introduction to the materials covered, learning goals, and connection to the \"Grade-Level Thematics\"",
        title1:"Course Units: ",
        bullet1:"the Course Page also serves to direct the users to its Units, each of which has a separate page.",
        title2:"Visual Requirements: ",
        bullet2:"the page must adapt to the color themes of each Domain(subject), use black background color, and showcase a rich assortment of images related to course material."
    }],
    o1_PBI1:[
        {
            title:"UNIT PAGE",
            description:"Each Course consists of 1~6 Units. A Unit can span from 2 weeks to a full semester.",
            imgUrl:"/assets/ross/Unit-1.jpg",
            title0:"Structural Requirements: ",
            bullet0:"Unit Page shows more details in learning goals, guiding questions, and instruction for teacher. It will also show more resource materials and references than in Course Page.",
            title1:"Module List: ",
            bullet1:"the Unit Page should direct the users to its Modules, each of which has a separate page.",
            title2:"Visual Requirements: ",
            bullet2:"the page must use same color themes based on the Domain. Decorate with large images related to the Unit material. Layout style for new content should be consistent with the Course Page."
        }
    ],
    o2_PBI1:[
        {
            title:"MODULE PAGE",
            description:"Each Unit consist of between 4~20 Modules. A Module usually takes 1~2 weeks, and it divides the learning objectives of the Unit into bite size.",
            imgUrl:"/assets/ross/Module-1.jpg",
            title0:"Structural Requirements: ",
            bullet0:"A Module page is the most instructional level and provides detailed teaching guidelines. It also provides a variety of multimedia resources for teaching.",
            title1:"Visual Requirements: ",
            bullet1:"the page must use same color themes based on the Domain. Decorate with large images related to the Module material. Layout style for new content should be consistent with Course and Unit Page.",
            title2:"",
            bullet2:""
        }
    ],
    r0_PI0:[
        {   descriptionL:"Meet project team members and staff at Ross School:",
            imgUrl:"/assets/ross/team.jpg"
        },
        {   descriptionL:"Study the curriculum structure and teaching philosophy of Ross School:",
            imgUrl:"/assets/ross/curriculum_structure.jpg",
        },
        {   descriptionL:"Visit the school:",
            imgUrl:"/assets/ross/school-img.jpg"
        },
    ],
    r1_PI0:[
        {
            descriptionL:"By auditing the old RLS website and learning from the feedback of the teachers (users) at Ross School, I identified the problems with the old UI and UX.",
            descriptionR:"Below are a few examples of these problems:",
            imgUrl:"/assets/ross/prev_eval.jpg",
        }
    ],
    r2_PI0:[
        {
            descriptionL:"Through many meetings and discussions with team, I clarified the design scope, goals, evaluation criterion, and constraints.",
            descriptionR:"(The constraints on UI were initially very stiff, but I was able to negotiate the flexibility later on, when I presented the different design proposals.)",
            imgUrl:"/assets/ross/design_brief.jpg",
        }
    ],
    p0_PI0:[
        {
            descriptionL:"I participate in the curricular re-structuring meetings with the Ross School staffs, because the new curriculum structure strongly affects the web architecture and navigation.",
            descriptionR:"My insights as a designer spurred the discussion to better clarify the details, and push the team to think more about how different structural components will formalize in practical implementation.",
            imgUrl:"/assets/ross/restructure.jpg",
        }
    ],
    p1_PI0:[
        {
            descriptionL:"To find the best design solution under the given visual style requirement of Ross School, I surveyed a number of websites that shared similar attributes, ranging from art curation to education platforms.",
            imgUrl:"/assets/ross/design_survey.jpg",
        },
        {
            descriptionL:"For each of the components in the page, I brainstormed different layouts on paper.",
            imgUrl:"/assets/ross/sketches.jpg",
        }
    ],
    p2_PI0:[
        {
            descriptionL:"I began the visual designs by \"preserving\" the style of the old RLS site, and gradually convinced the team lead with bolder visual changes.",
            imgUrl:"/assets/ross/old_style.jpg",
        },
        {
            descriptionL:"After many iterations, I establish a global color scheme that works well with all 8 \"Domain colors\" given by Ross School.",
            imgUrl:"/assets/ross/new_style.jpg",
        },

    ],
    prototyping:[
        {
            vUrl:"https://player.vimeo.com/video/330903315?title=0&byline=0&portrait=0&transparent=0&autoplay=1&loop=1",
            imgUrl:"assets/ross/ux_issue-1.jpg",
            description:"1. Changes to top navigation: cut down to a clear hierarchy for the the page."
        },
        {
            vUrl:"https://player.vimeo.com/video/330903320?title=0&byline=0&portrait=0&transparent=0&autoplay=1&loop=1",
            imgUrl:"assets/ross/ux_issue-2.jpg",
            description:"2. Added secondary navigation: allow user to easily target information on current page, and have a navigate with clarity when the page is very long."
        },
        {
            vUrl:"https://player.vimeo.com/video/330903309?title=0&byline=0&portrait=0&transparent=0&autoplay=1&loop=1",
            imgUrl:"assets/ross/ux_issue-3.jpg",
            description:"3. Improved viewing experience for long content: changed \"pop-up\" to slide view, and redesigned UI elements to more clearly group listed items."
        },
    ],

}

export default rossData