import React from 'react';
//import ReactDOM from 'react-dom';

class Footer extends React.Component {
    render() {
        return(
            <div className="footer" style={{width: "100%",color:"#888",textAlign: "center",margin:"0",paddingBottom:"2vw",paddingTop:"2vw"}}>
                    <h6 style={{fontSize:"15px",lineHeight:"26px",paddingTop:"120px", color:"#bbb"}}>© 2024 Yueping Wang</h6>
                    <p style={{margin:"0",fontSize:"14px",lineHeight:"28px", color:"#bbb"}}>This website is designed and developed by Yueping Wang using React.js</p>
                    <p style={{margin:"0",fontSize:"14px", color:"#bbb"}}>Last Updated: June 2024</p>
                </div>
            )
    }
}

export default Footer