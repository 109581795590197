import React from 'react';
// Components
import Overview from './pofoComponents/Overview'
import PI0 from './pofoComponents/PI0'
import PI2 from './pofoComponents/PI2'
import PBI31 from './pofoComponents/PBI31'
import PBIn from './pofoComponents/PBIn'
import PIV1 from './pofoComponents/PIV1'
import CZ41 from './pofoComponents/Clickzoom41'
// Data
import vtData from "../data/vtData.js"
import $ from "jquery";

// Styles
import "../css/pofo.css"
import "../css/image.css"
import "../css/sideNav.css"
import PBI12 from "./pofoComponents/PBI12";
import dtsData from "../data/dtsData";
import CZ4 from "./pofoComponents/Clickzoom4";

class ViewTools extends React.Component {
    constructor() {
        super();
        //pass data of this project
        this.state = {
            hasResearch: false,
            hasPrototype: false
        };
        // Project Background
        this.b0_PI0 = vtData.b0_PI0.map(idea=>{
            return <PI0 data={idea}/>
        });
        this.b1_PI0 = vtData.b1_PI0.map(idea=>{
            return <PI0 data={idea}/>
        });
        this.b1_CZ41 = vtData.b1_CZ41.map(idea=>{
            return <CZ41 data={idea}/>
        });
        // Design Outcome
        this.o0_PI0 = vtData.o0_PI0.map(idea=>{
            return <PI0 data={idea}/>
        });
        this.o1_PI2 = vtData.o1_PI2.map(idea=>{
            return <PI2 data={idea}/>
        });
        this.o2_PI0 = vtData.o2_PI0.map(idea=>{
            return <PI0 data={idea}/>
        });
        this.o3_PIV1 = vtData.o3_PIV1.map(idea=>{
            return <PIV1 data={idea}/>
        });
        this.o4_PIV1 = vtData.o4_PIV1.map(idea=>{
            return <PIV1 data={idea}/>
        });
        this.r0_PI0 = vtData.r0_PI0.map(idea=>{
            return <PI0 data={idea}/>
        });
        this.r1_PI0 = vtData.r1_PI0.map(idea=>{
            return <PI0 data={idea}/>
        });
        this.d0_PI2 = vtData.d0_PI2.map(idea=>{
            return <PI2 data={idea}/>
        });
        this.d1_PI2 = vtData.d1_PI2.map(idea=>{
            return <PI2 data={idea}/>
        });
        this.s0_PBI31 = vtData.s0_PBI31.map(idea=>{
            return <PBI31 data={idea}/>
        });
        this.s1_PBIn = vtData.s1_PBIn.map(idea=>{
            return <PBIn data={idea}/>
        });
    }

    componentDidMount(){
        // On-scroll style change
        $(document).ready(function() {
            //Switch to white scheme
            $('#root').css('background-color', 'white');
            $('#navbar').css('background-color', 'white');
            $('body').scrollspy({target: ".pofo-page", offset: 50});
            // Add smooth scrolling on all links inside the navbar
            $("#side-nav a").on('click', function(event) {
                // Make sure this.hash has a value before overriding default behavior
                if (this.hash !== "") {
                    // Prevent default anchor click behavior
                    event.preventDefault();
                    // Store hash
                    let targetHref = $(this).attr('href');
                    // Using jQuery's animate() method to add smooth page scroll
                    // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
                    $('html, body').animate({
                        scrollTop: $(targetHref).offset().top
                    }, 900);
                }  // End if
            });
            // set side nav text color highlight
            $(window).scroll(function() {
                let scrollDistance = $(window).scrollTop();
                // Assign active class to nav links while scolling
                $('.subgroup').each(function(n) {
                    if ($(this).position().top-200 <= scrollDistance) {
                        $('nav ul li a.active').removeClass('active');
                        $('nav ul li a').eq(n).addClass('active');
                    }
                    else{
                        $('.page-section').each(function(n) {
                            $('.side-nav div a.active').removeClass('active');
                        });
                    }
                });
                $('.page-section').each(function(m) {
                    if ($(this).position().top-200<= scrollDistance) {
                        $('.side-nav div a.active').removeClass('active');
                        $('.side-nav div a').eq(m).addClass('active');
                    }
                });
            }).scroll();
        });

        // Switch tabs in "Design Outcomes/Toolbar UI"
        $(document).ready(function(){
            $("#vt-tab-1").on({
                'click': function(){
                    let targetHref = $(this).attr('href');
                    $('html, body').animate({scrollTop: $(targetHref).offset().top}, 0);
                    // style changes:
                    $(this).css('opacity','1');
                    $('#display-vt').attr('src','/assets/vt/2d_ui_spec_fv.jpg');
                    $('#vt-tab-2').css('opacity','0.5');
                }
            });
            $("#vt-tab-2").on({
                'click': function(){
                    let targetHref = $(this).attr('href');
                    $('html, body').animate({scrollTop: $(targetHref).offset().top}, 0);
                    // style changes:
                    $(this).css('opacity','1');
                    $('#display-vt').attr('src','/assets/vt/2d_ui_spec_sc.jpg');
                    $('#vt-tab-1').css('opacity','0.5');
                }
            });
        });

        // Click-zoom
        let zoomImgH = function(){
            let clone = this.cloneNode();
            if(clone.classlist)
                clone.classlist.remove("zoomDH");
            //put clone to lb
            let lb = document.getElementById("lb-img-h");
            lb.innerHTML = "";
            lb.appendChild(clone);
            //show lb
            lb = document.getElementById("lb-back-h");
            lb.classList.add("show");
        };

        let imagesH = document.getElementsByClassName("zoomDH");
        if (imagesH.length>0){
            for (let imgh of imagesH)
                imgh.addEventListener("click", zoomImgH);
        }
        document.getElementById("lb-back-h").addEventListener("click", function(){
            this.classList.remove("show");
        })
    }

    render() {
        window.scrollTo(0, 0);
        if (window.innerWidth < 600) {
            return(
                <div className="pofo-page mobile" >
                    <div style={{width:"90vw", marginLeft:"5vw", float:"left", display:"inline"}}>
                        <Overview data={vtData}/>
                        <div>
                            <h2 className="page-section" id="pb" >PROJECT BACKGROUND</h2>
                            <div>
                                <h4 id="b0" className="subgroup">WHAT IS NTOPOLOGY USED FOR?</h4>
                                {this.b0_PI0}
                                <h4 id="b1" className="subgroup">CURRENT EXPERIENCE WITH 3D INSPECTION</h4>
                                {this.b1_CZ41}
                                {/*{this.b1_PI0}*/}
                            </div>
                        </div>
                        <div>
                            <h2 className="page-section" id="do">DESIGN OUTCOMES</h2>
                            <div>
                                <h4 id="o0" className="subgroup">SUMMARY</h4>
                                {this.o0_PI0}
                                <h4 id="o1" className="subgroup">TOOLBAR UI</h4>
                                {this.o1_PI2}
                                <h4 id="o2" className="subgroup">KEYBOARD INTERACTIONS</h4>
                                {this.o2_PI0}
                                <h4 id="o3" className="subgroup">GIMBAL INTERACTIONS</h4>
                                {this.o3_PIV1}
                                <h4 id="o4" className="subgroup">DESIGN PROTOTYPE & SPEC WEBSITE</h4>
                                {this.o4_PIV1}
                            </div>
                        </div>
                        <div>
                            <h2 className="page-section" id="rp">RESEARCH PROCESS</h2>
                            <div>
                                <h4 id="r0" className="subgroup">SURVEY & INTERVIEWS</h4>
                                {this.r0_PI0}
                            </div>
                            <div>
                                <h4 id="r1" className="subgroup">FEEDBACK DIGESTION</h4>
                                {this.r1_PI0}
                            </div>
                        </div>
                        <div>
                            <h2 id="dp" className="page-section">DESIGN PROCESS</h2>
                            <div >
                                <h4 id="d0" className="subgroup">BRAINSTORM & FEEDBACK</h4>
                                {this.d0_PI2}
                            </div>
                            <div>
                                <h4 id="d1" className="subgroup">DESIGN ITERATIONS</h4>
                                {this.d1_PI2}
                            </div>
                        </div>
                        <div>
                            <h2 className="page-section" id="sd">SPEC & DELIVERY</h2>
                            <div id="s0" className="subgroup">
                                {this.s0_PBI31}
                            </div>
                            <div id="s1" className="subgroup">
                                {this.s1_PBIn}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return(
            <div className="pofo-page" >
                {/*Side nav*/}
                <div style={{width:"18vw", marginLeft: "0vw", float:"left"}}>
                    <nav className="side-nav" id="side-nav" style={{position: "fixed", display:"inline-block"}}>
                        <div><a href="#overview">OVERVIEW</a></div>
                        <div><a href="#pb">PROJECT BACKGROUND</a></div>
                        <ul>
                            <li><a href="#b0">About nTopology</a></li>
                            <li><a href="#b1">Current Experience</a></li>
                        </ul>
                        <div><a href="#do">DESIGN OUTCOMES</a></div>
                        <ul>
                            <li><a href="#o0">Summary</a></li>
                            <li><a href="#o1">Toolbar UI</a></li>
                            <li><a href="#o2">Keyboard Switches</a></li>
                            <li><a href="#o3">Gimbal Interactions</a></li>
                            <li><a href="#o4">Design Spec Website</a></li>
                        </ul>
                        <div><a href="#rp">RESEARCH PROCESS</a></div>
                        <ul>
                            <li><a href="#r0">Survey & Interviews</a></li>
                            <li><a href="#r1">Feedback Digestion</a></li>
                        </ul>
                        <div><a href="#dp">DESIGN PROCESS</a></div>
                        <ul>
                            <li><a href="#d0">Brainstorm & Feedback</a></li>
                            <li><a href="#d1">Design Iterations</a></li>
                        </ul>
                        <div><a href="#sd">SPEC & DELIVERY</a></div>
                        <ul>
                            <li><a href="#s0">Toolbar UI</a></li>
                            <li><a href="#s1">Spacing & Layout</a></li>
                        </ul>
                    </nav>
                </div>
                {/*Page Content*/}
                <div style={{width:"80vw", marginLeft:"18vw", float:"left", display:"inline"}}>
                    <Overview data={vtData}/>
                    <div>
                        <h2 className="page-section" id="pb" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>PROJECT BACKGROUND</h2>
                        <div>
                            <h4 id="b0" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>What are nTopology Used For?</h4>
                            {this.b0_PI0}
                            <h4 id="b1" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"3vw"}}>Current Experience with 3D Inspection</h4>
                            {this.b1_CZ41}
                            {/*{this.b1_PI0}*/}
                        </div>
                    </div>
                    <div>
                        <h2 className="page-section" id="do" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>DESIGN OUTCOMES</h2>
                        <div>
                            <h4 id="o0" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>Summary</h4>
                            {this.o0_PI0}
                            <h4 id="o1" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"3vw"}}>Toolbar UI</h4>
                            {this.o1_PI2}
                            <h4 id="o2" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"3vw"}}>Keyboard Interactions</h4>
                            {this.o2_PI0}
                            <h4 id="o3" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"5vw"}}>Gimbal Interactions</h4>
                            {this.o3_PIV1}
                            <h4 id="o4" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"7vw"}}>Design Prototype & Spec Website</h4>
                            {this.o4_PIV1}
                        </div>
                    </div>
                    <div>
                        <h2 className="page-section" id="rp" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>RESEARCH PROCESS</h2>
                        <div>
                            <h4 id="r0" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>Survey & Interviews</h4>
                            {this.r0_PI0}
                        </div>
                        <div>
                            <h4 id="r1" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"5vw"}}>Feedback Digestion</h4>
                            {this.r1_PI0}
                        </div>
                    </div>
                    <div>
                        <h2 id="dp" className="page-section" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>DESIGN PROCESS</h2>
                        <div >
                            <h4 id="d0" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>Brainstorm & Feedback</h4>
                            {this.d0_PI2}
                        </div>
                        <div>
                            <h4 id="d1" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>Design Iterations</h4>
                            {this.d1_PI2}
                        </div>
                    </div>
                    <div>
                        <h2 className="page-section" id="sd" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>Spec & Delivery</h2>
                        <div id="s0" className="subgroup">
                            {this.s0_PBI31}
                        </div>
                        <div id="s1" className="subgroup">
                            {this.s1_PBIn}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ViewTools