import React from 'react';
import aboutData from "../../data/aboutData.js"
import ExperienceEntry from "./ExperienceEntry";

class MyContact extends React.Component{
    constructor(){
        super();
        this.contact= aboutData.contact.map(dt=>{
            return <ExperienceEntry data={dt}/>
        });
    }

    render(){
        return(
            <div className="contact-text-row">
                <div className="contact-text-col">
                    <div>
                        <h2>{"Contact me?"}</h2>
                    </div>
                    <div>
                        {this.contact}
                    </div>
                </div>
            </div>
        )
    }
}

export default MyContact