import React from 'react';

class ExperienceEntry extends React.Component {
    render() {
        return (
        <div>
            <b>{this.props.data.title}</b>
            <p>{this.props.data.description}</p>
        </div>);
    }
}

export default ExperienceEntry