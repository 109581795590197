import React from 'react';
import ReactDOM from 'react-dom';
import Overview from './pofoComponents/Overview'
import PI from './pofoComponents/PI'
import PI0 from './pofoComponents/PI0'
import SV0 from './pofoComponents/SV0'
import PI2 from './pofoComponents/PI2'
import PBI2 from './pofoComponents/PBI2'
import SI0 from './pofoComponents/SI0'
import trData from "../data/trData.js"
import "../css/pofo.css"
import "../css/sideNav.css"
import $ from 'jquery';

class Transparency extends React.Component {

    constructor(){
        super();
        //pass data of this project
        this.state = {
            hasResearch: false,
            hasPrototype:false
        };
        this.b0_PI0 = trData.b0_PI0.map(dt=>{
            return <PI0 data={dt}/>
        });
        this.b1_PI0 = trData.b1_PI0.map(dt=>{
            return <PI0 data={dt}/>
        });
        this.r0_PI0 = trData.r0_PI0.map(dt=>{
            return <PI0 data={dt}/>
        });
        this.r1_PI0 = trData.r1_PI0.map(dt=>{
            return <PI0 data={dt}/>
        });
        this.rd0_PI0 = trData.rd0_PI0.map(dt=>{
            return <PI0 data={dt}/>
        });
        this.rd1_PI0 = trData.rd1_PI0.map(dt=>{
            return <PI0 data={dt}/>
        });
        this.rd2_PI0 = trData.rd2_PI0.map(dt=>{
            return <PI0 data={dt}/>
        });
        this.rd3_PI0 = trData.rd3_PI0.map(dt=>{
            return <PI0 data={dt}/>
        });
    };

    componentDidMount(){
        // nav -> content
        $(document).ready(function(){
            //Switch to white scheme
            $('#root').css('background-color', 'white');
            $('#navbar').css('background-color', 'white');
            $('body').scrollspy({target: ".pofo-page", offset: 50});
            // Add smooth scrolling on all links inside the navbar
            $("#side-nav a").on('click', function(event) {
                // Make sure this.hash has a value before overriding default behavior
                if (this.hash !== "") {
                    // Prevent default anchor click behavior
                    event.preventDefault();
                    // Store hash
                    let targetHref = $(this).attr('href');
                    // Using jQuery's animate() method to add smooth page scroll
                    // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
                    $('html, body').animate({
                        scrollTop: $(targetHref).offset().top
                    }, 900);
                }  // End if
            });
            // set side nav text color highlight
            $(window).scroll(function() {
                let scrollDistance = $(window).scrollTop();
                // Assign active class to nav links while scolling
                $('.page-section').each(function(m) {
                    if ($(this).position().top-200<= scrollDistance) {
                        $('.side-nav div a.active').removeClass('active');
                        $('.side-nav div a').eq(m).addClass('active');
                    }
                    else{
                        $('.subgroup').each(function(n) {
                            $('nav ul li a.active').removeClass('active');
                        });
                    }
                });
                $('.subgroup').each(function(n) {
                    if ($(this).position().top-200 <= scrollDistance) {
                        $('nav ul li a.active').removeClass('active');
                        $('nav ul li a').eq(n).addClass('active');
                    }
                });
            }).scroll();

        });}

    render() {
        window.scrollTo(0, 0);

        if(window.innerWidth < 600){
            return(
                <div className="pofo-page mobile" >
                    <div style={{width:"90vw", marginLeft:"5vw", float:"left", display:"inline"}}>
                        <Overview data={trData}/>
                        <div>
                            <h2 className="page-section" id="pb">PROJECT BACKGROUND</h2>
                            <div>
                                <h4 id="b0" className="subgroup">VAGUENESS WITH INITIAL USER REQUESTS</h4>
                                {this.b0_PI0}
                                <h4 id="b1" className="subgroup">TECHNICAL LIMITATIONS & TRADE-OFFS</h4>
                                {this.b1_PI0}
                            </div>
                        </div>
                        <div>
                            <h2 className="page-section" id="rp">RESEARCH PROCESS</h2>
                            <div>
                                <h4 id="r0" className="subgroup">STUDY EXISTING RESEARCH</h4>
                                {this.r0_PI0}
                                <h4 id="r1" className="subgroup">UNDERSTAND USER NEEDS</h4>
                                {this.r1_PI0}
                            </div>
                        </div>
                        <div>
                            <h2 className="page-section" id="rd">RESEARCH DELIVERY</h2>
                            <div>
                                <h4 id="rd0" className="subgroup">SHARE BACK RESEARCH FINDINGS WITH THE R&D TEAM</h4>
                                {this.rd0_PI0}
                                <h4 id="rd1" className="subgroup">SYNTHESIZE THE MAIN USER WORKFLOWS </h4>
                                {this.rd1_PI0}
                                <h4 id="rd2" className="subgroup">ANALYZE USE CASES & OPPORTUNITIES </h4>
                                {this.rd2_PI0}
                                <h4 id="rd3" className="subgroup">USABILITY RECOMMENDATIONS & BEST PRACTICES </h4>
                                {this.rd3_PI0}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return(
            <div className="pofo-page" >
                <div style={{width:"18vw", marginLeft: "0vw", float:"left"}}>
                    <nav className="side-nav" id="side-nav" style={{position: "fixed", display:"inline-block"}}>
                        <div><a href="#overview">OVERVIEW</a></div>
                        <div><a href="#pb">PROJECT BACKGROUND</a></div>
                        <ul>
                            <li><a href="#b0">Initial Request</a></li>
                            <li><a href="#b1">Technical Limitations</a></li>
                        </ul>
                        <div><a href="#rp">RESEARCH PROCESS</a></div>
                        <ul>
                            <li><a href="#r0">Study Existing Research</a></li>
                            <li><a href="#r1">Understand User Needs</a></li>
                        </ul>
                        <div><a href="#rd">RESEARCH DELIVERY</a></div>
                        <ul>
                            <li><a href="#rd0">Documentation</a></li>
                            <li><a href="#rd1">Synthesize Use Cases</a></li>
                            <li><a href="#rd2">Analyze Opportunities</a></li>
                            <li><a href="#rd3">Usability Recommendations</a></li>
                        </ul>
                    </nav>
                </div>
                <div style={{width:"80vw", marginLeft:"18vw", float:"left", display:"inline"}}>
                    <Overview data={trData}/>
                    <div>
                        <h2 className="page-section" id="pb" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>PROJECT BACKGROUND</h2>
                        <div>
                            <h4 id="b0" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>Vagueness of Initial User Requests</h4>
                            {this.b0_PI0}
                            <h4 id="b1" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>Technical Limitations & Trade-Offs</h4>
                            {this.b1_PI0}
                        </div>
                    </div>
                    <div>
                        <h2 className="page-section" id="rp" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>RESEARCH PROCESS</h2>
                        <div>
                            <h4 id="r0" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>Study Existing Research</h4>
                            {this.r0_PI0}
                            <h4 id="r1" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>Understand User Needs</h4>
                            {this.r1_PI0}
                        </div>
                    </div>
                    <div>
                        <h2 className="page-section" id="rd" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>RESEARCH DELIVERY</h2>
                        <div>
                            <h4 id="rd0" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>Share Back Research Findings with the R&D Team</h4>
                            {this.rd0_PI0}
                            <h4 id="rd1" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>Synthesize the Main User Worflows </h4>
                            {this.rd1_PI0}
                            <h4 id="rd2" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>Analyze Use Cases & Opportunities </h4>
                            {this.rd2_PI0}
                            <h4 id="rd3" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>Usability Recommendations & Best Practices </h4>
                            {this.rd3_PI0}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Transparency