import React from 'react';
// Components
import Overview from './pofoComponents/Overview'
import PI0 from './pofoComponents/PI0'
import PI2 from './pofoComponents/PI2'
import PV0 from './pofoComponents/PV0'
import SV0 from './pofoComponents/SV0'
import PVV0 from './pofoComponents/PVV0'
// Data
import ccData from "../data/ccData.js"
import $ from "jquery";

// Styles
import "../css/pofo.css"
import "../css/image.css"
import "../css/sideNav.css"

class CameraControls extends React.Component {
    constructor(){
        super();
        // Pass data to this project
        // Project Background
        this.b0_PI0 = ccData.b0_PI0.map(idea=>{
            return <PI0 data={idea}/>
        });
        this.b1_PV0 = ccData.b1_PV0.map(idea=>{
            return <PV0 data={idea}/>
        });
        this.ov_SV0 = ccData.ov_SV0.map(idea=>{
            return <SV0 data={idea}/>
        });
        this.o0_PI2 = ccData.o0_PI2.map(idea=>{
            return <PI2 data={idea}/>
        });
        this.o1_PI2 = ccData.o1_PI2.map(idea=>{
            return <PI2 data={idea}/>
        });
        this.p0_PI2 = ccData.p0_PI2.map(idea=>{
            return <PI2 data={idea}/>
        });
        this.p1_PI2 = ccData.p1_PI2.map(idea=>{
            return <PI2 data={idea}/>
        });
        this.pt0_PI2 = ccData.pt0_PI2.map(idea=>{
            return <PI2 data={idea}/>
        });
        this.pt1_PVV0= ccData.pt1_PVV0.map(idea=>{
            return <PVV0 data={idea}/>
        });
    }

    componentDidMount(){
        // On-scroll style change
        $(document).ready(function() {
            //Switch to white scheme
            $('#root').css('background-color', 'white');
            $('#navbar').css('background-color', 'white');
            $('body').scrollspy({target: ".pofo-page", offset: 50});
            // Add smooth scrolling on all links inside the navbar
            $("#side-nav a").on('click', function(event) {
                // Make sure this.hash has a value before overriding default behavior
                if (this.hash !== "") {
                    // Prevent default anchor click behavior
                    event.preventDefault();
                    // Store hash
                    let targetHref = $(this).attr('href');
                    // Using jQuery's animate() method to add smooth page scroll
                    // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
                    $('html, body').animate({
                        scrollTop: $(targetHref).offset().top
                    }, 900);
                }  // End if
            });
            // set side nav text color highlight
            $(window).scroll(function() {
                let scrollDistance = $(window).scrollTop();
                // Assign active class to nav links while scolling
                $('.page-section').each(function(m) {
                    if ($(this).position().top-200<= scrollDistance) {
                        $('.side-nav div a.active').removeClass('active');
                        $('.side-nav div a').eq(m).addClass('active');
                    }
                    else{
                        $('.subgroup').each(function(n) {
                            $('nav ul li a.active').removeClass('active');
                        });
                    }
                });
                $('.subgroup').each(function(n) {
                    if ($(this).position().top-200 <= scrollDistance) {
                        $('nav ul li a.active').removeClass('active');
                        $('nav ul li a').eq(n).addClass('active');
                    }
                });
            }).scroll();
        });
    }

    render(){
        window.scrollTo(0, 0);

        if (window.innerWidth < 600){
            return(
                <div className="pofo-page mobile" >
                    <div style={{width:"90vw", marginLeft:"5vw", float:"left", display:"inline"}}>
                        <Overview data={ccData}/>
                        <div>
                            <h2 className="page-section" id="pb">PROJECT BACKGROUND</h2>
                            <div>
                                <h4 id="b0" className="subgroup">CONTROLLING VIEWS IN 3D</h4>
                                {this.b0_PI0}
                                <h4 id="b1" className="subgroup">PREVIOUSLY IN NTOPOLOGY</h4>
                                {this.b1_PV0}
                            </div>
                            <div>
                                <h2 className="page-section" id="do">DESIGN OUTCOMES</h2>
                                <div id="ov" className="subgroup">
                                    {this.ov_SV0}
                                </div>
                                <h4 id="o0" className="subgroup">MAIN IMPROVEMENTS</h4>
                                {this.o0_PI2}
                                <h4 id="o0" className="subgroup">CREATING A TEST FILE REPOSITORY</h4>
                                {this.o1_PI2}
                            </div>
                            <div>
                                <h2 className="page-section" id="dp">DESIGN PROCESS</h2>
                                <h4 id="p0" className="subgroup">MAPPING THE I/O VARIABLES</h4>
                                {this.p0_PI2}
                                <h4 id="p1" className="subgroup">VISUAL CONSIDERATIONS FOR CURSOR ICONS</h4>
                                {this.p1_PI2}
                            </div>
                            <div>
                                <h2 className="page-section" id="pt">PROTOTYPING</h2>
                                <h4 id="pt0" className="subgroup">NARROW DOWN 2D DESIGNS IN 3D</h4>
                                {this.pt0_PI2}
                                <h4 id="pt1" className="subgroup">SAMPLE PROTOTYPES IN UNITY</h4>
                                {this.pt1_PVV0}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return(
            <div className="pofo-page" >
                <nav className="side-nav" id="side-nav" style={{position: "fixed", display:"inline-block"}}>
                    <div><a href="#overview">OVERVIEW</a></div>
                    <div><a href="#pb">PROJECT BACKGROUND</a></div>
                    <ul>
                        <li><a href="#b0">Views in 3D</a></li>
                        <li><a href="#b1">Previous Experience</a></li>
                    </ul>
                    <div><a href="#do">DESIGN OUTCOME</a></div>
                    <ul>
                        <li><a href="#ov">Live Demo</a></li>
                        <li><a href="#o1">Main Improvements</a></li>
                    </ul>
                    <div><a href="#dp">DESIGN PROCESS</a></div>
                    <ul>
                        <li><a href="#p0">Map I/O Variables</a></li>
                        <li><a href="#p1">Cursor Icons</a></li>
                    </ul>
                    <div><a href="#pt">PROTOTYPING</a></div>
                    <ul>
                        <li><a href="#pt0">2D to 3D</a></li>
                        <li><a href="#pt1">Unity Prototypes</a></li>
                    </ul>
                </nav>

                <div style={{width:"80vw", marginLeft:"18vw", float:"left", display:"inline"}}>
                    <Overview data={ccData}/>
                    <div >
                        <h2 className="page-section" id="pb" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>PROJECT BACKGROUND</h2>
                        <div>
                            <h4 id="b0" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>Controlling Views in 3D</h4>
                            {this.b0_PI0}
                            <h4 id="b1" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"6vw"}}>Previously in nTopology</h4>
                            {this.b1_PV0}
                        </div>
                        <div>
                            <h2 className="page-section" id="do" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>DESIGN OUTCOMES</h2>
                            <div id="ov" className="subgroup">
                                {this.ov_SV0}
                            </div>
                            <h4 id="o0" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"6vw"}}>Main Improvements</h4>
                                {this.o0_PI2}
                            <h4 id="o0" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"6vw"}}>Creating a Test File Repository</h4>
                                {this.o1_PI2}
                        </div>
                        <div>
                            <h2 className="page-section" id="dp" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>DESIGN PROCESS</h2>
                            <h4 id="p0" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>Mapping the I/O Variables</h4>
                                {this.p0_PI2}
                            <h4 id="p1" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"6vw"}}>Visual Considerations for Cursor Icons</h4>
                            {this.p1_PI2}
                        </div>
                        <div>
                            <h2 className="page-section" id="pt" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>PROTOTYPING</h2>
                            <h4 id="pt0" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw"}}>Narrow Down 2D Designs in 3D</h4>
                            {this.pt0_PI2}
                            <h4 id="pt1" className="subgroup" style={{paddingLeft:"14vw",paddingRight:"15vw", marginTop:"6vw"}}>Sample Prototypes in Unity</h4>
                            {this.pt1_PVV0}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CameraControls