const relproData = {
    id:1,
    overview:{
        coverImgUrl:"/assets/relpro/01-cover.jpg",
        descriptionBold:"During my internship at RelPro, a B2B software company, I designed and developed the user interface of a new map search feature for the SaaS platform.",
        descriptionRegular:"RelPro is a business intelligence software application for sales intelligence and relationship management. It leverages data aggregation and analytics to help marketing, sales and customer success teams to better target, get and keep clients.",
        timelineImgUrl:"/assets/relpro/01-timeline.jpg",

        role:"UX Designer and Prototyper",
        theTeam:"Technical Lead, Another Intern, Front-end Developers, Marketing Team",
        tools:"Sketch, Adobe Illustrator, Javascript, HTML, CSS.",
        duration:" Oct 2017 - Dec 2017"
    },
    r0_PI0:[
        {
            title:"PROFILE EXISTING USERS",
            descriptionL:"RelPro is an up-and-running relationship management product since 2011. As of September 2017, there are over 2000 business subscribers.\n Therefore, in order to design the next feature, we need to first understand the current user groups, and how they use RelPro.",
            descriptionR:"",
            imgUrl:"/assets/relpro/01-ur01.jpg"
        }
    ],
    r1_PI0:[
        {
            title:"IDENTIFY HOW CURRENT FEATURES APPEAL TO USERS",
            descriptionL:"To best translate and extend the RelPro experience, we also need to identify what makes RelPro stand out from its competitors.",
            descriptionR:"",
            imgUrl:"/assets/relpro/01-ur02.jpg"
        }
    ],
    p0_PBI0: [{
        title:"ANALYZE CURRENT USER FLOW WITH RELPRO",
        description:"Using the same platform, RelPro user should feel familiar and intuitive when researching with the map interface. The experience and expectation should be draw similarity to other RelPro search features.",
        imgUrl:"/assets/relpro/01-dr11.jpg",
        title0:"Consistent Look & Feel: ",
        bullet0:"The color scheme, typography, icons and layout styles should align with current RelPro UI.",
        title1:"Show Basic Information within the Map UI: ",
        bullet1:"Key information (name, role, company, contact) should be accessible instantly within the map.",
        title2:"Include Popular RelPro Features from the Map UI: ",
        bullet2:"frequently used functions (“Save to list”, “Follow”) should be available to user instantly. More detailed information, such as Profile Page, should be open in new page via hyperlink."
    }],
    p1_PBI0:[
        {
            title:"IDENTIFY MAP-SPECIFIC DESIGN CHALLENGES",
            description:"",
            imgUrl:"/assets/relpro/01-dr12.jpg",
            title0:"Compact Functionality: ",
            bullet0:"how can we translate the miscellaneous information and functions on top of a map interface, without congesting the page and stagnating the viewing experience?",
            title1:"Fast Navigation: ",
            bullet1:"What’s the best way to visualize information of search results on a map?",
            title2:"",
            bullet2:""
        }
    ],
    p2_PI0:[
        {   descriptionL:"We surveyed eight different map-based consumer apps (B2B software with map features was hard to find), four of which are shown below. We analyzed, respectively, how each interface facilitates user experience.",
            imgUrl:"/assets/relpro/01-dr03.jpg"
        },
        {   descriptionL:"Amongst the map-based apps, there are two essential components that formulates the user experience: pins and floating information cards.",
            imgUrl:"/assets/relpro/01-dr04.jpg",
        }
    ],
    p3_PI0:[
        {
            title:"SURVEY ON MAP APIs",
            descriptionL:"While the other designer continued to refine the UI, I took on the technical research task to evaluate the popular map plugins and APIs, and help the tech team to make decision on what’s the best API for RelPro.",
            descriptionR:"Later on, I was assigned to program the working prototype using that API. This technical phase took 2~3 weeks, in which I worked closely with the tech team to deliver the hi-fidelity prototype.",
            imgUrl:"/assets/relpro/01-dr05.jpg",
        }
    ],
    p4_PI0:[
        {
            title:"UI FOR MAP-SEARCH",
            descriptionL:"",
            descriptionR:"",
            imgUrl:"/assets/relpro/01-dr06.jpg",
        }
    ],
    prototyping:[
        {
            imgUrl:"/assets/relpro/g-2.gif",
            description:"1. Began coding with the traditional map pins"
        },
        {
            imgUrl:"/assets/relpro/g-3.gif",
            description:"2. Customize pins by instance attributes, such as branch company and subsidiary company. (The circle pins are PNGs, the star pins are by Google Map API's vector drawing function)"
        },
        {
            imgUrl:"/assets/relpro/g-4.gif",
            description:"3. Create one-to-many pins using the MarkerClusterer library from Google. (I also customized the PNGs for markers, to align with RelPro's visual style)"
        },
        {
            imgUrl:"/assets/relpro/g-7.gif",
            description:"4. Use tree-structured hierarchal checkboxes (DOM) to dynamically add/delete pins from the map"
        },
    ],
    d0_SI0:[
        {
            title:"MAP UI IN THE \"CORPORATE PROFILE\" PAGE",
            imgUrl:"/assets/relpro/01-do02.jpg"
        },
        {
            title:"MAP UI IN THE \"ADVANCED SEARCH\" PAGE",
            imgUrl:"/assets/relpro/01-do01.jpg"
        },
    ],
    d1_SV0:[
        {
            title:"Demo: Map-search Feature Inside Corporate Profile Page",
            vUrl:"https://player.vimeo.com/video/251204732?title=0&byline=0&portrait=0&transparent=0&autoplay=1&loop=1"
        }
    ]
}

export default relproData